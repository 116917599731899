import { Box, Stack } from "@mui/material";
import React, { memo } from "react";
import { useCallToAction } from "./index.hooks";
import { AppContainer } from "components/AppContainer";
import { CtaButton } from "components/CtaButton";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";

export type CallToActionProps = VisualComponentProps<{
  label: string;
  href: string;
  openInNewPage?: boolean;
  alignment?: "flex-start" | "center" | "flex-end";
}>;

export const CallToAction = memo(
  ({
    label,
    href,
    anchorId,
    openInNewPage,
    alignment = "center",
  }: CallToActionProps) => {
    const {} = useCallToAction();

    return (
      <AppContainer id={anchorId ?? undefined} sx={{ mt: "40px" }}>
        <Stack justifyContent="center" alignItems={alignment}>
          <Box>
            <CtaButton
              text={label}
              href={href}
              target={openInNewPage ? "_blank" : "_self"}
            />
          </Box>
        </Stack>
      </AppContainer>
    );
  }
);

CallToAction.displayName = "CallToAction";
