import { useEffect, useState } from "react";

type LocalStorageStore = {
  storeId: number;
  storeMethod: "Geolocation" | "Manual";
};

export const useLocalStorageStore = () => {
  const [localStorageStore, setLocalStorageStore] = useState<LocalStorageStore>(
    () => {
      if (typeof window !== "undefined") {
        const defaultLocalStorageStore = localStorage.getItem(
          "defaultLocalStorageStore"
        );
        return defaultLocalStorageStore
          ? JSON.parse(defaultLocalStorageStore)
          : {};
      }

      return {};
    }
  );

  useEffect(() => {
    // Avoid accessing the localStorage from server-side
    if (window && typeof window !== "undefined") {
      localStorage.setItem(
        "defaultLocalStorageStore",
        JSON.stringify(localStorageStore)
      );
    }
  }, [localStorageStore]);

  return { localStorageStore, setLocalStorageStore };
};
