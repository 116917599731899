import React, { memo } from "react";
import { useHeader } from "./index.hooks";
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  Typography,
  Link as LinkMui,
  IconButton,
} from "@mui/material";
import { AppLogoAndSearchBar } from "./AppLogoAndSearchBar";
import PersonIcon from "@mui/icons-material/Person";
import { AppNavButton } from "./AppNavButton";
import {
  PersonOutlined,
  ShoppingBasketOutlined,
  StorefrontOutlined,
  Search,
  BadgeOutlined,
  MenuOutlined,
} from "@mui/icons-material";
import { styled } from "@mui/system";
import LogoutIcon from "@mui/icons-material/Logout";
import { NavigationBar } from "./NavigationBar";
import Link from "next/link";
import { MobileSearchAndMenuDialog } from "../../MobileSearchAndMenuDialog";
import MenuIcon from "@mui/icons-material/Menu";

const NavigationTypography = styled(Typography)`
  font: bold 11px/14px Sora;
  letter-spacing: 0;
  color: #151319;
`;

type HeaderProps = {};

export const Header = memo(({}: HeaderProps) => {
  const {
    handleOpenUserMenu,
    handleCloseUserMenu,
    anchorEl,
    isUserMenuOpen,
    selectedStore,
    userProfile,
    handleLogout,
    isLoggingOut,
    yPosition,
    onMobileMenuDialogClose,
    openMobileDialogMenu,
    isMobileMenuDialogOpen,
  } = useHeader();

  const renderMenuItem = (icon, text, onClick, href) => {
    const renderContent = () => (
      <MenuItem href={href} onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ textAlign: "right" }}
          >
            {text}
          </Typography>
        </ListItemText>
      </MenuItem>
    );

    if (href) {
      return (
        <Link href={href} passHref>
          {renderContent()}
        </Link>
      );
    }
    return renderContent();
  };

  const renderAvatar = () => {
    return userProfile ? (
      isLoggingOut ? (
        <AppNavButton
          sx={{ borderLeft: { md: "none", xs: "1px solid #E9E9E9" } }}
          startIcon={<CircularProgress size={16} />}
        >
          <NavigationTypography
            noWrap
            sx={{
              display: {
                xs: "none",
                md: "block",
              },
            }}
          >
            Logout in corso...
          </NavigationTypography>
        </AppNavButton>
      ) : (
        <AppNavButton
          sx={{ borderLeft: { md: "none", xs: "1px solid #E9E9E9" } }}
        >
          <Avatar
            sx={{
              width: { xs: "38px", md: "50px" },
              height: { xs: "38px", md: "50px" },
              color: "#009FDF",
              fontWeight: "bold",
              fontSize: { xs: "13px", md: "16px" },
              fontFamily: "Sora",
              bgcolor: "#F2F1ED",
              boxShadow: "0px 1px 3px #00000029",
              border: { xs: "2px solid #FFF", md: "3px solid #FFF" },
            }}
            onClick={handleOpenUserMenu}
            ref={anchorEl}
          >
            {`${userProfile.name[0].toUpperCase()}${userProfile.surname[0].toUpperCase()}`}
          </Avatar>
          <Popover
            open={isUserMenuOpen}
            onClose={handleCloseUserMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            anchorEl={anchorEl.current}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              marginTop: { xs: "6px", md: "13px" },
              marginLeft: { xs: "43.5px", md: "74px" },
            }}
          >
            <MenuList sx={{ minWidth: "200px" }}>
              {renderMenuItem(
                <PersonIcon />,
                "Profilo",
                null,
                "/profilo-socio"
              )}
              <Divider variant="middle" />
              {renderMenuItem(<LogoutIcon />, "Logout", handleLogout, null)}
            </MenuList>
          </Popover>
        </AppNavButton>
      )
    ) : (
      <>
        <AppNavButton
          sx={{ borderLeft: { md: "none", xs: "1px solid #E9E9E9" } }}
          href="/accesso-soci"
          startIcon={
            <BadgeOutlined
              sx={{ color: "#646259", marginLeft: { xs: "9px", md: "0px" } }}
            />
          }
        >
          <NavigationTypography
            noWrap
            sx={{
              display: {
                xs: "none",
                md: "block",
              },
            }}
          >
            Accesso Soci
          </NavigationTypography>
        </AppNavButton>
      </>
    );
  };

  return (
    <Box id="container" sx={{ zIndex: 1000 }}>
      <Box
        sx={{
          height: yPosition > 150 ? "75px" : "auto",
          display: { xs: "block", lg: "block" },
          mt: yPosition > 150 ? 0 : "75px",
          transition: yPosition > 150 ? "none" : "margin-top .3s ease-in-out",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            spacing: { xs: "0px", md: "15px" },
            justifyContent: "space-between",
            height: { xs: "50px", md: "75px" },
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "0px 2px 4px #0000000F",
            opacity: 1,
            mb: "2px",
            display: "flex",
            px: { xs: "0px", md: "30px" },
            borderTop: { xs: "1px solid #D0D0D0", md: "none" },
            borderBottom: { xs: "1px solid #D0D0D0", md: "none" },
            position: yPosition > 150 ? "fixed" : "relative",
            mt: yPosition > 150 ? "0" : "-75px",
            width: "100%",
            transition: "margin-top .4s ease-in-out",
            zIndex: 1600,
          }}
        >
          <AppLogoAndSearchBar />
          <Box
            display={{
              xs: "none",
              md: yPosition > 150 ? "block" : "none",
            }}
            sx={{ mr: 2 }}
          >
            <IconButton
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              flex: {
                xs: 0,
                md: 1,
              },
            }}
          />

          <Stack
            direction="row"
            alignItems="center"
            spacing="1px"
            sx={{
              height: { xs: "50px", md: "75px" },
              display: { xs: "flex" },
              flex: 1,
            }}
          >
            <AppNavButton
              href={"/cerca-nel-sito"}
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              <Search style={{ fontSize: "24px", color: "#646259" }} />
            </AppNavButton>

            <Box flex={1} />

            <LinkMui
              href="https://www.coopshop.it/shop/home"
              target="_blank"
              sx={{
                textDecoration: "none",
                height: "100%",
              }}
            >
              <AppNavButton
                startIcon={
                  <ShoppingBasketOutlined
                    sx={{
                      color: "#646259",
                      display: { xs: "none", md: "inline" },
                    }}
                  />
                }
                sx={{
                  borderLeft: { xs: "none", md: "1px solid #E9E9E9" },
                  display: { xs: "none", md: "flex" },
                }}
              >
                <NavigationTypography
                  sx={{ display: { xs: "none", md: "inline" } }}
                  noWrap
                >
                  Acquista Online
                </NavigationTypography>
              </AppNavButton>
            </LinkMui>

            {renderAvatar()}

            <AppNavButton
              href={`/punti-vendita/${selectedStore?.slug}`}
              startIcon={
                <StorefrontOutlined
                  sx={{
                    color: "#646259",
                    marginLeft: { xs: "9px", md: "0px" },
                  }}
                />
              }
            >
              <Stack
                direction="column"
                sx={{ display: { xs: "none", md: "inline-block" } }}
              >
                <NavigationTypography noWrap sx={{ color: "#646259" }}>
                  Offerte Negozio
                </NavigationTypography>
                <NavigationTypography
                  noWrap
                  sx={{
                    font: "bold 14px/18px Sora",
                    color: "#FF0000",
                  }}
                >
                  {selectedStore?.name?.length > 20
                    ? `${selectedStore?.name?.slice(0, 20)}...`
                    : selectedStore?.name}
                </NavigationTypography>
              </Stack>
            </AppNavButton>
            <AppNavButton
              sx={{ display: { xs: "flex", md: "none" } }}
              onClick={openMobileDialogMenu}
            >
              <MenuOutlined
                style={{
                  fontSize: "24px",
                  color: "#646259",
                }}
              />
            </AppNavButton>
          </Stack>
        </Stack>
      </Box>
      <NavigationBar />
      <MobileSearchAndMenuDialog
        open={isMobileMenuDialogOpen}
        onClose={onMobileMenuDialogClose}
      />
    </Box>
  );
});

Header.displayName = "Header";
