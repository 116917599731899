import { IMediaFe, MediaFe, MediaImageThumbSizes } from "models/client/MediaFe";
import Logo from "assets/images/logo-novacoop.png";
import { useMemo } from "react";
import { Resource } from "models/common/Resource";

export const useMenuLinkCard = (resource: Resource) => {
  const {
    data: { media, url },
    type,
  } = useMemo(() => resource, [resource]);

  const isExternalResource = useMemo(
    () => type === "vivicoop" || type === "im-patto" || type === "coopacademy",
    [type]
  );

  const imageLink = useMemo(() => {
    if (media) {
      return isExternalResource || type === "store"
        ? (media as string)
        : new MediaFe(media as IMediaFe).getUrlFromKeyAndExtension(
            MediaImageThumbSizes.SMALL
          );
    } else {
      return Logo;
    }
  }, [isExternalResource, media, type]);

  const clickableDomainLabel = useMemo(() => {
    if (isExternalResource) {
      return `${type}.it`;
    } else {
      return "novacoop.it";
    }
  }, [type, isExternalResource]);

  const resourceTypeLabel = useMemo(() => {
    switch (type) {
      case "article":
        return "Articolo";
      case "page":
        return "Pagina";
      case "store":
        return "Negozio";
      default:
        return "";
    }
  }, [type]);

  const path = useMemo(() => {
    if (isExternalResource) {
      return url;
    } else if (type === "page") {
      return `/${url}`;
    } else if (type === "article") {
      return `/news-eventi/news/${url}`;
    } else if (type === "store") {
      return `/punti-vendita/${url}`;
    }

    return "";
  }, [isExternalResource, type, url]);

  return {
    imageLink,
    clickableDomainLabel,
    resourceTypeLabel,
    path,
    isExternalResource,
  };
};
