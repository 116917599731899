import { useContext } from "react";
import { StoreLocatorContext } from "../contexts/StoreLocatorContext";

export const useStoreContext = () => {
  const context = useContext(StoreLocatorContext);

  if (context === undefined) {
    throw new Error(
      "useStoreContext must be used within a StoreLocatorContextProvider"
    );
  }

  return context;
};
