import { useCallback, useRef } from "react";
import { useStoreContext } from "hooks/useStoreContext";

export const useSearchbarStore = () => {
  const { searchByUserLocation, onCenterChanged, searchByName } =
    useStoreContext();

  const textValueRef = useRef<string>(null);

  const onGeoLocationIconClick = useCallback(() => {
    // Ask for user location
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      const center: google.maps.LatLngLiteral = {
        lat: latitude,
        lng: longitude,
      };

      onCenterChanged(center);
      searchByUserLocation(center, 14);
    });
  }, [searchByUserLocation, onCenterChanged]);

  const onStoreNameChange = useCallback(
    (e) => {
      const value = e.target.value;
      textValueRef.current = value;
      searchByName(value);
    },
    [searchByName]
  );

  const onSearchIconClick = useCallback(
    (_) => {
      searchByName(textValueRef.current);
    },
    [searchByName]
  );

  return { onGeoLocationIconClick, onStoreNameChange, onSearchIconClick };
};
