import React, { memo } from "react";
import { useSearchLandingPage } from "./index.hooks";
import {
  Box,
  IconButton,
  InputAdornment,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";
import { Search, ClearOutlined } from "@mui/icons-material";
import { AppContainer } from "components/AppContainer";
import { ResultsList } from "components/_visual-components/SearchLandingPage/ResultsList";
import { PageTitleV4 } from "components/_visual-components/PageTitleV4";

type SearchLandingPageProps = {};

export const SearchLandingPage = memo(({}: SearchLandingPageProps) => {
  const {
    searchText,
    onSearchTextChange,
    isFetchingResources,
    fetchMore,
    results,
    resultLimit,
    resetText,
    onSubmit,
  } = useSearchLandingPage();

  return (
    <form onSubmit={onSubmit}>
      <PageTitleV4 title={"Cerca nel sito"} />
      <AppContainer sx={{ mt: -5, zIndex: 300 }}>
        <Stack
          spacing={{ xs: 1, md: 0 }}
          direction={{ xs: "column", md: "row" }}
          sx={{
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
            paddingRight: "20px",
          }}
        >
          <InputBase
            placeholder={"Parola chiave..."}
            value={searchText}
            onChange={onSearchTextChange}
            sx={{
              height: "45px",
              width: { xs: "100%", md: "400px" },
              border: "1px solid #AAA79A",
              paddingLeft: "20px",
              borderRadius: "4px",
              backgroundColor: "#FFF",
            }}
            endAdornment={
              <InputAdornment position="end">
                <>
                  <IconButton onClick={resetText} size={"small"} sx={{ mr: 1 }}>
                    <ClearOutlined sx={{ fontSize: "18px" }} />
                  </IconButton>
                  <IconButton
                    color="primary"
                    type={"submit"}
                    sx={{
                      margin: "-1px",
                      borderRadius: "0px 4px 4px 0px",
                      width: "50px",
                      height: "45px",
                      color: "#FFF",
                      "&:hover": {
                        color: "#AAA79A",
                        backgroundColor: "#f1efee",
                      },
                      backgroundColor: "#AAA79A",
                    }}
                  >
                    <Search
                      style={{
                        fontSize: "24px",
                        color: "inherit",
                        borderRadius: "0px 4px 4px 0px",
                        marginLeft: "-4px",
                      }}
                    />
                  </IconButton>
                </>
              </InputAdornment>
            }
          />
          <Box>
            <Typography sx={{ zIndex: "0" }}>
              <b>{results.length}</b> risultati corrispondenti
            </Typography>
          </Box>
        </Stack>
        <ResultsList
          results={results}
          isFetchingResources={isFetchingResources}
          fetchMore={fetchMore}
          resultLimit={resultLimit}
        />
      </AppContainer>
    </form>
  );
});
SearchLandingPage.displayName = "SearchLandingPage";
