import React, { memo } from "react";
import { useMobileSearchAndMenuDialog } from "./index.hooks";
import {
  Box,
  Dialog,
  IconButton,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Image from "next/image";
import logo from "/assets/images/logo-novacoop.png";
import { Close, ExpandMore } from "@mui/icons-material";
import { MenuItem } from "models/common/StaticContents";
import Link from "next/link";
import { AppSearchBar } from "components/_visual-components";

type MobileSearchAndMenuDialogProps = {
  open: boolean;
  onClose?: () => void;
};

export const MobileSearchAndMenuDialog = memo(
  ({ open, onClose }: MobileSearchAndMenuDialogProps) => {
    const { mainMenu } = useMobileSearchAndMenuDialog();

    const renderMenu = (menuData: MenuItem[], level: number) => {
      return menuData.map((menuItem, index) => {
        const isFirstChild = index === 0;
        const hasChildren = (menuItem?.children ?? []).length > 0;
        const hasLink = Boolean(menuItem.path);

        const titleComponent = (
          <Typography
            textTransform={level === 0 ? "uppercase" : "none"}
            sx={{ ml: 2 * level }}
          >
            {menuItem.label}
          </Typography>
        );

        const expandedTextColor =
          level === 0
            ? "#000!important"
            : hasChildren
            ? "#009FDF!important"
            : "#000!important";

        return (
          <Accordion
            key={menuItem.nodeId}
            sx={{
              width: "100%",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
            disableGutters
          >
            <AccordionSummary
              expandIcon={hasChildren && <ExpandMore />}
              disabled={!hasChildren}
              sx={{
                borderTop:
                  level === 0 && isFirstChild
                    ? "1px solid rgba(0, 0, 0, 0.12)"
                    : "none",
                "&.Mui-expanded": {
                  borderTop:
                    isFirstChild && level !== 0
                      ? "none"
                      : "1px solid rgba(0, 0, 0, 0.12)",
                  borderBottom: hasChildren
                    ? "1px solid rgba(0, 0, 0, 0.12)"
                    : "none",
                  "& *": {
                    color: expandedTextColor,
                    fontWeight: hasChildren ? "bold" : "normal",
                  },
                },
                "&.Mui-disabled": {
                  opacity: 1,
                },
              }}
            >
              <Box
                sx={{ pointerEvents: hasLink ? "auto" : "inherit" }}
                onClick={onClose}
              >
                {hasLink ? (
                  <Link href={menuItem.path} passHref>
                    {titleComponent}
                  </Link>
                ) : (
                  titleComponent
                )}
              </Box>
            </AccordionSummary>
            {hasChildren && (
              <AccordionDetails sx={{ py: 0, px: 0 }}>
                {renderMenu(menuItem.children, level + 1)}
              </AccordionDetails>
            )}
          </Accordion>
        );
      });
    };

    return (
      <Dialog
        open={open}
        fullScreen
        PaperProps={{ sx: { backgroundColor: "#F2F1ED" } }}
      >
        <Stack spacing={3} sx={{ mb: 6 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ my: "5px", borderBottom: "1px solid #E1E1E1" }}
          >
            <Box
              component="div"
              sx={{
                position: "relative",
                width: "72px",
                ml: "15px",
                height: "40px",
                cursor: "pointer",
                my: "auto",
              }}
            >
              <Image
                src={logo.src}
                layout="fill"
                priority
                alt="Logo Novacoop"
                objectFit="cover"
              />
            </Box>
            <Box sx={{ borderLeft: "1px solid #E1E1E1", px: 1 }}>
              <IconButton onClick={onClose}>
                <Close />
              </IconButton>
            </Box>
          </Stack>
          <AppSearchBar />
          <Box sx={{ px: 2 }}>{renderMenu(mainMenu, 0)}</Box>
        </Stack>
      </Dialog>
    );
  }
);
MobileSearchAndMenuDialog.displayName = "MobileSearchAndMenuDialog";
