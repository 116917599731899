import React, { memo } from "react";
import { usePageTitleV4 } from "./index.hooks";
import { Box, Stack, Typography } from "@mui/material";
import { AppBreadcrumbs } from "components/AppBreadcrumbs";
import { AppContainer } from "components/AppContainer";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";

export type PageTitleV4Props = VisualComponentProps<{
  title: string;
  removeClipPath?: boolean;
}>;

export const PageTitleV4 = memo(
  ({ title, anchorId, removeClipPath }: PageTitleV4Props) => {
    const { breadcrumbs } = usePageTitleV4();

    return (
      <Box
        id={anchorId ?? undefined}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          backgroundColor: "#F2F1ED",
          clipPath: removeClipPath
            ? undefined
            : {
                xs: "polygon(0 0, 100% 0, 100% 85%, 0 100%) !important",
                md: "polygon(0 0, 100% 0, 100% 75%, 0 100%) !important",
              },
          pt: {
            xs: "30px",
            md: "47px",
          },
          pb: {
            xs: "45px",
            md: "60px",
          },
        }}
      >
        <AppContainer>
          <Stack direction="column" justifyContent="flex-start">
            <AppBreadcrumbs
              breadcrumbs={breadcrumbs}
              currentPageTitle={title}
            />
            <Typography
              component="h1"
              sx={{
                fontSize: {
                  xs: "22px",
                  md: "32px",
                  lg: "42px",
                },
                fontWeight: "bold",
                fontFamily: "Sora",
                marginTop: "8px",
                lineHeight: {
                  xs: 1.2,
                  md: 1,
                },
              }}
            >
              {title}
            </Typography>
          </Stack>
        </AppContainer>
      </Box>
    );
  }
);

PageTitleV4.displayName = "PageTitleV4";
