import React, { memo } from "react";
import { Typography, TypographyProps } from "@mui/material";

type FooterTitleProps = TypographyProps;

export const FooterTitle = memo(({ ...props }: FooterTitleProps) => {
  return (
    <Typography
      color="white"
      fontSize={17}
      lineHeight="1em"
      fontWeight={"bold"}
      fontFamily={"Sora"}
      {...props}
    />
  );
});
FooterTitle.displayName = "FooterTitle";
