import { useCallback, useEffect, useMemo, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { IMediaFe, MediaFe } from "models/client/MediaFe";

export const usePhotoGallerySlider = (images: IMediaFe[]) => {
  const [playAnimation, setPlayAnimation] = useState(false);
  const [animationDirection, setAnimationDirection] = useState<
    "left" | "right"
  >(null);
  const [imgCounter, setImgCounter] = useState(0);
  const [range, setRange] = useState(0);

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("md", "lg"));

  useEffect(() => {
    setImgCounter(0);
    if (isTablet) {
      setRange(2);
    } else if (isPhone) {
      setRange(1);
    } else {
      setRange(4);
    }
  }, [isPhone, isTablet]);

  const forwardArrowHandler = useCallback(() => {
    setImgCounter((i) => Math.min(i + 1, images.length - range + 1));
    setPlayAnimation(true);
    setAnimationDirection("left");
  }, [range, images]);

  const backwardArrowHandler = useCallback(() => {
    setImgCounter((i) => Math.max(i - 1, 0));
    setPlayAnimation(true);
    setAnimationDirection("right");
  }, []);

  const medias = useMemo(
    () => images.map((image) => new MediaFe(image)),
    [images]
  );

  useEffect(() => {
    if (playAnimation) {
      setTimeout(() => setPlayAnimation(false), 150);
    }
  }, [playAnimation]);

  return {
    imgCounter,
    backwardArrowHandler,
    forwardArrowHandler,
    range,
    isPhone,
    playAnimation,
    animationDirection,
    medias,
  };
};
