import React, { memo, ReactNode } from "react";
import { useMap } from "./index.hooks";
import { Box } from "@mui/material";
import MapOptions = google.maps.MapOptions;

type MapProps = {
  children?: ReactNode;
  onIdle: (m: google.maps.Map) => void;
  maxWidth?: string;
  height?: string;
} & MapOptions;

export const Map = memo(
  ({ children, maxWidth = "100vw", height = "75vh", ...props }: MapProps) => {
    const { mapAnchor, map } = useMap({ ...props });

    return (
      <>
        <Box ref={mapAnchor} maxWidth={maxWidth} id="map" height={height} />
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, { map });
          }
        })}
      </>
    );
  }
);
Map.displayName = "Map";
