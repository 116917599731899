import { useStoreContext } from "hooks/useStoreContext";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import LatLngLiteral = google.maps.LatLngLiteral;
import { IStoreFe, StoreFe } from "models/client/StoreFe";
import { useLocalStorageStore } from "../../../hooks/useLocalStorageStore";
import axios from "axios";
import { PageDataContext } from "../../../contexts/PageDataContext";
import { faLayerGroup } from "@fortawesome/pro-regular-svg-icons";
import volantino from "/assets/images/nc-t-volantino.jpg";
import { FamilyRestroom } from "@mui/icons-material";
export const useStoreSmallView = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [userPosition, setUserPosition] = useState<LatLngLiteral>();

  const { localStorageStore, setLocalStorageStore } = useLocalStorageStore();

  const {
    selectedStore,
    searchByUserLocation,
    onNearStoreFound,
    userStore,
    storeArticles,
    setStoreArticles,
  } = useStoreContext();
  const { userProfile } = useContext(PageDataContext);

  const currentStore = useMemo(
    () => (selectedStore ? new StoreFe(selectedStore) : null),
    [selectedStore]
  );

  const StoreViewProps = useMemo(
    () => ({
      closureTime: currentStore.isOpenNow
        ? currentStore.closingTimeToday
        : currentStore.openingTimeToday,
      city: currentStore.city,
      address: currentStore.address,
      slug: currentStore.slug,
      phone: currentStore.phone,
      name: currentStore.name,
      flyers:
        currentStore.flyers?.map((flyer) => {
          return {
            ...flyer,
            description:
              flyer.description ??
              "Scopri tutte le offerte e le promozioni dedicate a te!",
            cover: flyer.cover ?? volantino.src,
          };
        }) ?? [],
      hrefMaps: `http://www.google.com/maps/place/${currentStore.geo[1]},${currentStore.geo[0]}`,
      isOpenStore: currentStore.isOpenNow,
    }),
    [currentStore]
  );

  const onToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const updateSelectedStore = useCallback(async () => {
    await axios
      .patch("/api/users?type=change-store", {
        email: userProfile.email,
        favouriteStoreId: selectedStore?.storeId,
      })
      .then();
  }, [userProfile, selectedStore]);

  const fetchStoreArticles = useCallback(async () => {
    await axios
      .get(`/api/stores/${selectedStore?.storeId}/articles`)
      .then((res) => {
        // Updates articles when the user changes selected store without reloading the page
        setStoreArticles(res?.data?.articles ?? []);
      });
  }, [selectedStore, setStoreArticles]);

  useEffect(() => {
    if (selectedStore) {
      // Whenever the chosen store changes, update its articles list
      fetchStoreArticles().then(() => {
        if (
          userStore?.storeId !== selectedStore.storeId &&
          userProfile?.email
        ) {
          // If the user is logged, update the selection on DB
          updateSelectedStore().then();
        }
      });
    }
  }, [
    selectedStore,
    userStore,
    userProfile,
    fetchStoreArticles,
    updateSelectedStore,
  ]);

  useEffect(() => {
    if (!userPosition && !userStore) {
      navigator.geolocation.getCurrentPosition(function ({ coords }) {
        setUserPosition({ lat: coords.latitude, lng: coords.longitude });
        const getListByGeolocation = (): Promise<IStoreFe[]> => {
          return searchByUserLocation(
            {
              lat: coords.latitude,
              lng: coords.longitude,
            },
            14
          );
        };

        getListByGeolocation().then((list) => {
          // Don't override if the store in local storage was stored manually by the user
          if (
            !Boolean(localStorageStore?.storeId) ||
            localStorageStore.storeMethod !== "Manual"
          ) {
            onNearStoreFound(list[0]);
            setLocalStorageStore({
              storeId: list[0].storeId,
              storeMethod: "Geolocation",
            });
          }
        });
      });
    }
  }, [
    userPosition,
    userStore,
    localStorageStore,
    onNearStoreFound,
    searchByUserLocation,
    setLocalStorageStore,
  ]);

  return { onToggle, isOpen, storeArticles, ...StoreViewProps };
};
