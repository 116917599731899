import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Stack, Typography, Link } from "@mui/material";
import { IMediaFe, MediaFe } from "models/client/MediaFe";
import { memo } from "react";
import { useAssetDownload } from "./index.hooks";
import { faFilePdf, faDownLong } from "@fortawesome/pro-regular-svg-icons";
import { JsUtility } from "models/common/JsUtility";

type AssetDownloadProps = {
  content: {
    title: string;
    description?: string;
    date?: string;
    media?: IMediaFe;
    url?: string;
    isLink: boolean;
  };
};

export const AssetDownload = memo(({ content }: AssetDownloadProps) => {
  const {} = useAssetDownload();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      width="100%"
      sx={{
        py: 2,
        borderBottom: "1px solid #E1E1E1",
        "&:first-of-type": {
          borderTop: "1px solid #E1E1E1",
        },
      }}
    >
      <Box>
        <Typography
          sx={{ fontSize: "19px", fontFamily: "Sora", fontWeight: "bold" }}
        >
          {content.title}
        </Typography>
        <Stack direction="row">
          {!!content.date && (
            <Typography
              sx={{
                color: "#009FDF",
                fontSize: "15px",
                fontWeight: "bold",
                mr: 1,
              }}
            >
              {JsUtility.formatDateCET(content.date)}
            </Typography>
          )}

          {!!content.description && (
            <Link
              href={
                (!content.isLink &&
                  new MediaFe(content.media).getUrlFromKeyAndExtension()) ||
                content.url
              }
              target="_blank"
              sx={{
                textDecoration: "none",
              }}
            >
              <Typography
                component="span"
                sx={{
                  display: "inline",
                  color: "gray",
                  fontSize: "15px",
                }}
              >
                {content.description}
              </Typography>
            </Link>
          )}
        </Stack>
      </Box>
      <Link
        href={
          (!content.isLink &&
            new MediaFe(content.media).getUrlFromKeyAndExtension()) ||
          content.url
        }
        target="_blank"
      >
        <IconButton
          disableRipple
          sx={{
            color: "white",
            alignSelf: "center",
            width: "50px",
            height: "50px",
            bgcolor: "#FF0000",
            boxShadow: "0px 3px 6px#00000029;",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {content.media?.extension === "pdf" ? (
            <FontAwesomeIcon icon={faFilePdf} fontSize="large" />
          ) : (
            <FontAwesomeIcon icon={faDownLong} fontSize="large" />
          )}
        </IconButton>
      </Link>
    </Stack>
  );
});
AssetDownload.displayName = "AssetDownload";
