import {
  Grid,
  Stack,
  Typography,
  Paper,
  Box,
  AlertTitle,
  Alert,
  CircularProgress,
} from "@mui/material";
import React, { memo } from "react";
import { AppContainer } from "components/AppContainer";
import { useUserResetPassword } from "./index.hooks";
import { FormProvider } from "react-hook-form";
import { FormPassword } from "components/_form/FormPassword";
import { CtaButton } from "components/CtaButton";

type UserResetPasswordProps = {
  loadingMessage?: string;
};

export const UserResetPassword = memo(
  ({
    loadingMessage = "Conferma della password in corso",
  }: UserResetPasswordProps) => {
    const { formData, onSubmit, isLoading, messageObj } =
      useUserResetPassword();

    return (
      <FormProvider {...formData}>
        <form onSubmit={onSubmit}>
          <AppContainer sx={{ mt: "40px" }}>
            <Grid
              container
              spacing={2}
              sx={{ flexDirection: { xs: "column", md: "row" } }}
            >
              <Grid item xs={12} md={4}>
                <Paper
                  sx={{ backgroundColor: "#009FDF", px: "36px", py: "42px" }}
                >
                  <Stack spacing={3}>
                    <Typography
                      sx={{
                        fontFamily: "Sora",
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "32px",
                      }}
                    >
                      Resetta la tua password
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Karla",
                        color: "white",
                        fontSize: "23px",
                      }}
                    >
                      Inserisci e conferma la nuova password per accedere
                      nuovamente al tuo account.
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Karla",
                        color: "white",
                        fontSize: "23px",
                      }}
                    >
                      Oppure..
                    </Typography>
                    <Box>
                      <CtaButton text="Vai al login" href="/accesso-soci" />
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
              <Grid item xs={12} md={8}>
                <Paper
                  sx={{
                    py: "36px",
                    px: "42px",
                    border: "1px solid #E1E1E1",
                    boxShadow: "0px 2px 4px #0000000F",
                  }}
                >
                  <Stack spacing={2}>
                    <Typography
                      sx={{
                        fontFamily: "Karla",
                        fontSize: "21px",
                        color: "#AAA79A",
                      }}
                    >
                      Inserisci e conferma la nuova password...
                    </Typography>
                    <Stack spacing="20px">
                      {isLoading ||
                        (messageObj && (
                          <Alert
                            severity={
                              isLoading
                                ? "info"
                                : messageObj.type === "SUCCESS"
                                ? "success"
                                : "error"
                            }
                            sx={{
                              pt: 2,
                              mb: 2,
                            }}
                          >
                            <Stack spacing={3} direction={"row"}>
                              <AlertTitle>
                                {isLoading
                                  ? loadingMessage
                                  : ""
                                  ? "Accesso in corso..."
                                  : messageObj.message}
                              </AlertTitle>
                              {isLoading && (
                                <CircularProgress
                                  sx={{
                                    color: "#009FDF",
                                    fill: "#FFF",
                                  }}
                                  size={20}
                                />
                              )}
                            </Stack>
                          </Alert>
                        ))}
                      <FormPassword
                        color="secondary"
                        name={"password"}
                        label={"Password"}
                        disabled={isLoading}
                      />
                      <FormPassword
                        color="secondary"
                        name={"confirmPassword"}
                        label={"Conferma Password"}
                        disabled={isLoading}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        mt: 1,
                      }}
                    >
                      <Box>
                        <CtaButton
                          type="submit"
                          text="Reset Password"
                          disabled={isLoading}
                        />
                      </Box>
                    </Stack>
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          </AppContainer>
        </form>
      </FormProvider>
    );
  }
);
UserResetPassword.displayName = "UserResetPassword";
