import { useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";

const schema = yup.object().shape({
  email: yup.string().required("Inserisci un'email valida"),
});

type UserRecoveryPasswordFormData = {
  email: string;
};

export const useUserRecoveryPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageObj, setMessageObj] = useState<
    | {
        type: "ERROR" | "SUCCESS";
        message: string;
      }
    | undefined
  >(undefined);

  const formData = useForm<UserRecoveryPasswordFormData>({
    resolver: yupResolver(schema),
    defaultValues: { email: "" },
  });
  const { handleSubmit } = formData;

  const handleRequest = useCallback(
    async (data: UserRecoveryPasswordFormData) => {
      try {
        setIsLoading(true);
        const res = await axios.post("/api/usersRecoveryPasswords", {
          email: data.email,
        });
        if (res.status === 200) {
        }
      } catch (e) {
      } finally {
        setIsLoading(false);
        setMessageObj({
          message:
            "Se è associato un account a questa email riceverai presto un link per il recupero della password",
          type: "SUCCESS",
        });
      }
    },
    []
  );

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (data) => {
        await handleRequest(data);
      }),
    [handleSubmit, handleRequest]
  );
  return {
    formData,
    onSubmit,
    isLoading,
    messageObj,
  };
};
