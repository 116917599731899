import { Close, FormatListBulleted, Map } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import { StoreListItem } from "components/FindYourStore/StoreListItem";
import { useFindYourStore } from "components/FindYourStore/index.hooks";
import { SearchbarStore } from "components/FindYourStore/SearchbarStore";
import { StoreList } from "components/_visual-components/StoreLocator/StoreList";
import { GoogleMaps } from "components/_visual-components/StoreLocator/GoogleMaps";

type FindYourStoreProps = {
  name: string;
  onClose?: (e) => void;
};

export const FindYourStore = memo(({ onClose }: FindYourStoreProps) => {
  const {
    isOpenList,
    openList,
    closeList,
    clickedStore,
    markerClickHandler,
    stores,
  } = useFindYourStore();

  return (
    <>
      <Container maxWidth="md" sx={{ position: "relative", zIndex: 10000 }}>
        <Card>
          <CardContent
            sx={(theme) => ({
              backgroundColor: theme.palette.primary.main,
              padding: "30px",
            })}
          >
            <Grid container spacing={10} justifyContent="space-between">
              <Grid item xs={8} md={10}>
                <Typography
                  variant="h4"
                  sx={{
                    color: "white",
                    fontWeight: 800,
                  }}
                  gutterBottom
                >
                  Scegli il tuo negozio Coop
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: "white",
                    fontWeight: 300,
                  }}
                  gutterBottom
                >
                  Scopri promozioni, offerte ed opportunità dedicati a te!
                </Typography>
              </Grid>
              <Grid container justifyContent="flex-end" item xs={4} md={2}>
                <IconButton
                  sx={{
                    color: "#fff7f7",
                    "&:hover": {
                      background: "none",
                    },
                  }}
                  aria-label="settings"
                  onClick={onClose}
                >
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          </CardContent>
          <Grid
            container
            justifyContent="space-around"
            sx={{
              backgroundColor: "#f2f1ed",
              px: { sm: "30px", xs: "auto" },
              py: "30px",
            }}
          >
            <Grid item sm={10}>
              <SearchbarStore />
            </Grid>

            <Grid item sm={2}>
              <Stack direction="row" justifyContent="flex-end">
                <Box
                  sx={{
                    backgroundColor: isOpenList ? "white" : "#aaa79a",
                    borderRadius: " 4px 0px 0px 4px",
                    border: "1px solid #aaa79a",
                  }}
                >
                  <IconButton
                    onClick={closeList}
                    disabled={!isOpenList}
                    sx={{
                      color: isOpenList ? "#aaa79a" : "white",
                      "&:hover": {
                        background: "none",
                      },
                    }}
                  >
                    <Map />
                  </IconButton>
                </Box>

                <Box
                  sx={{
                    backgroundColor: isOpenList ? "#aaa79a" : "white",
                    borderRadius: " 0px 4px 4px 0px",
                    border: "1px solid #aaa79a",
                  }}
                >
                  <IconButton
                    onClick={openList}
                    disabled={isOpenList}
                    sx={{
                      color: isOpenList ? "white" : "#aaa79a",
                      "&:hover": {
                        background: "none",
                      },
                    }}
                  >
                    <FormatListBulleted />
                  </IconButton>
                </Box>
              </Stack>
            </Grid>
          </Grid>

          <Box
            sx={{
              position: "relative",
              maxHeight: "365px",
            }}
          >
            <GoogleMaps
              markers={stores}
              onMarkerClick={markerClickHandler}
              mapHeight="365px"
            />
            {/* Box store */}
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                height: "111px",
                transition: "all 0.5s",
                backgroundColor: "white",
                overflow: "hidden",
                transform: clickedStore ? "translateY(0%)" : "translateY(100%)",
              }}
            >
              <CardContent
                sx={{
                  px: {
                    xs: "10px",
                    sm: "20px",
                  },
                  zIndex: 1,
                }}
              >
                {clickedStore && (
                  <StoreListItem
                    hideSelectedTag
                    inDialog
                    onClose={onClose}
                    store={clickedStore}
                  />
                )}
              </CardContent>
            </Box>
            {/*Box store list*/}
            <Box
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                width: "100%",
                height: "100%",
                overflow: "auto",
                transition: "all 0.5s",
                backgroundColor: "white",
                transform: isOpenList ? "translateY(0%)" : "translateY(100%)",
                "::-webkit-scrollbar": { display: "none" },
              }}
            >
              <StoreList onClose={onClose} inDialog />
            </Box>
          </Box>
        </Card>
      </Container>
    </>
  );
});
FindYourStore.displayName = "FindYourStore";
