import React, { useCallback, useEffect, useRef, useState } from "react";
import MapOptions = google.maps.MapOptions;

export const useMap = (
  props: {
    onIdle: (m: google.maps.Map) => void;
  } & MapOptions
) => {
  const mapAnchor = useRef(null);
  const [mapInstance, setMapInstance] = useState<google.maps.Map>(null);

  const { onIdle } = props;

  const previousProps = useRef<typeof props>(null);

  const arePropsEqual = useCallback(
    () =>
      JSON.stringify(props ?? {}) ===
      JSON.stringify(previousProps?.current ?? {}),
    [props]
  );

  useEffect(() => {
    if (mapInstance && !arePropsEqual()) {
      mapInstance.setOptions(props);
      previousProps.current = props;
    }
  }, [mapInstance, props, arePropsEqual]);

  useEffect(() => {
    if (mapAnchor.current && !mapInstance) {
      setMapInstance(new google.maps.Map(mapAnchor.current, {}));
    }
  }, [mapAnchor, mapInstance]);

  useEffect(() => {
    if (mapInstance && onIdle) {
      google.maps.event.clearListeners(mapInstance, "idle");
      mapInstance.addListener("idle", () => onIdle(mapInstance));
    }
  }, [mapInstance, onIdle]);

  return { mapAnchor, map: mapInstance };
};
