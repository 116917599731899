import * as yup from "yup";

export type CardUserDetails = {
  autorizzazione: "S" | "N";
  nome: string;
  cognome: string;
  comuneRes: string;
  provRes: string;
  provResIso: string;
  cartaRelazione: string;
  cartaValida: string;
  statoCarta: "A" | "D";
  registrato: "S" | "N";
  userDisableApp: boolean;
  userDisableEcommerce: boolean;
};

export type UserProfile = {
  coopId: string;
  userId: string;
  email: string;
  name: string;
  surname: string;
  birthDate: string;
  gender: string;
  residenceProvince: string;
  residenceCity: string;
  fidelityCards?: {
    eanCard: string;
    coopId: number;
    coopName: string;
    registred: boolean;
  }[];
  privacy: {
    id: string;
    version: string;
    value: string;
    privacyType: string;
  }[];
};

export const createUserSchema = yup.object().shape({
  birthDate: yup.string().required("Inserisci la tua data di nascita"),
  email: yup.string().when("socioCoop", {
    is: false,
    then: yup.string().required("Inserisci un'email valida"),
  }),
  password: yup.string().when("socioCoop", {
    is: false,
    then: yup.string().required("Inserisci la tua password"),
  }),
  name: yup.string().when("socioCoop", {
    is: false,
    then: yup.string().required("Inserisci il tuo nome"),
  }),
  surname: yup.string().when("socioCoop", {
    is: false,
    then: yup.string().required("Inserisci il tuo cognome"),
  }),
  province: yup.string().when("socioCoop", {
    is: false,
    then: yup.string().required("Inserisci la tua provincia di residenza"),
  }),
  city: yup.string().when("socioCoop", {
    is: false,
    then: yup.string().required("Inserisci il tuo comune di residenza"),
  }),
  gender: yup.string().when("socioCoop", {
    is: false,
    then: yup.string().required("Inserisci il tuo genere"),
  }),
});
