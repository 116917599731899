import React, { FC, memo, PropsWithChildren } from "react";
import { useAppNavButton } from "./index.hooks";
import { Button, ButtonProps } from "@mui/material";

type AppNavButtonProps = {
  startIcon?: React.ReactNode;
} & ButtonProps;

export const AppNavButton: FC<PropsWithChildren<AppNavButtonProps>> = memo(
  ({ startIcon, children, sx, ...props }) => {
    const {} = useAppNavButton();
    return (
      <Button
        disableRipple
        disableElevation
        variant="text"
        startIcon={startIcon}
        sx={{
          ...sx,
          minWidth: { xs: "50px", md: "auto" },

          alignItems: { xs: "center", md: "auto" },
          justifyContent: { xs: "center", md: "auto" },
          aspectRatio: { xs: "1/1", md: "auto" },
          opacity: 1,
          textTransform: "none",
          height: "inherit",
          "&.MuiButtonBase-root:hover": {
            backgroundColor: "transparent",
          },
          borderRight: "1px solid #E9E9E9",
          "&:first-child": {
            borderLeft: "1px solid #E9E9E9",
          },

          px: 3,
          borderRadius: 0,
        }}
        {...props}
      >
        {children}
      </Button>
    );
  }
);
AppNavButton.displayName = "AppNavButton";
