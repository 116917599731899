import React, { memo } from "react";
import { usePressReleaseCategoryItem } from "./index.hooks";
import { Box, Icon, Typography } from "@mui/material";
import Link from "next/link";
import ArrowForward from "@mui/icons-material/ArrowForwardIos";

type PressReleaseCategoryItemProps = {
  title: string;
  slug: string;
};

export const PressReleaseCategoryItem = memo(
  ({ title, slug }: PressReleaseCategoryItemProps) => {
    const {} = usePressReleaseCategoryItem();

    return (
      <Link href={`/area-stampa/${slug}`} passHref>
        <Box
          px={2.25}
          maxWidth="100%"
          height={75}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderRadius="6px"
          border="3px solid #AAA79A"
          color="#646259"
          sx={{ "&:hover": { opacity: 0.7 }, cursor: "pointer" }}
        >
          <Typography sx={{ font: "700 21px/48px Sora" }}>{title}</Typography>
          <Icon>
            <ArrowForward />
          </Icon>
        </Box>
      </Link>
    );
  }
);
PressReleaseCategoryItem.displayName = "PressReleaseCategoryItem";
