import { useStoreContext } from "hooks/useStoreContext";
import { useCallback, useMemo, useState } from "react";
import { IStoreFe, StoreFe } from "models/client/StoreFe";

export const useStoreListItem = (store: IStoreFe) => {
  const { onStoreSelected, selectedStore } = useStoreContext();

  const storeFe = useMemo(() => new StoreFe(store), [store]);

  const selected = useMemo(
    () => selectedStore?._id === store._id,
    [selectedStore, store]
  );

  const onStoreClicked = useCallback(() => {
    onStoreSelected(store);
  }, [store, onStoreSelected]);

  return { onStoreClicked, selected, storeFe };
};
