import React, { memo, useCallback } from "react";
import { useResultCard } from "./index.hooks";
import { Resource } from "models/common/Resource";
import { Box, Stack, Typography } from "@mui/material";
import Image from "next/image";
import "moment/locale/it";
import Link from "next/link";
import { JsUtility } from "models/common/JsUtility";

type ResultCardProps = { medium: boolean } & Resource;

export const ResultCard = memo(({ medium, ...resource }: ResultCardProps) => {
  const { data, type } = resource;

  const {
    resourceTypeLabel,
    path,
    clickableDomainLabel,
    imageLink,
    isExternalResource,
  } = useResultCard(resource);

  const renderDomain = () => (
    <Typography
      fontSize={12}
      fontWeight="bold"
      fontFamily="Sora"
      color="#3dbfd5"
    >
      {"☍ " + clickableDomainLabel}
    </Typography>
  );
  const renderContent = () => (
    <Stack
      direction="row"
      spacing={1}
      sx={{ py: 1, height: "100%", cursor: "pointer" }}
    >
      <Box
        sx={{
          position: "relative",
          height: medium ? 100 : 60,
          aspectRatio: "1/1",
          minWidth: medium ? 100 : 60,
          border: "2px solid #F2F2F2",
          borderRadius: "5px",
          overflow: "hidden",
          "& *": {
            height: "100% !important",
            aspectRatio: "1 / 1 !important",
          },
        }}
      >
        <Image
          layout="fill"
          objectFit="cover"
          src={imageLink}
          alt={data?.description}
        />
      </Box>
      <Stack
        spacing={1}
        sx={{ height: "auto", py: 0.5, justifyContent: "space-between" }}
      >
        {type === "im-patto" ? (
          <Typography
            fontSize={13}
            fontWeight="bold"
            color="#151319"
            fontFamily="Sora"
            sx={{
              m: 0,
              p: 0,
              "& *": {
                m: 0,
                p: 0,
              },
            }}
            dangerouslySetInnerHTML={{ __html: data?.title }}
          />
        ) : (
          <Typography
            fontSize={13}
            fontWeight="bold"
            color="#151319"
            fontFamily="Sora"
          >
            {data?.title}
          </Typography>
        )}
        {isExternalResource ? (
          <Typography
            fontSize={11}
            color="#646259"
            fontFamily="Sora"
            sx={{
              minWidth: medium ? 100 : undefined,
              maxWidth: medium ? undefined : 400,
              display: "-webkit-box",
              wordWrap: "break-word",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
              m: 0,
              p: 0,
              "& *": {
                m: 0,
                p: 0,
              },
            }}
            dangerouslySetInnerHTML={{ __html: data?.description }}
          />
        ) : (
          <Typography
            fontSize={11}
            color="#646259"
            fontFamily="Sora"
            sx={{
              minWidth: medium ? 100 : undefined,
              maxWidth: medium ? undefined : 400,
              display: "-webkit-box",
              wordWrap: "break-word",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3,
            }}
          >
            {data?.description}
          </Typography>
        )}
        {medium && (
          <Typography fontFamily="Sora" fontSize={12} color="#bab8a9">
            {resourceTypeLabel}
          </Typography>
        )}
        {!medium && renderDomain()}
        {!!data?.date && (
          <Typography
            fontSize={12}
            fontWeight="bold"
            fontFamily="Sora"
            color="#3dbfd5"
          >
            {JsUtility.formatDateCET(data?.date, "LL")}
          </Typography>
        )}
      </Stack>
    </Stack>
  );

  if (isExternalResource) {
    return (
      <a
        target={isExternalResource ? "_blank" : "_self"}
        rel={"noreferrer"}
        href={path}
      >
        {renderContent()}
      </a>
    );
  } else {
    return (
      <Link href={path} passHref>
        <a target={"_self"} href={path}>
          {renderContent()}
        </a>
      </Link>
    );
  }
});

ResultCard.displayName = "ResultCard";
