import React, { memo } from "react";
import { useFloatingImageBanner } from "./index.hooks";
import { Box, Stack, Typography } from "@mui/material";
import { IMediaFe, MediaImageThumbSizes } from "models/client/MediaFe";
import Image from "next/image";
import { AppContainer } from "components/AppContainer";
import TesseraSocio from "assets/images/novacoop-tessera-sociocoop.png";
import { CtaButton } from "components/CtaButton";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";

export type FloatingImageBannerProps = VisualComponentProps<{
  rightImage: IMediaFe;
  buttonText: string;
  buttonLink: string;
  title: string;
  description: string;
}>;

export const FloatingImageBanner = memo(
  ({
    rightImage,
    buttonText,
    title,
    description,
    buttonLink,
    anchorId,
  }: FloatingImageBannerProps) => {
    const { rightImage: right } = useFloatingImageBanner(rightImage);

    return (
      <AppContainer
        sx={{
          mt: {
            xs: "50px",
            md: "250px",
          },
        }}
        id={anchorId ?? undefined}
        fullScreen
      >
        <Box
          sx={{
            textAlign: "left",
            width: {
              xs: "100%",
            },
            justifyContent: {
              xs: "space-around",
              md: "space-between",
            },
            position: "relative",
            background:
              "linear-gradient(90deg, #005070 0%, #006A94 27%,#009FDF  95%)",
            borderRadius: {
              xs: "0px",
              xl: "6px",
            },
            minHeight: {
              xs: "auto",
              md: "460px",
            },
          }}
        >
          <Box
            sx={{
              width: {
                xs: "191px",
                md: "320px",
              },
              height: {
                xs: "133px",
                md: "225px",
              },
              objectFit: "contain",
              position: "absolute",
              top: {
                xs: "-70px",
                md: "-145px",
              },
              left: {
                xs: "50%",
                md: "85px",
              },
              ml: {
                xs: "-92px",
                md: "0px",
              },
            }}
          >
            <Image
              src={TesseraSocio.src}
              layout={"fill"}
              objectFit={"cover"}
              alt={"Tessera socio"}
            />
          </Box>

          <Stack
            sx={{
              width: "50%",
              minWidth: {
                xs: "100%",
                md: "400px",
              },
              minHeight: {
                xs: "auto",
                md: "460px",
              },
              pt: {
                xs: "83px",
                md: "130px",
              },
              px: {
                xs: "60px",
                md: "85px",
              },
              pb: {
                xs: "50px",
                md: "85px",
              },
              justifyContent: "space-between",
              alignItems: {
                xs: "center",
                md: "flex-start",
              },
            }}
            spacing="24px"
          >
            <Stack>
              <Typography
                sx={{
                  fontFamily: "Sora",
                  fontWeight: "bold",
                  fontSize: {
                    xs: "24px",
                    md: "42px",
                  },
                  lineHeight: {
                    xs: "29px",
                    md: "42px",
                  },
                  color: "white",
                  textAlign: {
                    xs: "center",
                    md: "left",
                  },
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "16px",
                    md: "24px",
                  },
                  lineHeight: {
                    xs: "29px",
                    md: "42px",
                  },
                  fontFamily: "Sora",
                  marginTop: {
                    xs: "20px",
                    md: "40px",
                  },
                  color: "white",
                  flex: 1,
                  textAlign: {
                    xs: "center",
                    md: "left",
                  },
                }}
              >
                {description}
              </Typography>
            </Stack>

            <Box>
              <CtaButton text={buttonText} href={buttonLink} target="_blank" />
            </Box>
          </Stack>

          <Box
            sx={{
              width: "50%",
              height: "100%",
              position: "absolute",
              right: 0,
              bottom: 0,
              borderTopRightRadius: {
                xs: "0px",
                xl: "6px",
              },
              borderBottomRightRadius: {
                xs: "0px",
                xl: "6px",
              },
              overflow: "hidden",
              display: {
                xs: "none",
                md: "block",
              },
            }}
          >
            <Image
              src={right.getUrlFromKeyAndExtension(MediaImageThumbSizes.LARGE)}
              alt={right?.title}
              objectFit={"cover"}
              layout={"fill"}
            />
          </Box>
        </Box>
      </AppContainer>
    );
  }
);

FloatingImageBanner.displayName = "FloatingImageBanner";
