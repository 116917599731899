import React, { memo } from "react";
import { useAppContainer } from "./index.hooks";
import { Container, ContainerProps } from "@mui/material";

type AppContainerProps = {
  fullScreen?: boolean;
} & ContainerProps;

export const AppContainer = memo(
  ({ fullScreen = false, sx, ...props }: AppContainerProps) => {
    const {} = useAppContainer();

    return (
      <Container
        maxWidth="xl"
        sx={[
          {
            px: fullScreen ? "0 !important" : "40px !important",
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...props}
      />
    );
  }
);
AppContainer.displayName = "AppContainer";
