import React, { memo } from "react";
import { useHighlightContent } from "./index.hooks";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { AppContainer } from "components/AppContainer";
import { CtaButton } from "components/CtaButton";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";

export type HighlightContentProps = VisualComponentProps<{
  title: string;
  text: string;
  href: string;
  buttonText: string;
}>;

export const HighlightContent = memo(
  ({ title, text, href, buttonText, anchorId }: HighlightContentProps) => {
    const { hasCta, isMobile } = useHighlightContent(href, buttonText);

    return (
      <AppContainer id={anchorId ?? undefined}>
        <Paper
          sx={{
            backgroundColor: "#009fdf",
            color: "#FFF",
            textAlign: "center",
            borderRadius: "5px",
            p: { xs: 3, sm: 4, md: 6 },
            mt: "50px",
          }}
        >
          <Stack spacing={3}>
            <Typography
              sx={{
                fontFamily: "Sora",
                fontSize: {
                  xs: "22px",
                  md: "32px",
                },
                fontWeight: "bold",
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Karla",
                fontWeight: "regular",
              }}
            >
              {text}
            </Typography>
            {hasCta && (
              <Box>
                <CtaButton text={buttonText} href={href} fullWidth={isMobile} />
              </Box>
            )}
          </Stack>
        </Paper>
      </AppContainer>
    );
  }
);

HighlightContent.displayName = "HighlightContent";
