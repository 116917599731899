import React, { memo } from "react";
import { usePhotoGallerySlider } from "./index.hooks";
import {
  Box,
  Container,
  css,
  IconButton,
  keyframes,
  Stack,
} from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBackIosNew";
import ArrowForward from "@mui/icons-material/ArrowForwardIos";
import { styled } from "@mui/material/styles";
import { IMediaFe, MediaImageThumbSizes } from "models/client/MediaFe";
import Image from "next/image";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";

interface ImageProps {
  alt?: string;
  src: string;
  animate: boolean;
  direction: "left" | "right";
}

const slideLeft = keyframes`
    0% {
      transform: translateX(-280px);
    }
    100% {
      transform: translateX(0px);
    }
  `;
const slideRight = keyframes`
    0% {
      transform: translateX(280px);
    }
    100% {
      transform: translateX(0px);
    }
  `;

const ImageBox = styled(Box)`
  object-fit: cover;
  height: 280px;
  width: 280px;
  border-radius: 5px;
  margin-inline: 5px;
  user-select: none;
  ${(props: Pick<ImageProps, "animate" | "direction">) =>
    props.animate &&
    props.direction === "left" &&
    css`
      animation: ${slideRight} 0.15s ease-in-out;
    `}
  ${(props: Pick<ImageProps, "animate" | "direction">) =>
    props.animate &&
    props.direction === "right" &&
    css`
      animation: ${slideLeft} 0.15s ease-in-out;
    `}
`;

const StyledButton = styled(IconButton)`
  height: 42px;
  width: 42px;
  border-radius: 4px;
  opacity: 80%;
  border: 0.5px solid rgba(67, 69, 67, 0.6);
`;

export type PhotoGallerySliderProps = VisualComponentProps<{
  images: IMediaFe[];
}>;

export const PhotoGallerySlider = memo(
  ({ images, anchorId }: PhotoGallerySliderProps) => {
    const {
      imgCounter,
      backwardArrowHandler,
      forwardArrowHandler,
      range,
      isPhone,
      playAnimation,
      animationDirection,
      medias,
    } = usePhotoGallerySlider(images);

    return (
      <Container sx={{ py: 4 }} id={anchorId ?? undefined} maxWidth={"xl"}>
        <Stack
          direction="row"
          spacing={1.5}
          justifyContent="center"
          alignItems="center"
        >
          <StyledButton
            onClick={backwardArrowHandler}
            disabled={
              imgCounter === 0 || playAnimation || images.length < range
            }
            sx={{
              display: isPhone ? "none" : "inline-flex",
              border:
                (imgCounter === 0 || images.length <= range) &&
                "0.5px solid rgba(67, 69, 67,0.25)",
              mr: 1.5,
            }}
          >
            <ArrowBack sx={{ fontSize: 16, mr: 0.2 }} />
          </StyledButton>
          <Box
            overflow="hidden"
            m="0px !important"
            onSelect={(e) => e.preventDefault()}
            maxWidth="1300px"
            sx={{
              display: "flex",
              flexDirection: "row",
              whiteSpace: "nowrap",
            }}
          >
            {medias
              .filter(
                (media, index) =>
                  index < imgCounter + range && index >= imgCounter
              )
              .map((media, index) => (
                <ImageBox
                  key={index}
                  component="div"
                  animate={playAnimation}
                  direction={animationDirection}
                  sx={{ position: "relative", width: "280px", height: "280px" }}
                >
                  <Image
                    layout={"fill"}
                    objectFit={"cover"}
                    src={media.getUrlFromKeyAndExtension(
                      MediaImageThumbSizes.MEDIUM
                    )}
                    alt={media.title}
                  />
                </ImageBox>
              ))}
          </Box>
          <StyledButton
            onClick={forwardArrowHandler}
            disabled={
              imgCounter === medias.length - range ||
              playAnimation ||
              medias.length < range
            }
            sx={{
              display: isPhone ? "none" : "inline-flex",
              border:
                (imgCounter === medias.length - range ||
                  medias.length <= range) &&
                "0.5px solid rgba(67, 69, 67,0.25)",
            }}
          >
            <ArrowForward sx={{ fontSize: 16, ml: 0.2 }} />
          </StyledButton>
        </Stack>
        {isPhone && (
          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            marginTop={1.5}
          >
            <StyledButton
              onClick={backwardArrowHandler}
              disabled={imgCounter === 0}
              sx={{
                border: imgCounter === 0 && "0.5px solid rgba(67, 69, 67,0.25)",
              }}
            >
              <ArrowBack sx={{ fontSize: 16, mr: 0.2 }} />
            </StyledButton>
            <StyledButton
              onClick={forwardArrowHandler}
              disabled={imgCounter === medias.length - range}
              sx={{
                border:
                  imgCounter === medias.length - range &&
                  "0.5px solid rgba(67, 69, 67,0.25)",
              }}
            >
              <ArrowForward sx={{ fontSize: 16, ml: 0.2 }} />
            </StyledButton>
          </Stack>
        )}
      </Container>
    );
  }
);
PhotoGallerySlider.displayName = "PhotoSlideShow";
