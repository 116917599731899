import React, { memo } from "react";
import { useMainSlider } from "./index.hooks";
import {
  Box,
  ButtonGroup,
  Stack,
  Typography,
  Card,
  Grid,
  IconButton,
  Radio,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { AppContainer } from "components/AppContainer";
import { IMediaFe } from "models/client/MediaFe";
import { CtaButton } from "components/CtaButton";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";
import dynamic from "next/dynamic";

const Hammer: any = dynamic(() => import("react-hammerjs"), {
  loading: ({ children }: any) => <>{children}</>,
});

export type MainSliderProps = VisualComponentProps<{
  slides: {
    title: string;
    description: string;
    image: IMediaFe;
    buttonLabel: string;
    buttonLink: string;
  }[];
}>;

export const MainSlider = memo(({ slides, anchorId }: MainSliderProps) => {
  const {
    isMobile,
    slideNum,
    currentSlide,
    setSlideAtIndexCallbacks,
    imagesUrl,
    navigateNext,
    navigatePrev,
  } = useMainSlider(slides);

  const renderControls = () => {
    const horizontalPositioning = isMobile ? { right: 10 } : { left: 10 };
    const verticalPositioning = isMobile ? { bottom: 0 } : { top: 10 };

    return (
      <Stack
        direction="row"
        sx={{
          position: "absolute",
          ...verticalPositioning,
          ...horizontalPositioning,
        }}
      >
        <ButtonGroup variant="outlined">
          <IconButton sx={{ color: "#FFFFFF" }} onClick={navigatePrev}>
            <ArrowBack />
          </IconButton>
          <IconButton sx={{ color: "#FFFFFF" }} onClick={navigateNext}>
            <ArrowForward />
          </IconButton>
        </ButtonGroup>
      </Stack>
    );
  };
  const renderNavigationDots = () => {
    const horizontalPositioning = isMobile ? { left: 10 } : { right: 20 };

    return (
      <Stack
        direction={isMobile ? "row" : "column"}
        spacing={0.6}
        sx={{
          position: "absolute",
          bottom: 20,
          ...horizontalPositioning,
        }}
      >
        {slides?.map((s, index) => (
          <Radio
            key={s.image._id}
            checked={slideNum === index}
            onClick={setSlideAtIndexCallbacks[index]}
            sx={{
              color: "#FFF",
              width: 10,
              height: 10,
              transform: "scale(.8)",
              "&.Mui-checked": {
                color: "white",
              },
            }}
          />
        ))}
      </Stack>
    );
  };
  const renderTextsSection = () => {
    return (
      <Stack
        direction="column"
        sx={{
          height: "100%",
          position: "relative",
          justifyContent: "flex-end",
          p: 4,
        }}
      >
        <Stack direction="column" spacing={2}>
          <Typography
            variant="h2"
            sx={(theme) => ({
              color: "#FFF",
              fontSize: {
                xs: 20,
                sm: theme.typography.h2.fontSize,
              },
            })}
          >
            {currentSlide?.title}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={(theme) => ({
              color: "#FFF",
              fontSize: {
                xs: 14,
                sm: theme.typography.subtitle2.fontSize,
              },
            })}
          >
            {currentSlide?.description}
          </Typography>
          <Box>
            <CtaButton
              text={currentSlide?.buttonLabel}
              href={currentSlide?.buttonLink ?? "/"}
            />
          </Box>
        </Stack>
        {!isMobile && renderControls()}
      </Stack>
    );
  };
  const renderImagesSection = () => {
    return (
      <>
        <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
          {imagesUrl.map((src, index) => (
            <Box
              key={index}
              component="img"
              src={src}
              sx={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
                position: "absolute",
                opacity: slideNum === index ? 1 : 0,
                transform: {
                  xs: `translateX(${(index - slideNum) * 100}%)`,
                  sm: `translateY(${(index - slideNum) * 100}%)`,
                },
                transition: "all 0.5s",
              }}
            />
          ))}
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            top: { xs: "50%", sm: 0 },
            left: { xs: 0, sm: "50%" },
            right: 0,
            background: `linear-gradient(${
              isMobile ? 180 : 90
            }deg, transparent 50%, #009FDFBF 100%)`,
          }}
        />
      </>
    );
  };

  return (
    <AppContainer
      id={anchorId ?? undefined}
      fullScreen
      sx={{
        mt: {
          xs: 0,
          sm: "47px",
        },
      }}
    >
      <Card
        sx={{
          display: "flex",
          width: "100%",
          borderRadius: isMobile ? 0 : undefined,
        }}
      >
        <Grid
          container
          direction={isMobile ? "column-reverse" : "row"}
          sx={{
            height: "100%",
            width: "100%",
            color: "#FFFFFF",
          }}
        >
          <Grid
            item
            xs={12}
            sm={5}
            sx={{
              background:
                "transparent linear-gradient(to left, #009FDF 0%, #005070 100%) 0% 0% no-repeat padding-box",
            }}
          >
            {renderTextsSection()}
          </Grid>
          <Hammer onSwipeLeft={navigateNext} onSwipeRight={navigatePrev}>
            <Grid
              item
              xs={12}
              sm={7}
              sx={{
                position: "relative",
                width: "100%",
                aspectRatio: "16 / 9",
              }}
            >
              {imagesUrl?.[slideNum] && renderImagesSection()}
              {isMobile && renderControls()}
              {renderNavigationDots()}
            </Grid>
          </Hammer>
        </Grid>
      </Card>
    </AppContainer>
  );
});

MainSlider.displayName = "MainSlider";
