import React, { memo } from "react";
import { usePressReleaseCategoriesList } from "./index.hooks";
import { Stack } from "@mui/material";
import { PressReleaseCategoryItem } from "./PressReleaseCategoryItem";
import { AppContainer } from "../../AppContainer";

type PressReleaseCategoriesListProps = {};

export const PressReleaseCategoriesList = memo(
  ({}: PressReleaseCategoriesListProps) => {
    const { pressReleaseCategories } = usePressReleaseCategoriesList();

    return (
      <AppContainer>
        <Stack
          sx={{
            my: 4,
            width: "100%",
          }}
          rowGap={1.25}
        >
          {(pressReleaseCategories ?? []).map((prc) => (
            <PressReleaseCategoryItem {...prc} key={prc._id} />
          ))}
        </Stack>
      </AppContainer>
    );
  }
);
PressReleaseCategoriesList.displayName = "PressReleaseCategoriesList";
