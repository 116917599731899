import { Box, Breakpoint } from "@mui/material";
import React, { memo } from "react";
import { useRichText } from "./index.hooks";
import { AppContainer } from "components/AppContainer";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";

export type RichTextProps = VisualComponentProps<{
  htmlData: string;
  maxWidth: Breakpoint | null;
}>;

export const RichText = memo(
  ({ htmlData, maxWidth = "md", anchorId }: RichTextProps) => {
    const {} = useRichText();

    const renderContent = () => {
      return (
        <Box
          sx={{
            fontFamily: "Karla",
            lineHeight: 1.5,
            "& *": {
              my: 0,
            },
            "& ul, & p, & ol": {
              fontSize: "18px!important",
              lineHeight: "26px!important",
            },
          }}
        >
          <Box
            className="rich-text"
            dangerouslySetInnerHTML={{ __html: htmlData }}
          />
        </Box>
      );
    };

    if (maxWidth !== null) {
      return (
        <AppContainer
          id={anchorId ?? undefined}
          maxWidth={maxWidth}
          sx={{
            mt: 6,
          }}
        >
          {renderContent()}
        </AppContainer>
      );
    }

    return renderContent();
  }
);

RichText.displayName = "RichText";
