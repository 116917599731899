import React, { memo } from "react";
import { useMenuLinkCard } from "./index.hooks";
import { Box, Stack, Typography } from "@mui/material";
import Image from "next/image";
import { Resource } from "models/common/Resource";
import Link from "next/link";

export type MenuLinkCardProps = {
  medium?: boolean;
  resource: Resource;
};

export const MenuLinkCard = memo(
  ({ medium = false, resource }: MenuLinkCardProps) => {
    const {
      imageLink,
      clickableDomainLabel,
      resourceTypeLabel,
      path,
      isExternalResource,
    } = useMenuLinkCard(resource);

    const {
      data: { title, description },
    } = resource;

    const renderDomain = () => (
      <Typography
        fontSize={12}
        fontWeight="bold"
        fontFamily="Sora"
        color="#3dbfd5"
      >
        {"☍ " + clickableDomainLabel}
      </Typography>
    );

    return (
      <Link href={path} passHref>
        <a target={isExternalResource ? "_blank" : "_self"}>
          <Stack direction="row" spacing={1} sx={{ py: 1, cursor: "pointer" }}>
            <Box
              sx={{
                position: "relative",
                height: medium ? 100 : 60,
                aspectRatio: "1/1",
                minWidth: medium ? 100 : 60,
                border: "2px solid #F2F2F2",
                borderRadius: "5px",
                overflow: "hidden",
                "& *": {
                  height: "100% !important",
                  aspectRatio: "1 / 1 !important",
                },
              }}
            >
              <Image
                layout="fill"
                objectFit="cover"
                src={imageLink}
                alt={description}
              />
            </Box>
            <Stack spacing={1}>
              {medium && renderDomain()}
              {isExternalResource ? (
                <Typography
                  fontSize={13}
                  fontWeight="bold"
                  color="#151319"
                  fontFamily="Sora"
                  sx={{
                    m: 0,
                    p: 0,
                    "& *": {
                      m: 0,
                      p: 0,
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              ) : (
                <Typography
                  fontSize={13}
                  fontWeight="bold"
                  color="#151319"
                  fontFamily="Sora"
                >
                  {title}
                </Typography>
              )}
              {isExternalResource ? (
                <Typography
                  fontSize={11}
                  color="#646259"
                  fontFamily="Sora"
                  sx={{
                    minWidth: medium ? 100 : undefined,
                    maxWidth: medium ? undefined : 400,
                    display: "-webkit-box",
                    wordWrap: "break-word",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                    m: 0,
                    p: 0,
                    "& *": {
                      m: 0,
                      p: 0,
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              ) : (
                <Typography
                  fontSize={11}
                  color="#646259"
                  fontFamily="Sora"
                  sx={{
                    minWidth: medium ? 100 : undefined,
                    maxWidth: medium ? undefined : 400,
                    display: "-webkit-box",
                    wordWrap: "break-word",
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                  }}
                >
                  {description}
                </Typography>
              )}
              {medium && (
                <Typography fontFamily="Sora" fontSize={12} color="#bab8a9">
                  {resourceTypeLabel}
                </Typography>
              )}
              {!medium && renderDomain()}
            </Stack>
          </Stack>
        </a>
      </Link>
    );
  }
);

MenuLinkCard.displayName = "MenuLinkCard";
