import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRouter } from "next/router";
import axios from "axios";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Inserisci una mail valida")
    .required("Inserisci un'email valida"),
  password: yup.string().required("Inserisci la tua password"),
});
type UserLoginFormData = {
  email: string;
  password: string;
};

export const useUserLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [messageObj, setMessageObj] = useState<
    | {
        type: "ERROR" | "SUCCESS";
        message: string;
      }
    | undefined
  >(undefined);
  const { query } = useRouter();
  const { email, verificationCode } = query;
  const formData = useForm<UserLoginFormData>({
    resolver: yupResolver(schema),
    defaultValues: { email: "", password: "" },
  });
  const { handleSubmit, reset } = formData;
  const { push } = useRouter();

  useEffect(() => {
    const handleVerification = async () => {
      setIsLoading(true);
      reset({
        email: email as string,
        password: "",
      });
      let res;
      try {
        await new Promise((r) => setTimeout(r, 2000));
        res = await axios.patch("/api/users", {
          email,
          verificationCode,
        });
        if (res.status === 200) {
          setMessageObj({
            message: "Operazione completata!",
            type: "SUCCESS",
          });
        }
      } catch (error) {
        console.log(error);
        if (error?.response) {
          const message = error?.response?.data?.message;
          setMessageObj({ message, type: "ERROR" });
        }
      } finally {
        setIsLoading(false);
      }
    };
    if (email && verificationCode) {
      handleVerification();
    }
  }, [email, verificationCode, reset]);

  const handleRequest = useCallback(
    async (data: UserLoginFormData) => {
      try {
        setIsLoggingIn(true);
        const res = await axios.post("/api/userSessions", {
          email: data.email,
          password: data.password,
        });
        if (res.status === 200) {
          setIsLoggingIn(false);
          setMessageObj({
            message: "Login effettuato con successo! Redirect in corso...",
            type: "SUCCESS",
          });
          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
        }
      } catch (error) {
        if (error?.response) {
          const message = error?.response?.data?.message;
          setMessageObj({ message, type: "ERROR" });
        }
        setIsLoggingIn(false);
      }
    },
    [setIsLoggingIn, setMessageObj]
  );

  const onSubmit = useMemo(
    () =>
      handleSubmit((data) => {
        handleRequest(data);
      }),
    [handleSubmit, handleRequest]
  );

  return {
    formData,
    onSubmit,
    isLoading,
    isLoggingIn,
    messageObj,
  };
};
