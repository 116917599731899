import React, { memo } from "react";
import { Box, Paper, Stack } from "@mui/material";
import { CallOutlined } from "@mui/icons-material";
import { NavigationButton } from "./NavigationButton";
import { useNavigationBar } from "./index.hooks";
import { MainMenu } from "./MainMenu";
import { AppContainer } from "components/AppContainer";

type NavigationBarProps = {};

export const NavigationBar = memo(({}: NavigationBarProps) => {
  const {} = useNavigationBar();

  return (
    <Paper
      sx={{
        height: "45px",
        boxShadow: "0px 2px 4px #0000000F",
        opacity: 1,
        borderRadius: 0,
        borderBottom: "1px solid #E9E9E9",
        display: {
          xs: "none",
          md: "block",
        },
      }}
    >
      <AppContainer sx={{ height: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <MainMenu />
          <Box sx={{ height: "100%" }}>
            <NavigationButton
              labelColor="#646259"
              label="SERVIZIO CLIENTI"
              href="/contatti"
              startIcon={
                <CallOutlined
                  sx={{ transform: "rotate(270deg)", color: "#009FDF" }}
                />
              }
            />
          </Box>
        </Stack>
      </AppContainer>
    </Paper>
  );
});

NavigationBar.displayName = "NavigationBar";
