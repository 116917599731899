import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IStoreFe, StoreFe } from "models/client/StoreFe";
import { useStoreContext } from "hooks/useStoreContext";
import axios from "axios";
import { PageDataContext } from "contexts/PageDataContext";

export const useFindYourStore = () => {
  const { onStoreClicked, clickedStore, iStoresMap } = useStoreContext();

  const [isOpenList, setIsOpenList] = useState(true);

  const stores = useMemo(
    () => (iStoresMap ?? []).map((s) => new StoreFe(s)),
    [iStoresMap]
  );

  const openList = useCallback(() => {
    setIsOpenList(true);
  }, [setIsOpenList]);
  const closeList = useCallback(() => {
    setIsOpenList(false);
  }, [setIsOpenList]);

  const markerClickHandler = useCallback(
    (store: IStoreFe) => {
      onStoreClicked(store);
    },
    [onStoreClicked]
  );

  return {
    isOpenList,
    openList,
    closeList,
    clickedStore,
    markerClickHandler,
    stores,
  };
};
