import React, { memo } from "react";
import { useContentColumnsSlider } from "./index.hooks";
import { Box, Container, IconButton, Stack } from "@mui/material";
import { ColumnSliderItem } from "./ColumnSliderItem";
import {
  NavigateBeforeOutlined,
  NavigateNextOutlined,
} from "@mui/icons-material";
import { IMediaFe } from "models/client/MediaFe";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";
import dynamic from "next/dynamic";

const Hammer: any = dynamic(() => import("react-hammerjs"), {
  loading: ({ children }: any) => <>{children}</>,
});

export type ContentColumnsSliderProps = VisualComponentProps<{
  pages: {
    text: string;
    href: string;
    image: IMediaFe;
    title: string;
  }[];
}>;

export const ContentColumnsSlider = memo(
  ({ pages, anchorId }: ContentColumnsSliderProps) => {
    const {
      pageIndex,
      perPage,
      onNextPageClick,
      onPrevPageClick,
      dotClickCallbacks,
      pagesCount,
    } = useContentColumnsSlider(pages);

    const renderControls = () => (
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{ mt: 2, mx: "auto", width: "fit-content" }}
      >
        <IconButton
          onClick={onPrevPageClick}
          disabled={pageIndex === 0}
          sx={{
            border: "1px solid #E5E5E5",
            borderRadius: 2,
          }}
        >
          <NavigateBeforeOutlined />
        </IconButton>
        <Stack direction="row">
          {Array.from({ length: pagesCount }).map((_, index) => {
            const isCurrentPage = index === pageIndex;

            return (
              <Box
                key={index}
                onClick={isCurrentPage ? null : dotClickCallbacks[index]}
                sx={{
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  cursor: isCurrentPage ? "auto" : "pointer",
                  border: "1px solid #AAA79A",
                  backgroundColor: isCurrentPage ? "#AAA79A" : "transparent",
                  margin: "0 2px",
                  transition: "background-color 0.3s",
                }}
              />
            );
          })}
        </Stack>
        <IconButton
          onClick={onNextPageClick}
          disabled={pageIndex === pagesCount - 1}
          sx={{
            border: "1px solid #E5E5E5",
            borderRadius: 2,
          }}
        >
          <NavigateNextOutlined />
        </IconButton>
      </Stack>
    );

    return (
      <Container
        id={anchorId ?? undefined}
        sx={{
          textAlign: "center",
          mt: "68px",
        }}
      >
        <Box sx={{ overflowX: "hidden" }}>
          <Hammer onSwipeLeft={onNextPageClick} onSwipeRight={onPrevPageClick}>
            <Stack
              direction="row"
              flexWrap="nowrap"
              justifyContent={{
                xs: "flex-start",
                md: pages.length > 2 ? "flex-start" : "center",
              }}
            >
              {pages.map((page, index) => (
                <Box
                  key={index}
                  sx={{
                    width: `${100 / (perPage || 1)}%`,
                    flexShrink: 0,
                    transform: `translateX(-${pageIndex * perPage * 100}%)`,
                    transition: "transform 0.3s",
                  }}
                >
                  <ColumnSliderItem
                    text={page.text}
                    href={page.href}
                    iMedia={page.image}
                    title={page.title}
                  />
                </Box>
              ))}
            </Stack>
          </Hammer>
          {pagesCount > 1 && renderControls()}
        </Box>
      </Container>
    );
  }
);

ContentColumnsSlider.displayName = "PagesCarousel";
