// @ts-nocheck
import React, { memo, ReactNode } from "react";
import { useStoreButton } from "./index.hooks";
import { Button, ButtonProps, Stack } from "@mui/material";

type StoreButtonProps = {
  icon: ReactNode;
} & ButtonProps;

export const StoreButton = memo(
  ({ icon, children, sx, ...props }: StoreButtonProps) => {
    const {} = useStoreButton();

    return (
      <Button
        target={"_blank"}
        sx={[
          {
            height: "80px",
            pt: "16px",
            pl: "20px",
            overflow: "hidden",
            background: "white",
            borderRadius: 0,
            borderRight: "1px solid #E1E1E1",
            boxShadow: "0px 2px 4px #0000000f",
            width: {
              xs: "100%",
              lg: "auto",
            },
            flex: 1,
            p: "20px!important",
            textTransform: "none",
            "&:first-of-type": {
              borderBottomLeftRadius: "4px",
              borderTopLeftRadius: "4px",
            },
            "&:last-child": {
              borderBottomRightRadius: "4px",
              borderTopRightRadius: "4px",
            },
            "&:hover": {
              backgroundColor: "white",
              opacity: 0.7,
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...props}
      >
        <Stack
          direction="row"
          width="100%"
          spacing="12px"
          alignItems="flex-start"
        >
          {!!icon && icon}
          {children}
        </Stack>
      </Button>
    );
  }
);
StoreButton.displayName = "StoreButton";
