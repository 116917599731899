import { Grid } from "@mui/material";
import React, { memo } from "react";
import { ContentsGridV2Item } from "./ContentsGridV2Item";
import { useContentsGridV2 } from "./index.hooks";
import { AppContainer } from "components/AppContainer";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";

export type ContentsGridV2Props = VisualComponentProps<{
  contents: {
    title: string;
    article: string;
  }[];
  columns?: number;
}>;

export const ContentsGridV2 = memo(
  ({ contents = [], anchorId, columns }: ContentsGridV2Props) => {
    const {} = useContentsGridV2();

    return (
      <AppContainer id={anchorId ?? undefined} sx={{ mt: "40px" }}>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="stretch"
        >
          {contents.map((content, index) => (
            <ContentsGridV2Item
              key={index}
              title={content.title}
              article={content.article}
              columns={columns ?? 4}
            />
          ))}
        </Grid>
      </AppContainer>
    );
  }
);
ContentsGridV2.displayName = "ContentsGrid";
