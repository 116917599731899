import { Box, Stack, Typography } from "@mui/material";
import React, { memo } from "react";
import { useStoreList } from "./index.hooks";
import { SearchbarStore } from "components/FindYourStore/SearchbarStore";
import { StoreListItem } from "components/FindYourStore/StoreListItem";

type StoreListProps = { inDialog?: boolean; onClose?: (e) => void };

export const StoreList = memo(({ inDialog, onClose }: StoreListProps) => {
  const { iStoresList } = useStoreList();

  return (
    <Stack overflow="auto" sx={{ "::-webkit-scrollbar": { display: "none" } }}>
      {iStoresList?.map((s, index) => (
        <Box
          key={s._id}
          px="2px"
          py={2}
          borderTop={index !== 0 && "1px solid #00000029"}
        >
          <StoreListItem onClose={onClose} inDialog={inDialog} store={s} />
        </Box>
      ))}
    </Stack>
  );
});
StoreList.displayName = "StoreList";
