import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IStoreFe, StoreFe } from "models/client/StoreFe";
import { useStoreContext } from "hooks/useStoreContext";

export const useStoreLocator = () => {
  const {
    center: defaultCenter,
    horizontalWidth: defaultHorizontalWidth,
    selectedStore,
    iStoresMap,
    onStoreClicked,
  } = useStoreContext();

  const [isOpen, setIsOpen] = useState(false);
  const [center, setCenter] = useState(defaultCenter);
  const [horizontalWidth, setHorizontalWidth] = useState(
    defaultHorizontalWidth
  );

  const stores = useMemo(
    () => (iStoresMap ?? []).map((s) => new StoreFe(s)),
    [iStoresMap]
  );

  const storesCountText = useMemo(() => {
    const count = iStoresMap?.length ?? 0;
    const vowel = count === 1 ? "o" : "i";

    return `${count} Punt${vowel} vendita nelle vicinanze`;
  }, [iStoresMap]);

  const markerClickHandler = useCallback(
    (store: IStoreFe) => {
      onStoreClicked(store);
      setIsOpen(true);
    },
    [onStoreClicked]
  );

  const modalCloseHandler = useCallback((e) => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (selectedStore) {
      const [lng, lat] = selectedStore.geo;
      setCenter({ lat, lng: lng - horizontalWidth / 4 });
    }
  }, [selectedStore, horizontalWidth]);

  return {
    stores,
    isOpen,
    markerClickHandler,
    modalCloseHandler,
    storesCountText,
  };
};
