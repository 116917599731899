import React, { memo } from "react";
import { useVideoContent } from "./index.hooks";
import { Box, Container, IconButton, Typography } from "@mui/material";
import Image from "next/image";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import { IMediaFe, MediaFe, MediaImageThumbSizes } from "models/client/MediaFe";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";

export type VideoContentProps = VisualComponentProps<{
  title: string;
  image: IMediaFe;
  url: string;
}>;

export const VideoContent = memo(
  ({ title, image, url, anchorId }: VideoContentProps) => {
    const { showYTPlayer, playIconClickHandler, videoID, isMobile } =
      useVideoContent(url);

    return (
      <Container
        id={anchorId ?? undefined}
        maxWidth="lg"
        sx={{ py: 6, px: { xs: 0, md: 2 } }}
      >
        <Box
          display={isMobile ? "flex" : "none"}
          flexDirection="column"
          textAlign="center"
          alignItems="center"
        >
          <Typography sx={{ font: "bold 19px/36px Sora", color: "#151319" }}>
            {title}
          </Typography>
          <Typography sx={{ font: "normal 15px/10px Karla", color: "#646259" }}>
            Guarda il video
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="relative"
          width={{ xs: "370px", md: "766px", lg: "1090px" }}
          height={{ xs: "212px", md: "431px", lg: "613px" }}
          margin="auto"
          top={{ xs: "26px", md: 0 }}
        >
          <Box position="relative" width="100%" height="100%">
            <Image
              src={new MediaFe(image).getUrlFromKeyAndExtension(
                MediaImageThumbSizes.MEDIUM
              )}
              alt={image.title}
              layout="fill"
              style={{ borderRadius: "6px" }}
            />
          </Box>
          <Box
            display={showYTPlayer ? "none" : "flex"}
            width="100%"
            height="100%"
            position="absolute"
            margin="auto"
            sx={{
              backgroundColor: "rgba(0,159,223,0.75)",
              borderRadius: "6px",
            }}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <IconButton
              sx={{
                height: { xs: "36px", md: "50px", lg: "72px" },
                width: { xs: "36px", md: "50px", lg: "72px" },
                m: 1,
              }}
              onClick={playIconClickHandler}
            >
              <PlayCircleFilledWhiteOutlinedIcon
                sx={{ color: "white", fontSize: "72px" }}
              />
            </IconButton>
            <Box display={isMobile ? "none" : "initial"} textAlign={"center"}>
              <Typography sx={{ font: "bold 24px/36px Sora", color: "#FFF" }}>
                {title}
              </Typography>
              <Typography
                sx={{ font: "normal 19px/24px Karla", color: "#FFF" }}
              >
                Guarda il video
              </Typography>
            </Box>
          </Box>
          <iframe
            style={{
              borderRadius: "6px",
              position: "absolute",
              display: showYTPlayer ? "initial" : "none",
            }}
            width="100%"
            height="100%"
            data-add-placeholder="true"
            data-cookieblock-src={`https://www.youtube.com/embed/${videoID}?autoplay=${
              showYTPlayer ? "1" : "0"
            }&mute=0`}
            data-cookieconsent="statistics, marketing"
            title={title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Box>
      </Container>
    );
  }
);

VideoContent.displayName = "VideoContent";
