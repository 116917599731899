import { useStoreContext } from "hooks/useStoreContext";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export const useGoogleMaps = (
  fullScreen: boolean,
  markers: {
    latLng: google.maps.LatLngLiteral;
    _id: string;
    animation?: google.maps.Animation;
  }[]
) => {
  const {
    center: defaultCenter,
    zoom: defaultZoom,
    horizontalWidth: defaultHorizontalWidth,
    selectedStore,
    searchByLocation,
  } = useStoreContext();

  const horizontalWidth = useRef(defaultHorizontalWidth);

  const [center, setCenter] =
    useState<google.maps.LatLngLiteral>(defaultCenter);
  const [visibleMarkers, setVisibleMarkers] = useState<typeof markers>([]);
  const [bounds, setBounds] = useState<google.maps.LatLngBounds>();
  const [zoom, setZoom] = useState(defaultZoom);

  const animatedId = useMemo(() => selectedStore?._id, [selectedStore]);

  const onIdle = useCallback(
    (m: google.maps.Map) => {
      const newZoom = m.getZoom();
      const newCenter = m.getCenter();
      const newBounds = m.getBounds();
      const { west, east } = newBounds.toJSON();
      const newHorizontalWidth = west - east;

      setBounds(newBounds);

      if (newHorizontalWidth) {
        horizontalWidth.current = newHorizontalWidth;
      }
      if (newZoom) {
        setZoom(newZoom);
      }
      if (newCenter) {
        setCenter(newCenter.toJSON());
      }

      if (newCenter && newZoom) {
        searchByLocation(newCenter.toJSON(), newZoom);
      }
    },
    [setCenter, setZoom, searchByLocation]
  );

  useEffect(() => {
    if (selectedStore) {
      const [lng, lat] = selectedStore.geo;
      const horizontalOffset = fullScreen ? horizontalWidth.current / 4 : 0;

      setCenter({ lat, lng: lng - horizontalOffset });
    }
  }, [selectedStore, fullScreen]);

  useEffect(() => {
    if (bounds) {
      const newMarkers = markers.filter((m) => bounds.contains(m.latLng));

      newMarkers.forEach((m, i) => {
        if (m._id === animatedId) {
          newMarkers[i].animation = google.maps.Animation.DROP;
        }
      });

      setVisibleMarkers(newMarkers);
    }
  }, [markers, bounds, animatedId]);

  useEffect(() => {
    if (defaultCenter) {
      setCenter(defaultCenter);
    }
  }, [defaultCenter]);

  useEffect(() => {
    if (defaultZoom) {
      setZoom(defaultZoom);
    }
  }, [defaultZoom]);

  useEffect(() => {
    if (defaultHorizontalWidth) {
      horizontalWidth.current = defaultHorizontalWidth;
    }
  }, [defaultHorizontalWidth]);

  return {
    center,
    zoom,
    onIdle,
    visibleMarkers,
  };
};
