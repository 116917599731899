import React, { memo, ReactNode } from "react";
import { useFooterSocialLink } from "./index.hooks";
import { IconButton, IconButtonProps } from "@mui/material";

type FooterSocialLinkProps = {
  icon: ReactNode;
  href: string;
} & IconButtonProps;

export const FooterSocialLink = memo(
  ({ icon, href, ...props }: FooterSocialLinkProps) => {
    const {} = useFooterSocialLink();

    return (
      <a href={href} target="_blank" rel="noreferrer">
        <IconButton sx={{ color: "white" }} {...props}>
          {icon}
        </IconButton>
      </a>
    );
  }
);
FooterSocialLink.displayName = "FooterSocialLink";
