import React, { memo } from "react";
import { useStoreLocator } from "./index.hooks";
import { Box, Stack, Typography } from "@mui/material";
import { FindYourStore } from "components/FindYourStore";
import { StoreList } from "./StoreList";
import { SearchbarStore } from "components/FindYourStore/SearchbarStore";
import { PageTitleV4 } from "components/_visual-components/PageTitleV4";
import { GoogleMaps } from "./GoogleMaps";

type StoreLocatorProps = {};

export const StoreLocator = memo(({}: StoreLocatorProps) => {
  const {
    stores,
    isOpen,
    markerClickHandler,
    modalCloseHandler,
    storesCountText,
  } = useStoreLocator();

  return (
    <>
      <PageTitleV4 title="Trova negozio" removeClipPath />
      <Box
        bgcolor="rgba(21, 19, 25, 77%)"
        zIndex={1000}
        display={isOpen ? "flex" : "none"}
        justifyContent="center"
        alignItems="center"
        onClick={modalCloseHandler}
      >
        <Box onClick={(e) => e.stopPropagation()}>
          <FindYourStore name="find" onClose={modalCloseHandler} />
        </Box>
      </Box>
      <Box sx={{ position: "relative" }}>
        <GoogleMaps
          markers={stores}
          onMarkerClick={markerClickHandler}
          fullScreen
        />
        <Stack
          px="28px"
          boxShadow="0px 3px 6px #00000029"
          maxWidth={{ md: 646, xs: "100%" }}
          maxHeight="75vh"
          position={{ xs: "relative", md: "absolute" }}
          bgcolor="#FFF"
          top="0"
          mt={{ xs: "1vh", md: 0 }}
          right={{ xs: 0, md: "17vw" }}
        >
          <Box py="25px">
            <Typography color="#009FDF" variant="h5">
              {storesCountText}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: "#F2F1ED",
              px: "28px",
              py: "36px",
              mx: "-28px",
            }}
          >
            <SearchbarStore />
          </Box>
          <StoreList />
        </Stack>
      </Box>
    </>
  );
});
StoreLocator.displayName = "StoreLocator";
