import React, { memo } from "react";
import { useStoreSmallView } from "./index.hooks";
import { Box, Grid, Stack, Typography, Modal } from "@mui/material";
import { styled } from "@mui/material/styles";
import { StoreMediumCard } from "components/StoreMediumCard";
import { StoreSmallCard } from "components/StoreSmallCard";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import ShoppingCart from "@mui/icons-material/ShoppingCartOutlined";
import Phone from "@mui/icons-material/PhoneEnabledOutlined";
import Pointer from "@mui/icons-material/NearMeOutlined";
import { AppContainer } from "components/AppContainer";
import { FindYourStore } from "components/FindYourStore";
import { IStoreFe } from "models/client/StoreFe";
import { StoreButton } from "components/StoreButton";

type StoreSmallViewProps = { store: IStoreFe };

const BoldTypography = styled(Typography)`
  font-family: Sora;
  font-weight: bold;
`;

export const StoreSmallView = memo(({ store }: StoreSmallViewProps) => {
  const {
    isOpen,
    onToggle,
    isOpenStore,
    name,
    city,
    address,
    phone,
    slug,
    hrefMaps,
    closureTime,
    flyers,
    storeArticles,
  } = useStoreSmallView();

  return (
    <Box
      sx={{
        background: {
          xs: "linear-gradient(#F2F1ED 82%, #FFFFFF 82% )",
          md: "linear-gradient(#F2F1ED 76%, #FFFFFF 76% )",
          lg: "linear-gradient(#F2F1ED 98.5%, #FFFFFF 98.5% )",
        },
        mt: "68px",
      }}
      height="fit-content"
    >
      <AppContainer sx={{ pt: "57px" }}>
        <Stack justifyContent="center" alignItems="center" spacing={8}>
          <Box textAlign="center">
            <BoldTypography variant="h3">Il tuo negozio</BoldTypography>
            <Typography fontSize={17} color="#646259" fontFamily="Sora">
              Scopri promozioni, offerte ed opportunità dedicate a te!
            </Typography>
          </Box>
          <Grid container>
            <Grid
              item
              xs={12}
              lg={5.5}
              display={{ xs: "flex", lg: "block" }}
              flexDirection="column"
              alignItems="center"
              pb={{ xs: 4, lg: 0 }}
            >
              <Typography variant="h4">In Evidenza</Typography>
              <Box
                component="div"
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                maxWidth="fit-content"
              >
                {(storeArticles ?? []).length === 0 && (
                  <Typography color="black" fontSize={16}>
                    Nessun articolo in evidenza
                  </Typography>
                )}
                {storeArticles.slice(0, 2).map((article) => (
                  <StoreMediumCard
                    key={article._id}
                    article={article}
                    store={article.storesIds?.length === 1 ? store : undefined}
                  />
                ))}
              </Box>
              {/*

              <Box>
                <BoldTypography
                  display="inline"
                  fontSize={{ xs: 12, sm: 14 }}
                  color="#646259"
                >
                  Vedi tutte le promozioni attive vicino a te
                </BoldTypography>
                <IconButton>
                  <ArrowCircleDownIcon
                    sx={{ color: "#646259", transform: "rotate(270deg)" }}
                  />
                </IconButton>
              </Box>
                 */}
            </Grid>
            <Grid item xs={0} lg={1} />
            <Grid
              item
              xs={12}
              lg={5.5}
              display={{ xs: "flex", lg: "block" }}
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="h4">Volantino offerte</Typography>
              <Box
                component="div"
                display="flex"
                flexDirection="column"
                maxWidth="100%"
                py={1}
              >
                {!flyers?.length ? (
                  <Typography variant="h6" color="#646259">
                    Non ci sono volantini disponibili
                  </Typography>
                ) : (
                  flyers
                    .slice(0, 2)
                    .map((flyer) => (
                      <StoreSmallCard
                        key={flyer.url}
                        href={flyer.url}
                        src={flyer.cover}
                        from={flyer.startDate}
                        to={flyer.endDate}
                        title="Volantino promozioni ed offerte"
                        subTitle={flyer.description}
                        buttonText="Sfoglia il volantino"
                      />
                    ))
                )}
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </AppContainer>
      <AppContainer fullScreen sx={{ mt: "140px" }}>
        <Stack width="100%" direction={{ xs: "column", lg: "row" }}>
          <StoreButton
            icon={<StorefrontOutlinedIcon sx={{ color: "white" }} />}
            onClick={onToggle}
            sx={{
              background: "red",
              "&:hover": {
                backgroundColor: "red",
              },
            }}
          >
            <Box overflow="hidden">
              <Typography
                color="white"
                fontFamily="Sora"
                fontSize={17}
                sx={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textAlign: "left",
                }}
              >
                {name}
              </Typography>
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontSize: 11,
                  fontFamily: "Sora",
                  textAlign: "left",
                }}
              >
                Cambia Negozio
              </Typography>
            </Box>
          </StoreButton>
          <StoreButton
            disabled
            sx={{
              background: "#009FDF",
              "&:hover": {
                backgroundColor: "#009FDF",
              },
            }}
            icon={
              <Stack
                minWidth={18}
                minHeight={18}
                alignItems="center"
                justifyContent="center"
                bgcolor="white"
                borderRadius="50%"
              >
                <Box
                  minWidth={14}
                  minHeight={14}
                  bgcolor={isOpenStore ? "#34D539" : "#FF3D00"}
                  borderRadius="50%"
                />
              </Stack>
            }
          >
            <Stack alignItems="flex-start">
              <BoldTypography color="white" fontSize={17}>
                {"Ora " + (isOpenStore ? "aperto" : "chiuso")}
              </BoldTypography>
              <Typography color="white" fontFamily="Karla" fontSize={12}>
                {closureTime}
              </Typography>
            </Stack>
          </StoreButton>
          <StoreButton
            href={`/punti-vendita/${slug}`}
            icon={<ShoppingCart sx={{ color: "#646259" }} />}
          >
            <Box>
              <BoldTypography color="#646259" fontSize={15}>
                SCHEDA NEGOZIO
              </BoldTypography>
              <Typography color="#646259" fontFamily="Karla" fontSize={12}>
                Vedi dettagli del punto vendita
              </Typography>
            </Box>
          </StoreButton>

          <StoreButton
            icon={<Pointer sx={{ color: "#646259" }} />}
            href={hrefMaps}
          >
            <Box>
              <BoldTypography color="#646259" fontSize={15}>
                COME RAGGIUNGERE
              </BoldTypography>
              <Typography color="#646259" fontFamily="Karla" fontSize={12}>
                {address}, {city}
              </Typography>
            </Box>
          </StoreButton>

          <StoreButton
            href={`tel:${phone}`}
            icon={<Phone sx={{ color: "#646259" }} />}
            sx={{
              pr: 4,
            }}
          >
            <Box mr={4}>
              <BoldTypography color="#646259" fontSize={15}>
                CHIAMA IL NEGOZIO
              </BoldTypography>
              <Typography color="#646259" fontFamily="Karla" fontSize={12}>
                Chiama il <span style={{ fontWeight: "bold" }}> {phone}</span>
              </Typography>
            </Box>
          </StoreButton>
        </Stack>
      </AppContainer>
      {isOpen && (
        <Modal
          sx={{ display: "flex", alignItems: "center" }}
          open={isOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <FindYourStore name="find" onClose={onToggle} />
        </Modal>
      )}
    </Box>
  );
});
StoreSmallView.displayName = "StoreSmallView";
