import React, { memo } from "react";
import { useCtaButton } from "./index.hooks";
import { Button, ButtonProps, CircularProgress } from "@mui/material";
import Link from "next/link";
import { ArrowCircleRightOutlined } from "@mui/icons-material";

export type CtaButtonProps = {
  text: string;
  href?: string;
  target?: string;
  type?: string;
  isLoading?: boolean;
} & ButtonProps;

export const CtaButton = memo(
  ({ text, href, target, type, isLoading, sx, ...props }: CtaButtonProps) => {
    const {} = useCtaButton();

    const renderButton = () => {
      return (
        <Button
          color="primary"
          variant="contained"
          type={type}
          sx={{
            border: "1px solid #FFF",
            fontFamily: "Sora",
            fontWeight: "bold",
            fontSize: {
              xs: "11px!important",
              md: "15px!important",
            },
            padding: "0.8rem !important",
            backgroundColor: "#F00",
            textTransform: "none",
            height: {
              xs: "37px",
              md: "50px",
            },
            px: {
              xs: "15px!important",
              md: "20px!important",
            },
            mb: "2px",
            "& .MuiButton-endIcon svg": {
              fontSize: {
                xs: "18px!important",
                md: "22px!important",
              },
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          }}
          endIcon={
            isLoading ? (
              <CircularProgress
                sx={{
                  color: "#FFF",
                  fill: "#FFF",
                }}
                size={20}
              />
            ) : (
              <ArrowCircleRightOutlined />
            )
          }
          {...props}
        >
          {text}
        </Button>
      );
    };

    if (href) {
      if (target === "_blank") {
        return (
          <a href={href} target="_blank" rel="noreferrer">
            {renderButton()}
          </a>
        );
      }

      return (
        <Link href={href} passHref>
          {renderButton()}
        </Link>
      );
    }

    return renderButton();
  }
);
CtaButton.displayName = "CtaButton";
