import React, { memo } from "react";
import { useSmallCardItem } from "./index.hooks";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ArrowCircleRightOutlined } from "@mui/icons-material";
import { IMediaFe, MediaImageThumbSizes } from "models/client/MediaFe";
import Image from "next/image";
import Link from "next/link";

type SmallCardItemProps = {
  title: string;
  subtitle: string;
  href: string;
  imgSrc: IMediaFe;
};

export const SmallCardItem = memo(
  ({ title, subtitle, href, imgSrc }: SmallCardItemProps) => {
    const { media } = useSmallCardItem(imgSrc);

    return (
      <Grid item key={title} xs={12} sm={6} md={3} alignContent="center">
        <Card
          sx={{
            height: "120px",
            width: "100%",
            boxShadow: "none",
            border: "1px solid #E1E1E1",
          }}
        >
          <Stack direction="row" sx={{ height: "100%", position: "relative" }}>
            <Link href={href} passHref>
              <Box
                sx={{
                  height: "100%",
                  aspectRatio: "90 / 110",
                  boxFit: "cover",
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                <Image
                  src={media?.getUrlFromKeyAndExtension(
                    MediaImageThumbSizes.SMALL
                  )}
                  layout={"fill"}
                  objectFit={"cover"}
                  alt={media?.title}
                />
              </Box>
            </Link>

            <CardContent sx={{ paddingBottom: "10px" }}>
              <Stack
                sx={{
                  height: "100%",
                  width: "100%",
                }}
                direction="column"
                justifyContent="space-between"
              >
                <Box
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "4",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#212121",
                      lineHeight: 1.1,
                      mb: "2px",
                    }}
                    component="div"
                  >
                    {title}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "12px" }}
                    color="text.secondary"
                    component="div"
                  >
                    {subtitle}
                  </Typography>
                </Box>
              </Stack>
            </CardContent>

            <Box sx={{ position: "absolute", bottom: 0, right: 0 }}>
              <IconButton href={href}>
                {
                  <ArrowCircleRightOutlined
                    style={{
                      color: "#d0d0d0",
                    }}
                  />
                }
              </IconButton>
            </Box>
          </Stack>
        </Card>
      </Grid>
    );
  }
);
SmallCardItem.displayName = "SmallCardItem";
