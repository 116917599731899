import React, { memo } from "react";
import { useSingleImage } from "./index.hooks";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";
import { Breakpoint, Link } from "@mui/material";
import { IMediaFe, MediaFe, MediaImageThumbSizes } from "models/client/MediaFe";
import { AppContainer } from "components/AppContainer";

export type SingleImageProps = VisualComponentProps<{
  image: IMediaFe;
  maxWidth: Breakpoint | null;
  link?: string;
  newPageFlag?: boolean;
}>;

export const SingleImage = memo(
  ({
    image,
    maxWidth = "md",
    anchorId,
    link,
    newPageFlag,
  }: SingleImageProps) => {
    const {} = useSingleImage();

    const renderContent = () => {
      return (
        <AppContainer
          id={anchorId ?? undefined}
          maxWidth={maxWidth}
          sx={{
            mt: 6,
          }}
        >
          {link !== "" ? (
            <Link href={link} target={newPageFlag ? "_blank" : "_self"}>
              <img
                src={new MediaFe(image).getUrlFromKeyAndExtension(
                  MediaImageThumbSizes.MEDIUM
                )}
                alt={image.title}
                width="100%"
                height="auto"
              />
            </Link>
          ) : (
            <img
              src={new MediaFe(image).getUrlFromKeyAndExtension(
                MediaImageThumbSizes.MEDIUM
              )}
              alt={image.title}
              width="100%"
              height="auto"
            />
          )}
        </AppContainer>
      );
    };
    if (maxWidth !== null) {
      return renderContent();
    }
  }
);
SingleImage.displayName = "SingleImage";
