import { AddCircleOutlineRounded } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo } from "react";
import { useStoreListItem } from "components/FindYourStore/StoreListItem/index.hooks";
import Link from "next/link";
import { IStoreFe } from "models/client/StoreFe";
import { faHandPointer } from "@fortawesome/pro-regular-svg-icons";

type StoreListItemProps = {
  store: IStoreFe;
  inDialog?: boolean;
  hideSelectedTag?: boolean;
  onClose?: (e) => void;
};

export const StoreListItem = memo(
  ({ store, inDialog, hideSelectedTag, onClose }: StoreListItemProps) => {
    const { onStoreClicked, selected, storeFe } = useStoreListItem(store);

    return (
      <Stack
        direction="column"
        sx={{
          position: "relative",
          border: selected && !inDialog ? "2px solid #FF0000" : "none",
          borderRadius: selected ? "4px" : "none",
          p: 0,
          m: 0,
          pt: selected && !hideSelectedTag ? 5 : 0,
        }}
      >
        {selected && !hideSelectedTag && (
          <Box
            sx={(theme) => ({
              width: "fit-content",
              backgroundColor: theme.palette.primary.main,
              position: "absolute",
              top: -2,
              left: inDialog ? 18 : -2,
              py: "1px",
              px: "10px",
              color: "#ffffff",
              borderRadius: inDialog ? "4px" : "4px 0",
              marginBottom: "10px",
            })}
          >
            <Typography sx={{ fontVariant: "small-caps", fontWeight: 400 }}>
              SELEZIONATO
            </Typography>
          </Box>
        )}

        <Box
          flexDirection="row"
          display="flex"
          alignItems="center"
          px="20px"
          pb={selected && "10px"}
        >
          <Box
            sx={{
              flexGrow: 1,
              width: "500px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "800",
                fontSize: "16px",
                marginBottom: "5px",
                color: "#222026",
              }}
            >
              {storeFe.name}
            </Typography>
            <Typography
              variant="h6"
              sx={{ color: "#7e7c74", fontSize: "16px" }}
            >
              {storeFe.address}
            </Typography>
            <Typography
              variant="h6"
              display="inline"
              sx={{
                fontWeight: "bold",
                color: "#7e7c74",
                fontSize: "16px",
              }}
            >
              {storeFe.isOpenNow
                ? "Aperto ora - "
                : `Chiuso ora${storeFe.openingTimeToday ? " - " : ""}`}
            </Typography>
            <Typography
              variant="h6"
              display="inline"
              sx={{ color: "#7e7c74", fontSize: "16px" }}
            >
              {storeFe.isOpenNow
                ? storeFe.closingTimeToday
                : storeFe.openingTimeToday}
            </Typography>
          </Box>
          <Stack direction="row" spacing={1} alignItems="stretch">
            <Box
              sx={{
                display: "flex",
                border: "2px solid #aaa79a",
                borderRadius: "4px",
                justifyContent: "center",
                alignItems: "center",
                height: "44px",
                aspectRatio: "1/1",
              }}
            >
              <Link passHref href={`/punti-vendita/${store.slug}`}>
                <IconButton
                  onClick={onClose}
                  sx={{
                    height: "100%",
                    width: "100%",
                    color: "#aaa79a",
                    "&:hover": {
                      background: "none",
                    },
                  }}
                >
                  <AddCircleOutlineRounded sx={{ fontSize: 22 }} />
                </IconButton>
              </Link>
            </Box>
            <Box
              sx={(theme) => ({
                display: selected ? "none" : "flex",
                border: `2px solid ${theme.palette.primary.main}`,
                borderRadius: "4px",
                backgroundColor: theme.palette.primary.main,
                justifyContent: "center",
                alignItems: "center",
                height: "44px",
                aspectRatio: "1/1",
              })}
            >
              <IconButton
                onClick={onStoreClicked}
                sx={{
                  height: "100%",
                  width: "100%",
                  color: "#ffffff",
                  "&:hover": {
                    background: "none",
                  },
                }}
              >
                <FontAwesomeIcon icon={faHandPointer} fontSize={18} />
              </IconButton>
            </Box>
          </Stack>
        </Box>
      </Stack>
    );
  }
);

StoreListItem.displayName = "BoxItem";
