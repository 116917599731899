import { useEffect, useState } from "react";

export const useMarker = (props) => {
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(props);
      if (props.animate && !flag) {
        marker.setAnimation(props.animate);
        setFlag(true);
      }

      window.google.maps.event.clearListeners(marker, "click");
      if (props?.onClick) {
        marker.addListener("click", (e) => {
          props?.onClick?.();
        });
      }
    }

    return () => {
      if (marker) {
        window.google.maps.event.clearListeners(marker, "click");
      }
    };
  }, [marker, flag, props]);

  return {};
};
