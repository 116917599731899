import { IArticleFe } from "models/client/ArticleFe";
import { MediaFe } from "models/client/MediaFe";
import { useMemo } from "react";

export const useStoreMediumCard = (article: IArticleFe) => {
  const mediaUrl = useMemo(
    () => new MediaFe(article?.bannerImage)?.getUrlFromKeyAndExtension(),
    [article]
  );
  return { mediaUrl };
};
