import {
  Grid,
  Stack,
  Typography,
  Paper,
  Box,
  AlertTitle,
  Alert,
  CircularProgress,
} from "@mui/material";
import React, { memo } from "react";
import { FormTextField } from "components/_form/FormTextField";
import { AppContainer } from "components/AppContainer";
import { FormCheckbox } from "components/_form/FormCheckbox";
import { useUserLogin } from "./index.hooks";
import { FormProvider } from "react-hook-form";
import { FormPassword } from "components/_form/FormPassword";
import { CtaButton } from "components/CtaButton";
import Link from "next/link";
import LinkMui from "@mui/material/Link";

type UserLoginProps = {
  loadingMessage?: string;
};

export const UserLogin = memo(
  ({ loadingMessage = "Conferma dell'account in corso" }: UserLoginProps) => {
    const { formData, onSubmit, isLoading, messageObj, isLoggingIn } =
      useUserLogin();

    return (
      <FormProvider {...formData}>
        <form onSubmit={onSubmit}>
          <AppContainer sx={{ mt: "40px" }}>
            <Grid
              container
              spacing={2}
              sx={{ flexDirection: { xs: "column", md: "row" } }}
            >
              <Grid item xs={12}>
                <Alert severity="info">
                  Se disponi già di un account sul sito{" "}
                  <LinkMui href="https://www.coopshop.it/" target="_blank">
                    Coopshop.it
                  </LinkMui>
                  o sulla{" "}
                  <LinkMui
                    href="https://www.novacoop.it/essere-soci/app-coop"
                    target="_blank"
                  >
                    App Coop nazionale
                  </LinkMui>
                  , utilizza le stesse credenziali per accedere a Novacoop.it!
                </Alert>
                <Alert severity="warning" sx={{ mt: 2 }}>
                  Attenzione: Se modifichi la password su Novacoop.it dovrai
                  utilizzare la stessa password per accedere agli altri siti e
                  viceversa.
                </Alert>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper
                  sx={{ backgroundColor: "#009FDF", px: "36px", py: "42px" }}
                >
                  <Stack spacing={3}>
                    <Typography
                      sx={{
                        fontFamily: "Sora",
                        fontWeight: "bold",
                        color: "white",
                        fontSize: "32px",
                      }}
                    >
                      Sei già socio?
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Karla",
                        color: "white",
                        fontSize: "23px",
                      }}
                    >
                      Approfitta di tutti i vantaggi <strong>Socio Coop</strong>{" "}
                      facendo login e selezionando il tuo store di riferimento
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Karla",
                        color: "white",
                        fontSize: "23px",
                      }}
                    >
                      Oppure..
                    </Typography>
                    <Box>
                      <CtaButton text="Registrati" href="/registrazione-soci" />
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
              <Grid item xs={12} md={8}>
                <Paper
                  sx={{
                    py: "36px",
                    px: "42px",
                    border: "1px solid #E1E1E1",
                    boxShadow: "0px 2px 4px #0000000F",
                  }}
                >
                  <Stack spacing={2}>
                    <Typography
                      sx={{
                        fontFamily: "Karla",
                        fontSize: "21px",
                        color: "#AAA79A",
                      }}
                    >
                      Inserisci i tuoi dati per accedere...
                    </Typography>
                    <Stack spacing="20px">
                      {(isLoading || messageObj || isLoggingIn) && (
                        <Alert
                          severity={
                            isLoading || isLoggingIn
                              ? "info"
                              : messageObj.type === "SUCCESS"
                              ? "success"
                              : "error"
                          }
                          sx={{
                            pt: 2,
                            mb: 2,
                          }}
                        >
                          <Stack spacing={3} direction={"row"}>
                            <AlertTitle>
                              {isLoading
                                ? loadingMessage
                                : isLoggingIn
                                ? "Accesso in corso..."
                                : messageObj.message}
                            </AlertTitle>
                            {isLoading && (
                              <CircularProgress
                                sx={{
                                  color: "#009FDF",
                                  fill: "#FFF",
                                }}
                                size={20}
                              />
                            )}
                          </Stack>
                        </Alert>
                      )}
                      <FormTextField
                        color="secondary"
                        name={"email"}
                        label={"Indirizzo Email"}
                        disabled={isLoading}
                        sx={{ mt: 2 }}
                      />
                      <FormPassword
                        color="secondary"
                        name={"password"}
                        label={"Password"}
                        disabled={isLoading}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        mt: 1,
                      }}
                    >
                      <Link href={"/recupero-password"} passHref>
                        <LinkMui color="secondary">
                          Clicca qui per recuperare la password
                        </LinkMui>
                      </Link>
                      <Box>
                        <CtaButton
                          type="submit"
                          text="Login"
                          disabled={isLoading}
                        />
                      </Box>
                    </Stack>
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          </AppContainer>
        </form>
      </FormProvider>
    );
  }
);
UserLogin.displayName = "UserLogin";
