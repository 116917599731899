import { useContext, useMemo } from "react";
import { IMediaFe, MediaFe } from "models/client/MediaFe";
import { PageDataContext } from "contexts/PageDataContext";

export const usePageTitleV2 = (imageData: IMediaFe) => {
  const { breadcrumbs } = useContext(PageDataContext);
  const media = useMemo(() => new MediaFe(imageData), [imageData]);

  return { media, breadcrumbs };
};
