import React, { Fragment, memo } from "react";
import { useTextAndImage } from "./index.hooks";
import { Box, Container, Divider, Grid } from "@mui/material";
import { IMediaFe, MediaFe, MediaImageThumbSizes } from "models/client/MediaFe";
import { RichText } from "components/_visual-components/RichText";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";

export type TextAndImageProps = VisualComponentProps<{
  contents?: {
    text: string;
    image: IMediaFe;
    imagePosition: "left" | "right";
  }[];
}>;

export const TextAndImage = memo(
  ({ contents, anchorId }: TextAndImageProps) => {
    const {} = useTextAndImage();

    return (
      <Container maxWidth="xl" id={anchorId ?? undefined}>
        <Grid container>
          {contents.map(({ text, image, imagePosition }, index) => (
            <Fragment key={text + image.key + imagePosition + index}>
              <Grid
                item
                container
                display="flex"
                direction={imagePosition === "left" ? "row-reverse" : "row"}
                justifyContent="center"
                py={{ xs: 4, md: 6, lg: 10 }}
              >
                <Grid
                  item
                  xs={12}
                  md={7}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box maxWidth={{ xs: 302, md: 254, lg: 550 }}>
                    <RichText htmlData={text} maxWidth={null} />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    component="img"
                    src={new MediaFe(image).getUrlFromKeyAndExtension(
                      MediaImageThumbSizes.MEDIUM
                    )}
                    alt={image.title}
                    height="auto"
                    width="100%"
                    borderRadius="6px"
                  />
                </Grid>
              </Grid>
              <Divider
                flexItem
                style={{
                  width: "100%",
                  display: index === contents.length - 1 ? "none" : "initial",
                }}
              />
            </Fragment>
          ))}
        </Grid>
      </Container>
    );
  }
);
TextAndImage.displayName = "TextAndImage";
