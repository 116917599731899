import React, { memo } from "react";
import { useSmallCards } from "./index.hooks";
import { Grid } from "@mui/material";
import { SmallCardItem } from "./SmallCardItem";
import { IMediaFe } from "models/client/MediaFe";
import { AppContainer } from "components/AppContainer";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";

export type SmallCardsProps = VisualComponentProps<{
  cards: {
    title: string;
    subtitle: string;
    href: string;
    imgSrc: IMediaFe;
  }[];
}>;

export const SmallCards = memo(({ cards = [], anchorId }: SmallCardsProps) => {
  const {} = useSmallCards();

  return (
    <AppContainer
      id={anchorId ?? undefined}
      sx={{
        mt: "51px",
      }}
    >
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {cards.map((card) => (
          <SmallCardItem
            key={card.title}
            title={card.title}
            subtitle={card.subtitle}
            href={card.href}
            imgSrc={card.imgSrc}
          />
        ))}
      </Grid>
    </AppContainer>
  );
});
SmallCards.displayName = "SmallCards";
