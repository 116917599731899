import { Modify } from "../../common/JsUtility";
import { useCallback } from "react";
import moment from "moment";

export namespace StoreFeTypes {
  export type Coordinates = [number, number];

  export type Spid = {
    active: boolean;
    idService: number;
    startDay: string;
    endDay: string;
    openTime: string;
    closeTime: string;
    idConfiguration: string;
    lra: string;
    description: string;
  };

  export type Schedules = {
    date?: Date;
    value?: string;
    status: boolean;
    dayOfTheWeek: number;
  };

  export type Flyers = {
    url: string;
    cover: string;
    description: string;
    startDate: string;
    endDate: string;
  };

  export type Attributes = {
    type: {
      id: number;
      description: string;
    };
    services: {
      id: number;
      description: string;
    }[];
  };
}

export const DAY_OF_WEEK = {
  1: "Lunedì",
  2: "Martedì",
  3: "Mercoledì",
  4: "Giovedì",
  5: "Venerdì",
  6: "Sabato",
  7: "Domenica",
};

export const MONTH_OF_YEAR = {
  1: "Gennaio",
  2: "Febbraio",
  3: "Marzo",
  4: "Aprile",
  5: "Maggio",
  6: "Giugno",
  7: "Luglio",
  8: "Agosto",
  9: "Settembre",
  10: "Ottobre",
  11: "Novembre",
  12: "Dicembre",
};

export type IStoreFe = {
  _id?: string;
  storeId: number;
  coopId: number;
  slug: string;
  name: string;
  cooperativa: string;
  logo: string;
  storeImage: string;
  address: string;
  city: string;
  province: string;
  region: string;
  cap: string;
  phone: string;
  linkPrenotazioneLibri: string;
  geo: StoreFeTypes.Coordinates;
  flagType: { id: number; value: string };
  spid: StoreFeTypes.Spid;
  extraordinarySchedules: StoreFeTypes.Schedules[];
  schedules: StoreFeTypes.Schedules[];
  flyers: StoreFeTypes.Flyers[];
  attributes: StoreFeTypes.Attributes[];
  created: string;
};

export class StoreFe
  implements
    Modify<
      IStoreFe,
      {
        created: Date;
      }
    >
{
  _id: string;
  storeId: number;
  coopId: number;
  slug: string;
  name: string;
  cooperativa: string;
  logo: string;
  storeImage: string;
  address: string;
  city: string;
  province: string;
  region: string;
  cap: string;
  phone: string;
  linkPrenotazioneLibri: string;
  geo: StoreFeTypes.Coordinates;
  flagType: { id: number; value: string };
  spid: StoreFeTypes.Spid;
  extraordinarySchedules: StoreFeTypes.Schedules[];
  schedules: StoreFeTypes.Schedules[];
  flyers: StoreFeTypes.Flyers[];
  attributes: StoreFeTypes.Attributes[];
  created: Date;

  constructor(iStore: IStoreFe) {
    Object.assign(this, iStore);
    this.created = new Date(iStore.created);
  }

  get isOpenNow(): boolean {
    const now = new Date();
    const day = now.getDay();
    const nowTime = now.getHours() * 100 + now.getMinutes();

    const extraordinaryClosingSchedule = this.extraordinarySchedules.find(
      (s) => !s.status && moment().isSame(moment(s.date), "day")
    );
    if (extraordinaryClosingSchedule) {
      const openingTimes = extraordinaryClosingSchedule.value.split(";");
      for (const openingTime of openingTimes) {
        if (openingTime) {
          const [openTime, closeTime] = openingTime.split("-");
          const [hourOpen, minuteOpen] = openTime.split(":");
          const [hourClose, minuteClose] = closeTime.split(":");
          const open = parseInt(hourOpen) * 100 + parseInt(minuteOpen);
          const close = parseInt(hourClose) * 100 + parseInt(minuteClose);
          if (nowTime >= open && nowTime <= close) {
            return false;
          }
        }
      }
    }
    const schedule = this.schedules.find((s) => s.dayOfTheWeek === day);
    if (schedule) {
      const openingTimes = schedule.value.split(";");
      for (const openingTime of openingTimes) {
        const [openTime, closeTime] = openingTime.split("-");
        const [hourOpen, minuteOpen] = openTime.split(":");
        const [hourClose, minuteClose] = closeTime.split(":");
        const open = parseInt(hourOpen) * 100 + parseInt(minuteOpen);
        const close = parseInt(hourClose) * 100 + parseInt(minuteClose);
        if (nowTime >= open && nowTime <= close) {
          return true;
        }
      }
    }
    const extraordinarySchedule = this.extraordinarySchedules.find(
      (s) => s.status && moment().isSame(moment(s.date), "day")
    );

    if (extraordinarySchedule) {
      const openingTimes = extraordinarySchedule.value.split(";");
      for (const openingTime of openingTimes) {
        const [openTime, closeTime] = openingTime.split("-");
        const [hourOpen, minuteOpen] = openTime.split(":");
        const [hourClose, minuteClose] = closeTime.split(":");
        const open = parseInt(hourOpen) * 100 + parseInt(minuteOpen);
        const close = parseInt(hourClose) * 100 + parseInt(minuteClose);
        if (nowTime >= open && nowTime <= close) {
          return true;
        }
      }
    }
    return false;
  }

  get closingTimeToday(): string {
    const now = new Date();
    const day = now.getDay();
    const nowTime = now.getHours() * 100 + now.getMinutes();
    const schedule = this.schedules.find((s) => s.dayOfTheWeek === day);
    if (schedule) {
      const openingTimes = schedule.value.split(";");
      for (const openingTime of openingTimes) {
        const [openTime, closeTime] = openingTime.split("-");
        const [hourOpen, minuteOpen] = openTime.split(":");
        const [hourClose, minuteClose] = closeTime.split(":");
        const open = parseInt(hourOpen) * 100 + parseInt(minuteOpen);
        const close = parseInt(hourClose) * 100 + parseInt(minuteClose);

        if (nowTime >= open && nowTime <= close) {
          return `Chiude alle ${closeTime}`;
        }
      }
    }
    const extraordinarySchedule = this.extraordinarySchedules.find(
      (s) => s.status && moment().isSame(moment(s.date), "day")
    );
    if (extraordinarySchedule) {
      const openingTimes = extraordinarySchedule.value.split(";");
      for (const openingTime of openingTimes) {
        const [openTime, closeTime] = openingTime.split("-");
        const [hourOpen, minuteOpen] = openTime.split(":");
        const [hourClose, minuteClose] = closeTime.split(":");
        const open = parseInt(hourOpen) * 100 + parseInt(minuteOpen);
        const close = parseInt(hourClose) * 100 + parseInt(minuteClose);

        if (nowTime >= open && nowTime <= close) {
          return `Chiude alle ${closeTime}`;
        }
      }
    }
    return "";
  }

  get openingTimeToday(): string {
    const now = new Date();
    const day = now.getDay();
    const nowTime = now.getHours() * 100 + now.getMinutes();
    const schedule = this.schedules.find((s) => s.dayOfTheWeek === day);
    if (schedule) {
      const openingTimes = schedule.value.split(";");
      for (const openingTime of openingTimes) {
        const [openTime] = openingTime.split("-");
        const [hourOpen, minuteOpen] = openTime.split(":");
        const open = parseInt(hourOpen) * 100 + parseInt(minuteOpen);

        if (nowTime <= open) {
          return `Aperto dalle ${openTime}`;
        }
      }
    }
    const extraordinarySchedule = this.extraordinarySchedules.find(
      (s) => s.status && moment().isSame(moment(s.date), "day")
    );
    if (extraordinarySchedule) {
      const openingTimes = extraordinarySchedule.value.split(";");
      for (const openingTime of openingTimes) {
        const [openTime] = openingTime.split("-");
        const [hourOpen, minuteOpen] = openTime.split(":");
        const open = parseInt(hourOpen) * 100 + parseInt(minuteOpen);

        if (nowTime <= open) {
          return `Aperto dalle ${openTime}`;
        }
      }
    }
    return "";
  }

  get latLng(): google.maps.LatLngLiteral {
    return {
      lat: this.geo[1],
      lng: this.geo[0],
    };
  }
}
