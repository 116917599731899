import React, { memo } from "react";
import { useContentsGridV1List } from "./index.hooks";
import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { IMediaFe, MediaFe, MediaImageThumbSizes } from "models/client/MediaFe";
import Image from "next/image";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";

export type ContentsGridV1Props = VisualComponentProps<{
  slides: { title: string; subtitle: string; image: IMediaFe }[];
  columns?: number;
}>;

export const ContentsGridV1 = memo(
  ({ slides, anchorId, columns }: ContentsGridV1Props) => {
    const {} = useContentsGridV1List();

    const gridSize = 12 / (columns ?? 4);
    return (
      <Container id={anchorId ?? undefined} maxWidth="xl" sx={{ py: 2 }}>
        <Grid container width="100%" justifyContent="center">
          {slides.map((slide) => (
            <Grid
              item
              xs={Math.max(gridSize, 12)}
              md={Math.max(gridSize, 4)}
              py={3}
              key={slide.title}
            >
              <Stack alignItems="center">
                <Box width={{ xs: 302, md: 222, lg: 365 }}>
                  <Box
                    component="div"
                    height={{ xs: 170, md: 125, lg: 205 }}
                    width="100%"
                    borderRadius="6px"
                    sx={{
                      position: "relative",
                      objectFit: "cover",
                    }}
                  >
                    <Image
                      alt={slide.image.title}
                      src={new MediaFe(slide.image).getUrlFromKeyAndExtension(
                        MediaImageThumbSizes.MEDIUM
                      )}
                      layout={"fill"}
                      objectFit={"cover"}
                    />
                  </Box>
                  <Typography
                    fontFamily="Sora"
                    fontWeight="bold"
                    fontSize={{ xs: 17, md: 15, lg: 17 }}
                    color="#151319"
                    textAlign="center"
                    py={{ xs: 1, md: 2 }}
                  >
                    {slide.title}
                  </Typography>
                  <Typography
                    fontFamily="Karla"
                    fontSize={15}
                    color="#646259"
                    textAlign="center"
                    lineHeight={{ xs: "22px", md: "24px", lg: "26px" }}
                  >
                    {slide.subtitle}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  }
);

ContentsGridV1.displayName = "ImageAndTextList";
