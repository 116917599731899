import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { PageDataContext } from "contexts/PageDataContext";
import { useRouter } from "next/router";

export const usePressReleasesList = () => {
  const [limit, setLimit] = useState(10);
  const [searchFilter, setSearchFilter] = useState("");
  const inputRef = useRef(null);
  const { pressReleases } = useContext(PageDataContext);

  /* Commented lines (filtering based on categories) should work only if page has the correct route (/area-stampa/{pressReleaseCategory.slug}) */

  // const router = useRouter();
  // const categorySlug = router.asPath.replace("/area-stampa/", "");
  // const category = pressReleaseCategories.find(
  //   (prc) => prc.slug === categorySlug && prc._id
  // );

  const orderedPressReleases = useMemo(
    () => pressReleases.sort((a, b) => b.published.localeCompare(a.published)),
    [pressReleases]
  );

  const filteredPressReleases = useMemo(
    () =>
      (orderedPressReleases ?? [])
        .slice(0, limit)
        .filter((pr) => pr.title.includes(searchFilter)),
    [orderedPressReleases, searchFilter, limit]
  );

  const showMoreHandler = useCallback(() => setLimit((prev) => prev + 10), []);
  const onSearchSubmit = useCallback(() => {
    setLimit(10);
    setSearchFilter(inputRef.current);
  }, []);

  const onChangeHandler = useCallback(
    (e) => (inputRef.current = e.target.value),
    []
  );

  const onEnterKeyPressHandler = useCallback(
    (e) => e.key === "Enter" && onSearchSubmit(),
    [onSearchSubmit]
  );

  return {
    filteredPressReleases,
    showMoreHandler,
    onSearchSubmit,
    limit,
    onChangeHandler,
    onEnterKeyPressHandler,
    pressReleases,
  };
};
