import React, { memo } from "react";
import { Divider, DividerProps } from "@mui/material";

type FooterDividerProps = {} & DividerProps;

export const FooterDivider = memo(({ sx, ...props }: FooterDividerProps) => {
  return (
    <Divider
      sx={[
        { height: "1px", borderColor: "#FFFFFF35" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  );
});
FooterDivider.displayName = "FooterDivider";
