import React, { memo, ReactNode } from "react";
import { useMapWrapper } from "./index.hooks";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { Map } from "../Map";
import MapOptions = google.maps.MapOptions;
import { Typography } from "@mui/material";

type MapWrapperProps = {
  children?: ReactNode;
  onIdle: (m: google.maps.Map) => void;
  maxWidth?: string;
  height?: string;
} & MapOptions;

export const MapWrapper = memo(({ children, ...props }: MapWrapperProps) => {
  const {} = useMapWrapper();

  return (
    <Wrapper
      apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}
      render={(status) => {
        if (status === Status.LOADING)
          return <Typography>Loading...</Typography>;
        if (status === Status.FAILURE) return <Typography>Error</Typography>;
        return null;
      }}
    >
      <Map {...props}>{children}</Map>
    </Wrapper>
  );
});
MapWrapper.displayName = "MapWrapper";
