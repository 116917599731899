import { useMemo } from "react";
import { useMediaQuery, useTheme } from "@mui/material";

export const useHighlightContent = (href: string, buttonText: string) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const hasCta = useMemo(
    () => Boolean(buttonText) && Boolean(href),
    [buttonText, href]
  );

  return { hasCta, isMobile };
};
