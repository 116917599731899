import { createContext } from "react";
import { MenuItem, PrivacyDisclaimer } from "models/common/StaticContents";
import { ParentPage } from "models/client/PageFe";
import { IStoreFe } from "models/client/StoreFe";
import { IPressReleaseCategoryFe } from "models/client/PressReleaseCategoryFe";
import { IPressReleaseFe } from "models/client/PressReleaseFe";
import { UserProfile } from "models/common/UserCommon";
import { IArticleFe } from "models/client/ArticleFe";
import { FooterProps } from "components/_visual-components";

export type PageDataContextType = {
  mainMenu?: (MenuItem & { open?: boolean; anchorEl?: any })[];
  footerContent?: FooterProps;
  breadcrumbs?: ReadonlyArray<ParentPage>;
  store?: IStoreFe;
  pressReleaseCategories?: IPressReleaseCategoryFe[];
  pressReleases?: IPressReleaseFe[];
  userProfile?: UserProfile;
  mediumCardsArticles?: IArticleFe[];
  privacyDisclaimer?: PrivacyDisclaimer;
  slug?: string;
};

export const PageDataContext = createContext<PageDataContextType>({
  mainMenu: [],
  footerContent: { columns: [] },
  breadcrumbs: [],
  store: null,
  pressReleaseCategories: [],
  pressReleases: [],
  userProfile: null,
  mediumCardsArticles: [],
  privacyDisclaimer: null,
  slug: null,
});
