import React, { memo } from "react";
import { useStoreSmallCard } from "./index.hooks";
import { Box, Button, Grid, Stack, SvgIcon, Typography } from "@mui/material";
import BookIcon from "@mui/icons-material/MenuBookRounded";
import Image from "next/image";

export type StoreSmallCardProps = {
  title: string;
  src: string;
  href: string;
  from: string;
  to: string;
  buttonText: string;
  subTitle?: string;
};

export const StoreSmallCard = memo(
  ({
    title,
    href,
    src,
    from,
    to,
    buttonText,
    subTitle,
  }: StoreSmallCardProps) => {
    const {} = useStoreSmallCard();

    return (
      <Stack
        direction={{
          xs: "column",
          md: "row",
        }}
        spacing={3}
        my={1.5}
        alignItems={{
          xs: "center",
          md: "flex-start",
        }}
      >
        {src && (
          <Box
            sx={{
              position: "relative",
              width: "90px",
              height: "120px",
              userSelect: "none",
              borderRadius: "4px",
              overflow: "hidden",
            }}
          >
            <Image
              src={src}
              layout={"fill"}
              objectFit={"cover"}
              alt="Volantino"
            />
          </Box>
        )}
        <Stack
          alignItems={{
            xs: "center",
            md: "flex-start",
          }}
          spacing={1}
        >
          {from && to && (
            <Typography
              gutterBottom
              fontSize={9}
              fontWeight="bold"
              fontFamily="Sora"
              color="error"
            >
              <span style={{ color: "rgb(190,190,190)" }}>Validità:</span>
              {" " + from + " - " + to}
            </Typography>
          )}
          {title && (
            <Typography
              gutterBottom
              fontSize={15}
              fontWeight="bold"
              color="#151319"
              fontFamily="Sora"
            >
              {title}
            </Typography>
          )}
          {subTitle && (
            <Typography
              gutterBottom
              fontSize={14}
              fontWeight="regular"
              color="#151319"
              fontFamily="Sora"
              textAlign={{
                xs: "center",
                md: "left",
              }}
            >
              {subTitle}
            </Typography>
          )}
          <Box>
            <Button
              variant="outlined"
              startIcon={<SvgIcon fontSize="large" component={BookIcon} />}
              href={href}
              target="_blank"
              sx={{
                color: "#646259",
                borderColor: "#646259",
                fontFamily: "Sora",
                fontSize: "13px",
                fontWeight: "bold",
                textTransform: "none",
                py: 1,
              }}
            >
              {buttonText}
            </Button>
          </Box>
        </Stack>
      </Stack>
    );
  }
);
StoreSmallCard.displayName = "StoreSmallCard";
