import React, { memo, Fragment } from "react";
import { useTags } from "./index.hooks";
import { Divider, Stack, Typography } from "@mui/material";
import StyleOutlinedIcon from "@mui/icons-material/StyleOutlined";
import { AppContainer } from "components/AppContainer";

type TagsProps = { tags: string[] };

export const Tags = memo(({ tags }: TagsProps) => {
  const {} = useTags();

  return (
    <AppContainer maxWidth="md" fullScreen>
      <Divider orientation="horizontal" sx={{ color: "#AAA79A", mb: 1 }} />

      <Stack direction="row" spacing={1} pl={0.5} sx={{ alignSelf: "center" }}>
        <StyleOutlinedIcon sx={{ color: "#AAA79A" }} />
        {tags.map((t, n) => {
          return (
            <Fragment key={n}>
              <Typography
                variant="subtitle2"
                sx={{ fontSize: "17px", color: "#AAA79A" }}
              >
                {t}
              </Typography>
              {n < tags.length - 1 && (
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "17px", color: "#AAA79A" }}
                >
                  -
                </Typography>
              )}
            </Fragment>
          );
        })}
      </Stack>
      <Divider orientation="horizontal" sx={{ color: "#AAA79A", mt: 1 }} />
    </AppContainer>
  );
});
Tags.displayName = "Tags";
