import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import React, { memo } from "react";
import { useContentsGridV2Item } from "./index.hooks";

type ContentsGridItemProps = {
  title: string;
  article: string;
  columns?: number;
};

export const ContentsGridV2Item = memo(
  ({ title, article, columns }: ContentsGridItemProps) => {
    const {} = useContentsGridV2Item();

    const gridSize = 12 / (columns ?? 4);
    return (
      <Grid
        item
        md={Math.max(gridSize, 3)}
        sm={Math.max(gridSize, 6)}
        xs={Math.max(gridSize, 12)}
        my={1}
      >
        <Card
          sx={() => ({
            border: `1px solid #E1E1E1`,
            boxShadow: "none",
            borderRadius: "6px",
            height: "100%",
          })}
        >
          <CardContent sx={{ height: "100%", py: "30px" }}>
            <Stack
              sx={{ height: "100%" }}
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <Typography variant="h4" sx={{ fontSize: 17 }}>
                {title}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Karla",
                  fontSize: 15,
                  color: "#646259",
                  lineHeight: "26px",
                  mt: "17px",
                }}
              >
                {article}
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    );
  }
);

ContentsGridV2Item.displayName = "ContentsGridItem";
