import React, { memo } from "react";
import { useGoogleMaps } from "./index.hooks";
import { Marker } from "../Marker";
import { MapWrapper } from "../MapWrapper";

type GoogleMapsProps = {
  markers: {
    _id: string;
    latLng: google.maps.LatLngLiteral;
    animation?: google.maps.Animation;
  }[];
  onMarkerClick?: (marker: any) => void;
  fullScreen?: boolean;
  mapHeight?: string;
};

export const GoogleMaps = memo(
  ({ markers, onMarkerClick, fullScreen, mapHeight }: GoogleMapsProps) => {
    const { center, zoom, onIdle, visibleMarkers } = useGoogleMaps(
      fullScreen,
      markers
    );

    return (
      <MapWrapper
        height={mapHeight}
        center={center}
        zoom={zoom}
        onIdle={onIdle}
      >
        {visibleMarkers?.map((m) => (
          <Marker
            key={m._id}
            position={new window.google.maps.LatLng(m.latLng)}
            onClick={onMarkerClick.bind(null, m)}
            icon={{
              url: "https://bit.ly/42qVdwq",
              scaledSize: {
                width: 43,
                height: 56,
                equals: (other: google.maps.Size | null): boolean => true,
              },
            }}
            animate={m.animation}
          />
        ))}
      </MapWrapper>
    );
  }
);
GoogleMaps.displayName = "GoogleMaps";
