import { createContext } from "react";
import { StoreLocatorContextType } from "./storeLocatorContext.interfaces";
import { IStoreFe } from "../../models/client/StoreFe";

export const StoreLocatorContext = createContext<StoreLocatorContextType>({
  center: {
    lat: 45.08704,
    lng: 7.669433,
  },
  zoom: 14,
  horizontalWidth: 0,
  iStoresMap: [],
  iStoresList: [],
  storeArticles: [],
  selectedStore: null,
  clickedStore: null,
  userStore: null,
  onStoreSelected: (store) => {},
  onStoreClicked: (store) => {},
  searchByLocation: (center, zoom) => {},
  searchByUserLocation: (center, zoom): Promise<IStoreFe[]> =>
    Promise.resolve([]),
  searchByName: (storeName) => {},
  onCenterChanged: (center) => {},
  onZoomChanged: (zoom) => {},
  onHorizontalWidthChanged: (horizontalWidth) => {},
  onUserStoreSelected: (store) => {},
  setStoreArticles: (storeArticles) => {},
  onNearStoreFound: (store) => {},
});
