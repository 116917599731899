import { useMemo } from "react";
import { IMediaFe, MediaFe } from "models/client/MediaFe";

export const useFloatingImageBanner = (rightImageData: IMediaFe) => {
  const rightImage = useMemo(
    () => new MediaFe(rightImageData),
    [rightImageData]
  );

  return { rightImage };
};
