import React, { useMemo } from "react";

export const useCustomPopoverMenu = (
  parentRef: EventTarget & HTMLElement,
  position: "bottom" | "right"
) => {
  const { top, left } = useMemo(() => {
    if (position === "bottom") {
      return {
        top: parentRef?.offsetTop + parentRef?.clientHeight,
        left: parentRef?.offsetLeft,
      };
    } else if (position === "right") {
      return {
        top: parentRef?.offsetTop,
        left: parentRef?.offsetLeft + parentRef?.clientWidth,
      };
    } else {
      return {
        top: 0,
        left: 0,
      };
    }
  }, [parentRef, position]);

  return { top, left };
};
