import React, { Fragment, memo } from "react";
import { useSearchBarMenu } from "./index.hooks";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Button,
} from "@mui/material";
import { ArrowForwardOutlined } from "@mui/icons-material";
import { MenuLinkCard } from "components/MenuLinkCard";
import Link from "next/link";
import { Resource } from "models/common/Resource";

type SearchBarMenuProps = {
  isOpen: boolean;
  handleClose: (openState: boolean) => void;
  bestResults: Resource[];
  otherResults: Resource[];
  searchHints: string[];
  hintClickCallbacks: () => void[];
  isFetchingResources: boolean;
  barText: string;
};

export const SearchBarMenu = memo(
  ({
    isOpen,
    handleClose,
    bestResults = [],
    otherResults = [],
    searchHints,
    hintClickCallbacks,
    isFetchingResources,
    barText,
  }: SearchBarMenuProps) => {
    const {
      backdropClickHandler,
      HintTypography,
      StyledTypography,
      StyledStack,
    } = useSearchBarMenu(handleClose);

    return (
      <>
        <Box height="100vh" maxWidth="100vw">
          {/*Disables scrolling when backdrop is visible*/}
          {isOpen && (
            <style>
              {`
                body {
                  overflow: hidden;
                }
              `}
            </style>
          )}
          <Backdrop
            open={isOpen}
            sx={{
              position: "fixed",
              display: isOpen ? "block" : "none",
              zIndex: 2000,
              top: "76px",
              left: 0,
              width: "100%",
              height: "100%",
            }}
            onClick={backdropClickHandler}
          />
        </Box>
        {isOpen && (
          <StyledStack spacing={1} sx={{ zIndex: 2500 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "inherit",
                height: "inherit",
                overflowY: "scroll",
              }}
            >
              {searchHints?.length > 0 && (
                <Fragment>
                  <Box>
                    <StyledTypography>Suggerimenti:</StyledTypography>
                    {searchHints.map((text, index) => (
                      <HintTypography
                        onClick={hintClickCallbacks[index]}
                        key={text}
                      >
                        {text}
                      </HintTypography>
                    ))}
                  </Box>
                  <Divider variant="middle" flexItem light />
                </Fragment>
              )}
              {isFetchingResources ? (
                <Box sx={{ py: 2, alignSelf: "center" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Fragment>
                  {bestResults.length > 0 && (
                    <Box>
                      <StyledTypography>Migliori risultati:</StyledTypography>
                      <Grid container spacing={4}>
                        {(bestResults || []).map((result) => (
                          <Grid key={result._id} item xs={6}>
                            <MenuLinkCard
                              key={result._id}
                              medium
                              resource={result}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}
                  {bestResults.length > 0 && otherResults.length > 0 && (
                    <Divider variant="middle" flexItem light />
                  )}
                  {otherResults.length > 0 && (
                    <Box sx={{ pt: 2 }}>
                      <StyledTypography>Altri risultati:</StyledTypography>
                      {(otherResults ?? []).map((result) => (
                        <MenuLinkCard key={result._id} resource={result} />
                      ))}
                    </Box>
                  )}
                  {bestResults.length === 0 && otherResults.length === 0 && (
                    <Box sx={{ pt: 3 }}>
                      <StyledTypography>
                        Nessun risultato trovato
                      </StyledTypography>
                    </Box>
                  )}
                </Fragment>
              )}
            </Box>

            <Stack
              sx={{
                borderTop: "1px solid #e0e0e0",
                pt: 2,
                px: 2,
                pb: -2,
                width: "100%",
              }}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                variant="text"
                href={`/cerca-nel-sito${!!barText ? "?search=" + barText : ""}`}
                sx={{
                  fontSize: { xs: "16px" },
                  fontWeight: { xs: 600 },
                  color: "#3dbfd5",
                  textTransform: "none",
                }}
                endIcon={<ArrowForwardOutlined />}
              >
                Vedi tutti i risultati
              </Button>
            </Stack>
          </StyledStack>
        )}
      </>
    );
  }
);
SearchBarMenu.displayName = "SearchBarMenu";
