import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { useContext, useMemo } from "react";
import { PageDataContext } from "contexts/PageDataContext";

export const useFooter = () => {
  const theme = useTheme();
  const { footerContent } = useContext(PageDataContext);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const height = useMemo(() => (isMobile ? "200px" : "250px"), [isMobile]);

  return {
    height,
    footerContent,
  };
};
