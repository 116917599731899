import { useCallback, useContext, useEffect, useState } from "react";
import { PageDataContext } from "contexts/PageDataContext";
import { MenuItem } from "models/common/StaticContents";

export const popupPaperProps = {
  minWidth: 275,
  borderRadius: 0,
  boxShadow: "none",
  outline: "1px solid #d0d0d0",
};

export const useMainMenu = () => {
  const { mainMenu } = useContext(PageDataContext);

  const [menuData, setMenuData] = useState(mainMenu);

  const closeAllFromLevel = useCallback((submenu: typeof menuData) => {
    submenu.forEach((element) => {
      if (element.open) {
        element.open = false;
        element.anchorEl = null;

        if (element.children) {
          closeAllFromLevel(element.children);
        }
      }
    });
  }, []);

  const setOpenProperty = useCallback(
    (
      item: MenuItem,
      open: boolean,
      event,
      subMenu: typeof menuData
    ): typeof menuData => {
      return subMenu.map((subItem) => {
        if (subItem.nodeId === item.nodeId) {
          return {
            ...subItem,
            open,
            anchorEl: open ? event.currentTarget : null,
          };
        } else {
          if (subItem.children && subItem.children.length > 0) {
            return {
              ...subItem,
              children: setOpenProperty(item, open, event, subItem.children),
            };
          } else {
            return subItem;
          }
        }
      });
    },
    []
  );

  const handlePopoverOpen = useCallback(
    (event, menuItem) => {
      const newMenuData = setOpenProperty(menuItem, true, event, menuData);
      setMenuData(newMenuData);
    },
    [setOpenProperty, menuData]
  );

  const forceMenuReRender = useCallback(() => {
    setMenuData((oldVal) => [...oldVal]);
  }, [setMenuData]);

  const onMenuClickAway = useCallback(() => {
    closeAllFromLevel(menuData);
    forceMenuReRender();
  }, [closeAllFromLevel, forceMenuReRender, menuData]);

  useEffect(() => {
    if (mainMenu?.length) {
      setMenuData(mainMenu);
    }
  }, [mainMenu]);

  return {
    menuData,
    handlePopoverOpen,
    closeAllFromLevel,
    onMenuClickAway,
  };
};
