import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRouter } from "next/router";
import axios from "axios";

const schema = yup.object().shape({
  password: yup.string().required("Inserisci una password valida"),
  confirmPassword: yup.string().required("Conferma la tua password"),
});
type UserResetPasswordFormData = {
  password: string;
  confirmPassword: string;
};

export const useUserResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [messageObj, setMessageObj] = useState<
    | {
        type: "ERROR" | "SUCCESS";
        message: string;
      }
    | undefined
  >(undefined);
  const { query } = useRouter();
  const { email, resetPasswordCode } = query;
  const formData = useForm<UserResetPasswordFormData>({
    resolver: yupResolver(schema),
    defaultValues: { password: "", confirmPassword: "" },
  });
  const { handleSubmit, reset } = formData;
  const { push } = useRouter();

  useEffect(() => {
    if (!(email && resetPasswordCode)) {
      setMessageObj({
        type: "ERROR",
        message:
          "Link di recupero non è corretto, email e/o codice di verifica non trovati. Redirect alla pagina di recupero password in corso...",
      });
      setTimeout(() => {
        push("/recupero-password").then();
      }, 4000);
    }
  }, [email, resetPasswordCode, push]);

  const handleRequest = useCallback(
    async (data: UserResetPasswordFormData) => {
      try {
        setIsLoading(true);
        const res = await axios.patch(
          "/api/users",
          {
            password: data.password,
            passwordCheck: data.confirmPassword,
            email: email as string,
            resetPasswordCode: resetPasswordCode as string,
          },
          { params: { type: "reset-password" } }
        );
        if (res.status === 200) {
          setIsLoading(false);
          setMessageObj({
            message: "Recupero effettuato con successo! Redirect in corso...",
            type: "SUCCESS",
          });
          setTimeout(() => {
            push("/accesso-soci");
          }, 2000);
        }
      } catch (error) {
        if (error?.response) {
          const message = error?.response?.data?.message;
          setMessageObj({ message, type: "ERROR" });
        }
        setIsLoading(false);
      }
    },
    [push, email, resetPasswordCode]
  );

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (data) => {
        await handleRequest(data);
      }),
    [handleSubmit, handleRequest]
  );

  return { formData, onSubmit, messageObj, isLoading };
};
