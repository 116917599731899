import React, { memo } from "react";
import { useTextsAndCallLayout } from "./index.hooks";
import { Breakpoint, Stack } from "@mui/material";
import { AppContainer } from "components/AppContainer";
import { RichText } from "components/_visual-components/RichText";
import { CtaButton } from "components/CtaButton";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";

export type TextsAndCallLayoutProps = VisualComponentProps<{
  title: string;
  titleContainer: Breakpoint;
  subtitle: string;
  subtitleContainer: Breakpoint;
  ctaLabel: string;
  ctaLink: string;
}>;

export const TextsAndCallLayout = memo(
  ({
    ctaLabel,
    ctaLink,
    subtitle,
    title,
    titleContainer,
    subtitleContainer,
    anchorId,
  }: TextsAndCallLayoutProps) => {
    const {} = useTextsAndCallLayout();

    return (
      <Stack
        spacing={3}
        id={anchorId ?? undefined}
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <RichText htmlData={title} maxWidth={titleContainer} />
        <RichText htmlData={subtitle} maxWidth={subtitleContainer} />
        <CtaButton text={ctaLabel} href={ctaLink} />
      </Stack>
    );
  }
);
TextsAndCallLayout.displayName = "TextsAndCallLayout";
