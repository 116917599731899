import { IMediaFe, MediaFe, MediaImageThumbSizes } from "models/client/MediaFe";
import { useContext, useMemo } from "react";
import { PageDataContext } from "contexts/PageDataContext";

export const usePageTitleV3 = (image: IMediaFe) => {
  const { breadcrumbs } = useContext(PageDataContext);
  const backgroundUrl = useMemo(
    () =>
      new MediaFe(image).getUrlFromKeyAndExtension(
        MediaImageThumbSizes.EXTRA_LARGE
      ),
    [image]
  );

  return { backgroundUrl, breadcrumbs };
};
