import React, { memo } from "react";
import { useFooter } from "./index.hooks";
import { Box, Typography, Stack } from "@mui/material";
import Image from "next/image";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "assets/images/x_logo.svg";
import logoCoop from "assets/images/logo-novacoop-alpha.png";
import filoDiretto from "assets/images/novacoop_numeroverde_alpha.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { FooterLink } from "components/_visual-components/Footer/FooterLink";
import { FooterDivider } from "components/_visual-components/Footer/FooterDivider";
import { AppContainer } from "components/AppContainer";
import { FooterTitle } from "components/_visual-components/Footer/FooterTitle";
import { FooterSocialLink } from "components/_visual-components/Footer/FooterSocialLink";

export type FooterProps = {
  columns: {
    title: string;
    links: {
      label: string;
      href: string;
      openInNewTab: boolean;
    }[];
  }[];
};

export const Footer = memo(({}) => {
  const { footerContent } = useFooter();

  return (
    <Box sx={{ bgcolor: "red", mt: "90px", width: "100%" }}>
      <AppContainer
        sx={{
          pt: {
            xs: "40px",
            md: "55px",
          },
          pb: {
            xs: "80px",
            md: "88px",
          },
        }}
      >
        <Stack
          direction={{
            xs: "column",
            md: "row",
          }}
          spacing={{
            xs: "40px",
            md: "60px",
          }}
          pb={{
            xs: "40px",
            md: "55px",
          }}
        >
          <Stack flex={1}>
            <Image
              src={logoCoop.src}
              width={136}
              height={85}
              alt="Logo NovaCoop"
              objectFit="contain"
              objectPosition="left"
            />
            <Typography
              color="#fff"
              fontSize={19}
              fontWeight={"bold"}
              marginTop="24px"
              fontFamily="Sora"
            >
              Nova Coop
            </Typography>
            <Typography color="#fff" fontSize={17} mt={"4px"}>
              Via Nelson Mandela, 4
            </Typography>
            <Typography color="#fff" fontSize={17}>
              13100 Vercelli
            </Typography>
          </Stack>
          {footerContent?.columns?.map((column, index) => (
            <Stack key={index} flex={1}>
              <FooterTitle>{column.title}</FooterTitle>
              <FooterDivider sx={{ mt: "16px" }} />
              {column.links.map((link, index) => (
                <FooterLink
                  key={index}
                  label={link.label}
                  href={link.href}
                  target={link.openInNewTab ? "_blank" : "_self"}
                />
              ))}
            </Stack>
          ))}
          <Stack flex={1}>
            <FooterTitle>Filo Diretto</FooterTitle>
            <FooterDivider sx={{ mt: "16px" }} />
            <Typography
              color="#fff"
              fontSize={15}
              my="22px"
              fontFamily={"Karla"}
            >
              Contattaci al numero verde o ai nostri recapiti e-mail.
            </Typography>
            <a href="tel:800238380">
              <Image
                width={120}
                height={45}
                src={filoDiretto.src}
                alt="Numero Verde: 800-238380"
                objectFit="contain"
                objectPosition="left"
              />
            </a>
          </Stack>
        </Stack>
        <Stack>
          <FooterDivider />
          <Stack
            direction={{
              xs: "column",
              md: "row",
            }}
            alignItems="center"
            spacing={{
              xs: "20px",
              md: "200px",
            }}
          >
            <Typography
              fontSize={13}
              sx={{ color: "#fff" }}
              fontFamily="Karla"
              mt="30px"
            >
              Iscrizione registro delle Imprese di Monte Rosa Laghi Alto
              Piemonte: Rea VC - 155262 - C.F. e P.IVA 01314250034 -Iscrizione
              Albo società Cooperative a mutualità prevalente: A103575 - Ai
              sensi del Regolamento Europeo UE n°679/2016 Lei potrà esercitare i
              diritti previsti dalle disposizioni del Regolamento Europeo in
              materia di protezione dei dati personali n°679/2016 inviando una
              comunicazione scritta presso la sede Vercelli (VC) in Via Nelson
              Mandela n. 4, ovvero inoltrando una email al seguente indirizzo
              privacy@novacoop.coop.it
            </Typography>
            <Stack direction="row" alignItems="center">
              <FooterSocialLink
                icon={<FacebookIcon />}
                href="https://www.facebook.com/Novacoop"
              />
              <FooterSocialLink
                icon={<InstagramIcon />}
                href="https://www.instagram.com/Nova_Coop/"
              />
              <FooterSocialLink
                icon={
                  <Box
                    component="img"
                    src={XIcon.src}
                    sx={{ width: "20px", height: "20px" }}
                  />
                }
                href="https://twitter.com/nova_coop"
              />
              <FooterSocialLink
                icon={<YouTubeIcon />}
                href="https://www.youtube.com/user/NovaCoopVideo"
              />
              <FooterSocialLink
                icon={<LinkedInIcon />}
                href="https://www.linkedin.com/company/5027493/admin/"
              />
            </Stack>
          </Stack>
        </Stack>
      </AppContainer>
    </Box>
  );
});
Footer.displayName = "Footer";
