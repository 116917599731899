import React, { memo, ReactNode } from "react";
import { useMarker } from "./index.hooks";
import MarkerOptions = google.maps.MarkerOptions;

type MarkerProps = {
  children?: ReactNode;
  onClick?: (e) => void;
  animate?: google.maps.Animation;
} & MarkerOptions;

export const Marker = memo(({ children, ...props }: MarkerProps) => {
  const {} = useMarker(props);

  return null;
});
Marker.displayName = "Marker";
