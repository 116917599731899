import React, { memo } from "react";
import { useAppSearchBar } from "./index.hooks";
import { Box, IconButton, InputAdornment, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { SearchBarMenu } from "components/_visual-components/Header/SearchBarMenu";

type AppSearchBarProps = {};

export const AppSearchBar = memo(({}: AppSearchBarProps) => {
  const {
    isUpMd,
    isOpen,
    barText,
    onSearchBarTextChange,
    closeMenuHandler,
    data,
    isFetchingResources,
    searchHints,
    hintClickCallbacks,
    redirectToSearchPage,
  } = useAppSearchBar();

  return (
    <>
      <Box
        component={"div"}
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <form onSubmit={redirectToSearchPage}>
          <InputBase
            placeholder={"Cerca informazioni"}
            value={barText}
            onChange={onSearchBarTextChange}
            sx={{
              height: "40px",
              width: { xs: "90%", md: "267px" },
              border: "1px solid #FF0000",
              paddingLeft: "20px",
              borderRadius: "4px",
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  sx={{
                    margin: "-1px",
                    borderRadius: " 0px 4px 4px 0px",
                    paddingRight: 0,
                    width: "40px",
                    height: "40px",
                    transition: "0.5s",
                    "&:hover": {
                      color: "#FF0000",
                    },
                  }}
                  type="submit"
                >
                  <SearchIcon
                    style={{
                      fontSize: "24px",
                      font: "#FFF",
                      borderRadius: "0px 4px 4px 0px",
                      marginLeft: "-4px",
                    }}
                  />
                </IconButton>
              </InputAdornment>
            }
          />
        </form>
      </Box>
      {isOpen && (
        <SearchBarMenu
          barText={barText}
          isOpen={isOpen}
          handleClose={closeMenuHandler}
          isFetchingResources={isFetchingResources}
          searchHints={searchHints}
          hintClickCallbacks={hintClickCallbacks}
          {...data}
        />
      )}
    </>
  );
});
AppSearchBar.displayName = "AppSearchBar0";
