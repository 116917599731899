import {
  IconButton,
  InputAdornment,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";

import { memo } from "react";
import SearchIcon from "@mui/icons-material/Search";

import { IMediaFe } from "models/client/MediaFe";

import { ContentType, useDownloadList } from "./index.hooks";
import { AssetDownload } from "components/AssetDownload";
import { AppContainer } from "components/AppContainer";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";

export type DownloadListProps = VisualComponentProps<{
  contents: ContentType;
}>;

export const DownloadList = memo(
  ({ contents, anchorId }: DownloadListProps) => {
    const { barText, onSearchBarTextChange, filteredContent } =
      useDownloadList(contents);

    return (
      <AppContainer id={anchorId ?? undefined}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            mt: 4,
            width: "100%",
          }}
        >
          <InputBase
            placeholder={"Cerca in questa pagina..."}
            value={barText}
            onChange={onSearchBarTextChange}
            sx={{
              fontSize: "19px",
              height: "51px",
              width: "480px",
              maxWidth: "100%",
              border: "1px solid gray",
              paddingLeft: "20px",
              borderRadius: "4px",
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  sx={{
                    borderRadius: 0,
                    paddingRight: 0,
                    width: "51px",
                    height: "51px",
                    bgcolor: "gray",
                  }}
                >
                  <SearchIcon
                    style={{
                      color: "white",
                      fontSize: "24px",
                      font: "#FFF",
                      borderRadius: "0px 4px 4px 0px",
                      marginLeft: "-4px",
                    }}
                  />
                </IconButton>
              </InputAdornment>
            }
          />
          <Typography display={"inline-block"} sx={{ mt: "15px" }}>
            {filteredContent?.length} elementi totali
          </Typography>
        </Stack>
        <Stack sx={{ width: "100%", mt: 2 }}>
          {filteredContent?.map((content) => (
            <AssetDownload content={content} key={content.title} />
          ))}
        </Stack>
      </AppContainer>
    );
  }
);
DownloadList.displayName = "DownloadList";
