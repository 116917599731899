import React, { memo } from "react";
import { Stack, Typography, Link } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { FooterDivider } from "components/_visual-components/Footer/FooterDivider";

type FooterLinkProps = {
  label: string;
  href: string;
  target: "_blank" | "_self";
};

export const FooterLink = memo(
  ({ label, href, target = "_self" }: FooterLinkProps) => {
    return (
      <>
        <Link href={href} target={target}>
          <Stack
            direction="row"
            sx={{
              width: "100%",
              justifyContent: "space-between",
              height: "40px",
              alignItems: "center",
              cursor: "pointer",
              "&:hover": {
                opacity: 0.6,
              },
            }}
          >
            <Typography color="white" fontSize={13}>
              {label}
            </Typography>
            <ArrowForwardIos sx={{ color: "white", fontSize: "13px" }} />
          </Stack>
        </Link>
        <FooterDivider />
      </>
    );
  }
);
FooterLink.displayName = "FooterLink";
