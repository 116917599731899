import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import { useContext, useMemo } from "react";
import { IMediaFe, MediaFe } from "models/client/MediaFe";
import { PageDataContext } from "contexts/PageDataContext";

export const usePageTitleV1 = (iMedia: IMediaFe) => {
  const { breadcrumbs } = useContext(PageDataContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const py = useMemo(() => (isMobile ? "150px" : "200px"), [isMobile]);
  const media = useMemo(() => new MediaFe(iMedia), [iMedia]);

  return {
    media,
    py,
    breadcrumbs,
  };
};
