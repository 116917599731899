import React, { memo, useState } from "react";
import { usePageTitleV2 } from "./index.hooks";
import { Box, Stack, Typography } from "@mui/material";
import { IMediaFe, MediaImageThumbSizes } from "models/client/MediaFe";
import Image from "next/image";
import { AppBreadcrumbs } from "components/AppBreadcrumbs";
import { AppContainer } from "components/AppContainer";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";

export type PageTitleV2Props = VisualComponentProps<{
  title: string;
  subtitle: string;
  image: IMediaFe;
}>;

export const PageTitleV2 = memo(
  ({ title, subtitle, image: imageData, anchorId }: PageTitleV2Props) => {
    const { media, breadcrumbs } = usePageTitleV2(imageData);

    return (
      <AppContainer
        id={anchorId ?? undefined}
        sx={{
          mt: "40px",
        }}
      >
        <Stack
          alignItems="center"
          sx={{
            textAlign: "center",
            width: "100%",
            borderBottom: "1px solid #E1E1E1",
          }}
        >
          <Typography variant="h1">{title}</Typography>
          <Typography
            sx={{
              fontFamily: "Karla",
              color: "#151319",
              fontSize: {
                xs: "15px",
                md: "24px",
              },
              mt: "6px",
            }}
          >
            {subtitle}
          </Typography>
          <Box
            sx={{
              width: "100%",
              aspectRatio: "22 / 10",
              position: "relative",
              img: { borderRadius: 2 },
              mt: "36px",
            }}
          >
            <Image
              src={media.getUrlFromKeyAndExtension(
                MediaImageThumbSizes.EXTRA_LARGE
              )}
              alt={media.title}
              layout={"fill"}
              objectFit={"cover"}
              objectPosition={"center"}
            />
          </Box>
          <Stack alignItems="center" sx={{ my: 1 }}>
            <AppBreadcrumbs
              breadcrumbs={breadcrumbs}
              currentPageTitle={title}
            />
          </Stack>
        </Stack>
      </AppContainer>
    );
  }
);

PageTitleV2.displayName = "PageTitleV2";
