import { IMediaFe } from "models/client/MediaFe";
import { useCallback, useMemo, useState } from "react";

export type ContentType = {
  title: string;
  description?: string;
  date?: string;
  media?: IMediaFe;
  url?: string;
  isLink: boolean;
}[];

export const useDownloadList = (contents: ContentType) => {
  const [barText, setBarText] = useState("");

  const onSearchBarTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const textInput = event.target.value;
      setBarText(textInput);
    },
    []
  );

  const filteredContent = useMemo(() => {
    const ln = barText.length;
    if (ln !== 0) {
      const filteredContent = contents.filter(
        (content) =>
          content.title.toUpperCase().slice(0, ln) === barText.toUpperCase()
      );

      return filteredContent;
    } else {
      return contents;
    }
  }, [barText, contents]);

  return { barText, onSearchBarTextChange, filteredContent };
};
