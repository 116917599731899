import { GpsFixed, SearchOutlined } from "@mui/icons-material";
import { Box, IconButton, InputBase, Paper, Stack } from "@mui/material";
import React, { memo } from "react";
import { useSearchbarStore } from "components/FindYourStore/SearchbarStore/index.hooks";

type SearchbarStoreProps = {};

export const SearchbarStore = memo(({}: SearchbarStoreProps) => {
  const { onGeoLocationIconClick, onStoreNameChange, onSearchIconClick } =
    useSearchbarStore();

  return (
    <Paper component="form" sx={{ borderRadius: "4px" }}>
      <Stack direction="row">
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Cerca tra i punti vendita Nova Coop..."
          onChange={onStoreNameChange}
        />
        <IconButton
          sx={{ "&:hover": { background: "none" } }}
          aria-label="geolocate"
          onClick={onGeoLocationIconClick}
        >
          <GpsFixed />
        </IconButton>
        <Box
          sx={{
            backgroundColor: "#aaa79a",
            borderRadius: " 0px 4px 4px 0px",
          }}
        >
          <IconButton
            sx={{
              color: "white",
              "&:hover": {
                background: "none",
              },
            }}
            onClick={onSearchIconClick}
          >
            <SearchOutlined />
          </IconButton>
        </Box>
      </Stack>
    </Paper>
  );
});

SearchbarStore.displayName = "SearchbarStore";
