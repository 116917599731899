import React, { memo, ReactNode } from "react";
import { useNavigationButton } from "components/_visual-components/Header/NavigationBar/NavigationButton/index.hooks";
import { Button, ButtonProps, Stack, Typography } from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system";

type NavigationButtonProps = {
  label: string;
  href: string;
  target?: "_blank" | "_self";
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  labelColor?: string;
  direction?: ResponsiveStyleValue<
    "row" | "row-reverse" | "column" | "column-reverse"
  >;
  shopName?: string;
} & ButtonProps;

export const NavigationButton = memo(
  ({
    label,
    href,
    target,
    startIcon,
    endIcon,
    labelColor = "#151319",
    shopName,
    direction = "column",
    ...props
  }: NavigationButtonProps) => {
    const {} = useNavigationButton();

    return (
      <a href={href} target={target} rel="noreferrer">
        <Button
          disableRipple
          disableElevation
          variant="text"
          sx={{
            opacity: 1,
            textTransform: "none",
            height: "100%",
            borderRadius: 0,
            borderRight: "1px solid #E9E9E9",
            "&:first-of-type": {
              borderLeft: "1px solid #E9E9E9",
            },
          }}
          {...props}
        >
          {startIcon ? startIcon : ""}
          <Stack direction={direction}>
            <Typography
              noWrap
              sx={{
                font: "bolder 12px/15px Sora",
                letterSpacing: "0px",
                color: labelColor,
                paddingLeft: "8px",
                paddingRight: "8px",
                opacity: 1,
              }}
            >
              {label}
            </Typography>

            {shopName ? (
              <Typography
                sx={{
                  font: "bold 16px/22px Sora",
                  letterSpacing: "0px",
                  color: "#FF0000",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  opacity: 1,
                }}
              >
                {shopName}
              </Typography>
            ) : (
              ""
            )}
          </Stack>
          {endIcon ? endIcon : ""}
        </Button>
      </a>
    );
  }
);
NavigationButton.displayName = "NavigationButton";
