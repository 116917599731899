import {
  Box,
  Breakpoint,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import { useColumnsGrid } from "./index.hooks";
import { AppContainer } from "components/AppContainer";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";
import { RichText } from "../RichText";
import { CtaButton } from "../../CtaButton";
import {
  IMediaFe,
  MediaFe,
  MediaImageThumbSizes,
} from "../../../models/client/MediaFe";
import Image from "next/image";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import { useVideoContent } from "../VideoContent/index.hooks";
import { VideoContentProps } from "../VideoContent";

const ColumnVideoContent = memo(({ title, url, image }: VideoContentProps) => {
  const { showYTPlayer, playIconClickHandler, videoID, isMobile } =
    useVideoContent(url);

  return (
    <>
      {isMobile && (
        <Stack textAlign="center" alignItems="center">
          <Typography sx={{ font: "bold 19px/36px Sora", color: "#151319" }}>
            {title}
          </Typography>
          <Typography sx={{ font: "normal 15px/10px Karla", color: "#646259" }}>
            Guarda il video
          </Typography>
        </Stack>
      )}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        width={{ xs: "100%" }}
        sx={{ aspectRatio: "16/9" }}
        height="auto"
      >
        <Box position="relative" width="100%" height="100%">
          <Image
            src={new MediaFe(image).getUrlFromKeyAndExtension(
              MediaImageThumbSizes.MEDIUM
            )}
            alt={image.title}
            layout="fill"
            style={{ borderRadius: "6px" }}
          />
        </Box>
        <Box
          display={showYTPlayer ? "none" : "flex"}
          width="100%"
          height="100%"
          position="absolute"
          margin="auto"
          sx={{
            backgroundColor: "rgba(0,159,223,0.75)",
            borderRadius: "6px",
          }}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <IconButton
            sx={{
              height: { xs: "36px", md: "50px", lg: "72px" },
              width: { xs: "36px", md: "50px", lg: "72px" },
              m: 1,
            }}
            onClick={playIconClickHandler}
          >
            <PlayCircleFilledWhiteOutlinedIcon
              sx={{ color: "white", fontSize: "72px" }}
            />
          </IconButton>
          <Box display={isMobile ? "none" : "initial"} textAlign={"center"}>
            <Typography sx={{ font: "bold 24px/36px Sora", color: "#FFF" }}>
              {title}
            </Typography>
            <Typography sx={{ font: "normal 19px/24px Karla", color: "#FFF" }}>
              Guarda il video
            </Typography>
          </Box>
        </Box>
        <iframe
          style={{
            borderRadius: "6px",
            position: "absolute",
            display: showYTPlayer ? "initial" : "none",
          }}
          width="100%"
          height="100%"
          data-add-placeholder="true"
          data-cookieblock-src={`https://www.youtube.com/embed/${videoID}?autoplay=${
            showYTPlayer ? "1" : "0"
          }&mute=0`}
          data-cookieconsent="statistics, marketing"
          title={title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Box>
    </>
  );
});
ColumnVideoContent.displayName = "ColumnVideoContent";

export type ColumnsGridProps = VisualComponentProps<{
  columns: {
    breakpoints: {
      [key in Breakpoint]?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    };
    hasTitle: boolean;
    title?: string;
    hasHtmlBody: boolean;
    htmlBody?: string;
    hasCallToAction: boolean;
    callToAction?: {
      label: string;
      href: string;
      openInNewPage: boolean;
    };
    hasImage: boolean;
    image?: IMediaFe;
    hasVideo: boolean;
    video?: {
      title: string;
      image: IMediaFe;
      url: string;
    };
  }[];
}>;

export const ColumnsGrid = memo(({ columns, anchorId }: ColumnsGridProps) => {
  const {} = useColumnsGrid();

  return (
    <AppContainer id={anchorId ?? undefined} sx={{ mt: "70px", pb: "50px" }}>
      <Grid container spacing={3} justifyContent="center" rowGap={4}>
        {columns.map(
          (
            {
              hasTitle,
              title,
              hasHtmlBody,
              htmlBody,
              hasCallToAction,
              callToAction,
              hasImage,
              image,
              hasVideo,
              video,
              breakpoints,
            },
            index
          ) => (
            <Grid key={index} item {...breakpoints}>
              <Stack spacing={3} alignItems="flex-start">
                {hasTitle && <Typography variant="h3">{title}</Typography>}
                {hasHtmlBody && (
                  <RichText htmlData={htmlBody} maxWidth={null} />
                )}
                {hasImage && (
                  <Box width="fit-content">
                    <Box
                      component="img"
                      src={new MediaFe(image).getUrlFromKeyAndExtension()}
                      alt={image.title}
                      sx={{
                        borderRadius: "6px",
                        maxWidth: "100%",
                      }}
                    />
                  </Box>
                )}
                {hasVideo && <ColumnVideoContent {...video} />}
                {hasCallToAction && (
                  <Box sx={{ "& *": { lineHeight: "1em!important" } }}>
                    <CtaButton
                      text={callToAction.label}
                      href={callToAction.href}
                      target={callToAction.openInNewPage ? "_blank" : "_self"}
                    />
                  </Box>
                )}
              </Stack>
            </Grid>
          )
        )}
      </Grid>
    </AppContainer>
  );
});

ColumnsGrid.displayName = "ColumnsGrid";
