import React, { Fragment, memo } from "react";
import { useMainMenu } from "components/_visual-components/Header/NavigationBar/MainMenu/index.hooks";
import {
  Box,
  ClickAwayListener,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { NavigationButton } from "components/_visual-components/Header/NavigationBar/NavigationButton";
import { CustomPopoverMenu } from "../CustomPopoverMenu";

type MainMenuProps = {};

export const MainMenu = memo(({}: MainMenuProps) => {
  const { menuData, handlePopoverOpen, closeAllFromLevel, onMenuClickAway } =
    useMainMenu();

  const renderMenu = (content: typeof menuData, isFirstLevel: boolean) => {
    return content.map((item, index) => {
      const hasChildren = Boolean(item?.children?.length);
      const hasLink = Boolean(item?.path?.length);

      if (!hasChildren && !hasLink) return <></>;

      return (
        <Fragment key={item.nodeId}>
          {isFirstLevel ? (
            <NavigationButton
              label={item.label.toUpperCase()}
              href={item.path}
              target={item.openInNewTab ? "_blank" : "_self"}
              onMouseEnter={
                hasChildren
                  ? (event) => {
                      closeAllFromLevel(content);
                      handlePopoverOpen(event, item);
                    }
                  : undefined
              }
              sx={{
                height: "45px",
                borderRight: "1px solid #E9E9E9",
                "&:first-of-type": {
                  borderLeft: "1px solid #E9E9E9",
                },
                "&.MuiButtonBase-root": {
                  borderRadius: "0px",
                },
                py: "0 !important",
              }}
            />
          ) : (
            <a
              href={item.path}
              target={item.openInNewTab ? "_blank" : "_self"}
              rel="noreferrer"
            >
              <Box
                onMouseEnter={
                  hasChildren
                    ? (event) => {
                        closeAllFromLevel(content);
                        handlePopoverOpen(event, item);
                      }
                    : undefined
                }
                sx={{
                  cursor: "pointer",
                  px: 2,
                  py: 0,
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    py: 1.5,
                  }}
                >
                  {hasChildren ? (
                    <Typography
                      display="inline"
                      sx={{
                        color: item.open ? "#029FDF" : "text.secondary",
                        fontWeight: item.open ? "bold" : "normal",
                        fontSize: "12px",
                      }}
                      fontFamily="Sora"
                    >
                      {item.label}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{ color: "text.secondary", fontSize: "12px" }}
                      fontFamily="Sora"
                    >
                      {item.label}
                    </Typography>
                  )}

                  {hasChildren && (
                    <ArrowForwardIosRoundedIcon
                      sx={{
                        height: 18,
                        color: "text.secondary",
                      }}
                    />
                  )}
                </Stack>
                {index !== content.length - 1 && <Divider />}
              </Box>
            </a>
          )}
          {hasChildren && (
            <CustomPopoverMenu
              open={item.open}
              parentRef={item.anchorEl}
              position={isFirstLevel ? "bottom" : "right"}
            >
              {renderMenu(item.children, false)}
            </CustomPopoverMenu>
          )}
        </Fragment>
      );
    });
  };

  return (
    <ClickAwayListener onClickAway={onMenuClickAway}>
      <Stack direction="row" sx={{ zIndex: 1 }}>
        {renderMenu(menuData, true)}
      </Stack>
    </ClickAwayListener>
  );
});
MainMenu.displayName = "MainMenu";
