import React, { memo } from "react";
import { useAppBreadcrumbs } from "./index.hooks";
import {
  Breadcrumbs,
  BreadcrumbsProps,
  SxProps,
  Typography,
} from "@mui/material";
import { ParentPage } from "models/client/PageFe";
import Link from "next/link";

type AppBreadcrumbsProps = {
  breadcrumbs: ReadonlyArray<ParentPage>;
  currentPageTitle: string;
  textSx?: SxProps;
} & BreadcrumbsProps;

export const AppBreadcrumbs = memo(
  ({
    breadcrumbs,
    currentPageTitle,
    sx,
    textSx = {},
    ...props
  }: AppBreadcrumbsProps) => {
    const {} = useAppBreadcrumbs();

    return (
      <Breadcrumbs
        separator=">"
        sx={{
          "& .MuiTypography-root": {
            fontFamily: "Sora",
            fontSize: "13px",
            lineHeight: "18px",
            color: "#646259",
            ...(Array.isArray(textSx) ? Object.assign({}, ...textSx) : textSx),
          },
          ...sx,
        }}
        {...props}
      >
        <Link key="novacoop" href={"/"} passHref>
          <Typography sx={{ cursor: "pointer" }}>Novacoop</Typography>
        </Link>
        {breadcrumbs.map((breadcrumb, index) => {
          return (
            <Link
              key={breadcrumb._id}
              href={`/${breadcrumbs.reduce(
                (url, breadcrumb, index1) =>
                  index1 <= index ? `${url}/${breadcrumb.slug}` : "",
                ""
              )}`}
              passHref
            >
              <Typography sx={{ cursor: "pointer" }}>
                {breadcrumb.title}
              </Typography>
            </Link>
          );
        })}
        <Typography sx={{ fontWeight: "bold" }}>{currentPageTitle}</Typography>
      </Breadcrumbs>
    );
  }
);
AppBreadcrumbs.displayName = "AppBreadcrumbs";
