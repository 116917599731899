import React, { memo } from "react";
import { useResultsList } from "./index.hooks";
import {
  Box,
  ButtonBase,
  CircularProgress,
  Stack,
  Typography,
  Divider,
} from "@mui/material";
import { Resource } from "models/common/Resource";
import { ResultCard } from "components/_visual-components/SearchLandingPage/ResultsList/ResultCard";

type ResultsListProps = {
  isFetchingResources: boolean;
  results: Resource[];
  resultLimit: number;
  fetchMore: () => void;
};

export const ResultsList = memo(
  ({
    isFetchingResources,
    results,
    resultLimit,
    fetchMore,
  }: ResultsListProps) => {
    const {} = useResultsList();
    return (
      <Stack sx={{ width: "100%", pt: 10, justifyContent: "center" }}>
        {isFetchingResources ? (
          <Box sx={{ py: 2, alignSelf: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Divider orientation={"horizontal"} sx={{ pt: 0, mb: 2 }} />
            <Stack spacing={1} divider={<Divider orientation={"horizontal"} />}>
              {results?.length > 0 &&
                results.slice(0, resultLimit).map((result) => {
                  return <ResultCard key={result._id} {...result} medium />;
                })}
              {results.length === 0 && (
                <Box
                  sx={{
                    width: "100%",
                    pt: 2,
                    pb: 2,
                  }}
                >
                  <Typography>Nessun risultato trovato</Typography>
                </Box>
              )}
            </Stack>
            <Divider orientation={"horizontal"} sx={{ pt: 2 }} />
          </>
        )}

        {!isFetchingResources && results?.length >= resultLimit && (
          <ButtonBase
            onClick={fetchMore}
            sx={{
              alignSelf: "center",
              width: "200px",
              height: "45px",
              borderRadius: "4px",
              color: "#AAA79A",
              border: "1px solid #AAA79A",
            }}
          >
            <Typography>Carica altri</Typography>
          </ButtonBase>
        )}
      </Stack>
    );
  }
);
ResultsList.displayName = "ResultsList";
