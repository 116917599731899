import React, { memo } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { ArrowCircleRightOutlined } from "@mui/icons-material";
import Image from "next/image";
import { useColumnSliderItem } from "./index.hooks";
import { IMediaFe, MediaImageThumbSizes } from "models/client/MediaFe";

type ColumnSliderItemProps = {
  title: string;
  href: string;
  text: string;
  iMedia: IMediaFe;
};

export const ColumnSliderItem = memo(
  ({ title, text, href, iMedia }: ColumnSliderItemProps) => {
    const { media } = useColumnSliderItem(iMedia);
    return (
      <Stack spacing={2} alignItems="center">
        <Box
          sx={{
            width: "50%",
            aspectRatio: "1 / 1",
            position: "relative",
            margin: "auto",
          }}
        >
          <Image
            src={media.getUrlFromKeyAndExtension(
              MediaImageThumbSizes.EXTRA_LARGE
            )}
            layout="fill"
            objectFit="cover"
            alt={media.title}
          />
        </Box>
        <Typography variant="h4">{title}</Typography>
        <Typography variant="subtitle1">{text}</Typography>
        <Button
          endIcon={<ArrowCircleRightOutlined sx={{ fontSize: "10px" }} />}
          href={href}
          sx={{
            color: "#FF0000",
            textTransform: "none",
            backgroundColor: "transparent",
          }}
        >
          <Typography variant="h5" color={"#F00"}>
            Scopri
          </Typography>
        </Button>
      </Stack>
    );
  }
);
ColumnSliderItem.displayName = "PageItem";
