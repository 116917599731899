import { useCallback, useMemo, useState } from "react";
import { IMediaFe } from "models/client/MediaFe";
import { useMediaQuery, useTheme } from "@mui/material";

export const useContentColumnsSlider = (
  pages: {
    text: string;
    href: string;
    image: IMediaFe;
    title: string;
  }[]
) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [pageIndex, setPageIndex] = useState(0);

  const perPage = useMemo(
    () => (isMobile ? 1 : isTablet ? 2 : 3),
    [isMobile, isTablet]
  );
  const pagesCount = useMemo(
    () => Math.ceil(pages.length / perPage),
    [pages, perPage]
  );

  const onNextPageClick = useCallback(
    () =>
      setPageIndex((previousPage) =>
        Math.min(previousPage + 1, pagesCount - 1)
      ),
    [setPageIndex, pagesCount]
  );
  const onPrevPageClick = useCallback(
    () => setPageIndex((previousPage) => Math.max(previousPage - 1, 0)),
    [setPageIndex]
  );
  const dotClickCallbacks = useMemo(
    () =>
      Array.from(
        { length: pagesCount },
        (_, index) => () => setPageIndex(index)
      ),
    [pagesCount, setPageIndex]
  );

  return {
    pageIndex,
    perPage,
    onNextPageClick,
    onPrevPageClick,
    pagesCount,
    dotClickCallbacks,
  };
};
