import React, { memo } from "react";
import { useStoreMediumCard } from "./index.hooks";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  IconButton,
  SxProps,
} from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import Image from "next/image";
import { IArticleFe } from "models/client/ArticleFe";
import { JsUtility } from "models/common/JsUtility";
import { IStoreFe } from "models/client/StoreFe";

export type StoreMediumCardProps = {
  article: IArticleFe;
  store?: IStoreFe;
  cardSx?: {};
  boxSx?: {};
};

export const StoreMediumCard = memo(
  ({ cardSx, boxSx, article, store }: StoreMediumCardProps & SxProps) => {
    const { mediaUrl } = useStoreMediumCard(article);

    return (
      <Box sx={{ py: 3, pr: 2, ...boxSx }} minWidth={300}>
        <Card sx={{ maxWidth: 313, ...cardSx }}>
          <Box
            component={"div"}
            sx={{ width: "100%", height: "150px", position: "relative" }}
          >
            <Image
              src={mediaUrl}
              layout="fill"
              objectFit="cover"
              alt={article?.bannerImage?.description}
            />
          </Box>
          <CardContent>
            {article?.isEvent && (
              <>
                {article?.eventAddress ? (
                  <Typography
                    fontSize={11}
                    fontWeight="bold"
                    fontFamily="Karla"
                    sx={{ color: "rgb(190,190,190)" }}
                  >
                    {article.eventAddress}
                  </Typography>
                ) : store?.name ? (
                  <Typography
                    fontSize={11}
                    fontWeight="bold"
                    fontFamily="Karla"
                    sx={{ color: "rgb(190,190,190)" }}
                  >
                    {store.name}
                  </Typography>
                ) : (
                  <Typography
                    fontSize={11}
                    fontWeight="bold"
                    fontFamily="Karla"
                    sx={{ color: "rgb(190,190,190)" }}
                  >
                    Scopri i punti vendita che aderiscono
                  </Typography>
                )}
                <Typography
                  mb={1.3}
                  fontSize={11}
                  fontWeight="bold"
                  fontFamily="Karla"
                  color="error"
                >
                  <span style={{ color: "rgb(190,190,190)" }}>Validità: </span>
                  {JsUtility.formatDateCET(
                    article?.startEventDate,
                    "DD MMMM YYYY"
                  ) +
                    " - " +
                    JsUtility.formatDateCET(
                      article?.endEventDate,
                      "DD MMMM YYYY"
                    )}
                </Typography>
              </>
            )}
            <Typography
              gutterBottom
              fontSize={15}
              fontWeight="bold"
              color="#151319"
              fontFamily="Sora"
              sx={{
                // All the following properties are needed to truncate text after 2 lines
                display: "-webkit-box",
                wordWrap: "break-word",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
            >
              {article?.articleTitle}
            </Typography>
            <Typography
              fontSize={14}
              color="#646259"
              fontFamily="Karla"
              sx={{
                // All the following properties are needed to truncate text after 4 lines
                display: "-webkit-box",
                wordWrap: "break-word",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 4,
              }}
            >
              {article?.articleSubtitle}
            </Typography>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton href={`/news-eventi/news/${article?.slug}`}>
              <ArrowCircleDownIcon
                style={{
                  color: "#d0d0d0",
                  transform: "rotate(270deg)",
                }}
              />
            </IconButton>
          </CardActions>
        </Card>
      </Box>
    );
  }
);
StoreMediumCard.displayName = "StoreMediumCard";
