import React, { memo } from "react";
import { usePressReleasesList } from "./index.hooks";
import { AppContainer } from "../../AppContainer";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";
import { AssetDownload } from "../../AssetDownload";
import SearchIcon from "@mui/icons-material/Search";

type PressReleasesListProps = {};

export const PressReleasesList = memo(({}: PressReleasesListProps) => {
  const {
    filteredPressReleases,
    showMoreHandler,
    onSearchSubmit,
    limit,
    onChangeHandler,
    onEnterKeyPressHandler,
    pressReleases,
  } = usePressReleasesList();

  return (
    <AppContainer>
      <Stack
        sx={{
          my: 4,
          width: "100%",
        }}
        rowGap={1.25}
      >
        <Box
          sx={{
            pb: 8,
            pt: 5,
            maxWidth: "100%",
            height: "100%",
            display: "flex",
            alignItems: { xs: "center", sm: "center" },
            paddingRight: "20px",
            justifyContent: { xs: "center", sm: "space-between" },
            flexDirection: { xs: "column-reverse", sm: "row" },
            rowGap: 2,
          }}
        >
          <InputBase
            placeholder="Cerca in questa pagina.."
            onChange={onChangeHandler}
            onKeyPress={onEnterKeyPressHandler}
            sx={{
              height: "51px",
              width: "100%",
              maxWidth: "464px",
              border: "1px solid #AAA79A",
              paddingLeft: "20px",
              borderRadius: "4px",
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  onClick={onSearchSubmit}
                  sx={{
                    margin: "-1px",
                    borderRadius: " 0px 4px 4px 0px",
                    paddingRight: 0,
                    width: "51px",
                    height: "51px",
                    transition: "0.5s",
                    bgcolor: "#AAA79A",
                    "&:hover": {
                      opacity: 0.7,
                      color: "#FFF",
                      bgcolor: "#AAA79A",
                    },
                  }}
                >
                  <SearchIcon
                    style={{
                      fontSize: "24px",
                      font: "#FFF",
                      borderRadius: "0px 4px 4px 0px",
                      marginLeft: "-8px",
                    }}
                  />
                </IconButton>
              </InputAdornment>
            }
          />
          <Box
            display="flex"
            columnGap={1}
            alignSelf={{ xs: "center", sm: "flex-end" }}
          >
            <Typography sx={{ font: "700 19px Karla", color: "#000" }}>
              {pressReleases.length}
            </Typography>
            <Typography sx={{ font: "500 19px Karla", color: "#646259" }}>
              elementi totali
            </Typography>
          </Box>
        </Box>
        {filteredPressReleases.map((pr) => (
          <AssetDownload
            key={pr._id}
            content={{
              title: pr.title,
              description: pr.description,
              date: pr.published?.toString(),
              media: pr.media,
              isLink: false,
            }}
          />
        ))}
        <Button
          variant="outlined"
          disabled={pressReleases.length <= limit}
          onClick={showMoreHandler}
          sx={{
            mt: 10,
            mb: 4,
            width: 207,
            height: 50,
            color: "#646259",
            border: "1px solid #646259",
            font: "500 15px Sora",
            textTransform: "none",
            alignSelf: "center",
          }}
        >
          Carica altri elementi
        </Button>
      </Stack>
    </AppContainer>
  );
});
PressReleasesList.displayName = "PressReleasesList";
