import { useCallback, useMemo, useState } from "react";
import { IMediaFe, MediaFe, MediaImageThumbSizes } from "models/client/MediaFe";
import { useMediaQuery, useTheme } from "@mui/material";

export const useMainSlider = (
  slides: {
    title: string;
    description: string;
    image: IMediaFe;
    buttonLabel: string;
    buttonLink: string;
  }[]
) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [slideNum, setSlideNum] = useState(0);

  const currentSlide = useMemo(() => slides?.[slideNum], [slideNum, slides]);

  const imagesUrl = useMemo(
    () =>
      (slides ?? []).map((s) =>
        new MediaFe(s.image).getUrlFromKeyAndExtension(
          MediaImageThumbSizes.LARGE
        )
      ),
    [slides]
  );
  const setSlideAtIndexCallbacks = useMemo(
    () => (slides ?? []).map((_, index) => () => setSlideNum(index)),
    [slides]
  );

  const navigatePrev = useCallback(() => {
    setSlideNum((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  }, [setSlideNum, slides]);

  const navigateNext = useCallback(() => {
    setSlideNum((curr) => (curr + 1) % slides.length);
  }, [setSlideNum, slides]);

  return {
    isMobile,
    slideNum,
    currentSlide,
    setSlideAtIndexCallbacks,
    imagesUrl,
    navigatePrev,
    navigateNext,
  };
};
