import { useCallback } from "react";
import { styled } from "@mui/material/styles";
import { Stack, Typography } from "@mui/material";

const HintTypography = styled(Typography)`
  background: rgba(227, 224, 218, 0.6);
  display: inline-flex;
  color: #868579;
  font-size: 11px;
  font-family: Sora;
  width: fit-content;
  padding-inline: 12px;
  padding-block: 8px;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  margin-bottom: 8px;
  margin-right: 8px;
  cursor: pointer;
`;

const StyledTypography = styled(Typography)`
  padding-bottom: 12px;
  font-size: 12px;
  font-family: Sora;
  color: #868579;
`;

const StyledStack = styled(Stack)`
  display: flex;
  position: absolute;
  top: 81px;
  left: 175px;
  min-width: 500px;
  max-width: 750px;
  max-height: 750px;
  overflow: auto;
  padding: 24px;
  z-index: 10;
  background: #ffffff;
  text-align: start;
  border-radius: 4px;
`;

export const useSearchBarMenu = (handleClose: (openState: boolean) => void) => {
  const backdropClickHandler = useCallback(() => {
    handleClose(false);
  }, [handleClose]);

  return {
    backdropClickHandler,
    HintTypography,
    StyledTypography,
    StyledStack,
  };
};
