import React, { memo } from "react";
import { useAppLogoAndSearchBar } from "./index.hooks";
import { Box, Stack, Link as LinkMui } from "@mui/material";
import { AppSearchBar } from "components/_visual-components/Header/AppSearchBar";
import logo from "/assets/images/logo-novacoop.png";
import Link from "next/link";
import Image from "next/image";

type AppLogoAndSearchBarProps = {};

export const AppLogoAndSearchBar = memo(({}: AppLogoAndSearchBarProps) => {
  const { isMobile } = useAppLogoAndSearchBar();

  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center">
      <Stack
        sx={{
          "&:first-of-type": {
            borderLeft: { xs: "none", md: "1px solid #E9E9E9" },
          },
          cursor: "pointer",
        }}
        pl={{
          xs: "4px",
          md: "8px",
        }}
        pr={{
          xs: "4px",
          md: "20px",
        }}
        alignItems="center"
        justifyContent="center"
      >
        <LinkMui href="/">
          <Box
            component="img"
            src={logo.src}
            alt={"Logo Novacoop"}
            sx={{
              width: "auto",
              height: {
                xs: "35px",
                md: "70px",
              },
              display: "block",
            }}
          />
        </LinkMui>
      </Stack>
      {!isMobile && <AppSearchBar />}
    </Stack>
  );
});
AppLogoAndSearchBar.displayName = "AppLogoAndSearchBar";
