import { Box, Stack, Typography } from "@mui/material";
import { IMediaFe } from "models/client/MediaFe";
import React, { memo } from "react";
import { usePageTitleV3 } from "./index.hooks";
import { AppBreadcrumbs } from "components/AppBreadcrumbs";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";

export type PageTitleV3Props = VisualComponentProps<{
  title: string;
  subtitle: string;
  image: IMediaFe;
}>;

export const PageTitleV3 = memo(
  ({ title, subtitle, image, anchorId }: PageTitleV3Props) => {
    const { backgroundUrl, breadcrumbs } = usePageTitleV3(image);

    return (
      <Stack id={anchorId ?? undefined}>
        <Stack
          sx={{
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            backgroundImage: `url(${backgroundUrl})`,
            boxShadow: "inset 0 0 0 1000px rgba(0, 159, 223, 0.75)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "300px",
          }}
        >
          <Typography
            component="h1"
            sx={{
              color: "white",
              textAlign: "center",
              margin: 0,
              fontFamily: "Sora",
              fontWeight: "bold",
            }}
            variant="h3"
          >
            {title}
          </Typography>
          <Typography
            sx={{
              color: "white",
              fontFamily: "Karla",
            }}
            variant="body1"
          >
            {subtitle}
          </Typography>
        </Stack>
        <Stack alignItems="center" sx={{ mt: 1 }}>
          <AppBreadcrumbs breadcrumbs={breadcrumbs} currentPageTitle={title} />
        </Stack>
      </Stack>
    );
  }
);

PageTitleV3.displayName = "PageTitleV3";
