import React, { FC, memo, PropsWithChildren } from "react";
import { useCustomPopoverMenu } from "./index.hooks";
import { Grow, Paper } from "@mui/material";

type CustomPopoverMenuProps = {
  parentRef: EventTarget & HTMLElement;
  open: boolean;
  position: "bottom" | "right";
};

export const CustomPopoverMenu: FC<PropsWithChildren<CustomPopoverMenuProps>> =
  memo(({ children, parentRef, open, position }) => {
    const { top, left } = useCustomPopoverMenu(parentRef, position);

    return open ? (
      <Grow in>
        <Paper
          elevation={0}
          sx={{
            display: open ? "block" : "none",
            position: "absolute",
            top: top,
            left: left,
            minWidth: 300,
            boxShadow: "none",
            borderRadius: 0,
            // outline: "1px solid #d0d0d0",
            // outlineOffset: "-1px",
            "&:after": {
              content: "''",
              position: "absolute",
              top: 0,
              left: 0,
              width: "calc(100% - 1px)",
              height: "calc(100% - 1px)",
              border: "1px solid #d0d0d0",
              pointerEvents: "none",
            },
          }}
        >
          {children}
        </Paper>
      </Grow>
    ) : null;
  });
CustomPopoverMenu.displayName = "CustomPopoverMenu";
