import { useCallback, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";

export const useVideoContent = (url: string) => {
  const [showYTPlayer, setShowYTPlayer] = useState(false);

  const trimmedUrl = url.trimEnd();
  const videoID = trimmedUrl.substring(
    trimmedUrl.length - 11,
    trimmedUrl.length
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const playIconClickHandler = useCallback(() => {
    setShowYTPlayer(true);
  }, []);

  return { showYTPlayer, playIconClickHandler, videoID, isMobile };
};
