import React, { memo } from "react";
import { useMediumCards } from "./index.hooks";
import { Box, IconButton, Grid, Stack, Typography } from "@mui/material";
import { CardItem } from "./CardItem";
import { AppContainer } from "components/AppContainer";
import { styled } from "@mui/material/styles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { CtaButton } from "components/CtaButton";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";
import dynamic from "next/dynamic";
import { JsUtility } from "models/common/JsUtility";

const Hammer: any = dynamic(() => import("react-hammerjs"), {
  loading: ({ children }: any) => <>{children}</>,
});

export type MediumCardsProps = VisualComponentProps<{
  heading: { mainTitle: string; mainSubtitle: string };
  sidebar: {
    sidebarTitle: string;
    sidebarText: string;
    sidebarLink: string;
  };
  tag: string;
}>;

const StyledButton = styled(IconButton)`
  height: 42px;
  width: 42px;
  border-radius: 4px;
  opacity: 80%;
  border: 0.5px solid rgba(67, 69, 67, 0.6);
`;

export const MediumCards = memo(
  ({ heading, sidebar, anchorId = "", tag }: MediumCardsProps) => {
    const {
      hasHeading,
      hasSidebar,
      imgCounter,
      backwardArrowHandler,
      forwardArrowHandler,
      isMobile,
      filteredArticles,
    } = useMediumCards(heading, sidebar, tag);

    const renderHeading = () => (
      <Stack alignItems="center">
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            fontSize: "22px",
            fontWeight: "bold",
            color: "#151319",
          }}
        >
          {heading.mainTitle}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            color: "#646259",
            fontSize: "15px",
          }}
        >
          {heading.mainSubtitle}
        </Typography>
      </Stack>
    );
    const renderSidebar = () => (
      <Stack
        sx={{
          height: "100%",
          mr: { xs: 0, md: "56px" },
        }}
      >
        <Stack spacing={4}>
          <Typography
            variant="h4"
            fontSize={22}
            display={{ xs: "none", sm: "inline" }}
          >
            {sidebar.sidebarTitle}
          </Typography>
          <Typography
            color="gray"
            fontSize={17}
            textAlign={isMobile ? "center" : "left"}
          >
            {sidebar.sidebarText}
          </Typography>
          <Box>
            <CtaButton text="Scopri di più" href={sidebar.sidebarLink} />
          </Box>
        </Stack>
      </Stack>
    );
    const renderMobileControls = () => (
      <Stack direction="row" spacing={2}>
        <StyledButton
          onClick={backwardArrowHandler}
          disabled={imgCounter === 0}
        >
          <KeyboardArrowLeftIcon sx={{ fontSize: 22 }} />
        </StyledButton>
        <StyledButton
          onClick={forwardArrowHandler}
          disabled={imgCounter === filteredArticles.length - 1}
        >
          <KeyboardArrowRightIcon sx={{ fontSize: 22 }} />
        </StyledButton>
      </Stack>
    );
    const renderMobileGrid = () => (
      <Hammer
        onSwipeLeft={forwardArrowHandler}
        onSwipeRight={backwardArrowHandler}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "stretch",
            flexWrap: "nowrap",
            width: "100%",
          }}
        >
          {filteredArticles.map((article, index) => {
            const isLeft = index - imgCounter < 0;
            const isRight = index - imgCounter > 0;

            const offset = isLeft ? -0.05 : isRight ? 0.05 : 0;

            return (
              <Box
                key={index}
                mt={6}
                sx={{
                  transform: `translateX(${(offset - imgCounter) * 100}%)`,
                  transition: "all 0.5s",
                  flexShrink: 0,
                  width: "100%",
                }}
              >
                <CardItem
                  title={article.articleTitle}
                  href={`/news-eventi/news/${article.slug}`}
                  imageData={article.bannerImage}
                  date={JsUtility.formatDateCET(
                    article.created,
                    "DD MMMM YYYY"
                  )}
                />
              </Box>
            );
          })}
        </Box>
      </Hammer>
    );
    const renderDesktopGrid = () => (
      <Grid container spacing={2} sx={{ mt: "20px" }}>
        {filteredArticles.map((article, index) => (
          <Grid item key={index} xs={hasSidebar ? 6 : 4} lg={4}>
            <CardItem
              title={article.articleTitle}
              href={`/news-eventi/news/${article.slug}`}
              imageData={article.bannerImage}
              date={JsUtility.formatDateCET(article.created, "DD MMMM YYYY")}
            />
          </Grid>
        ))}
      </Grid>
    );

    return (
      <AppContainer
        sx={{ overflowX: "hidden", mt: "68px" }}
        id={anchorId ?? undefined}
      >
        {hasHeading && renderHeading()}
        <Grid
          container
          direction={isMobile ? "column" : "row"}
          alignItems={isMobile ? "center" : "flex-start"}
          sx={{ mt: 2 }}
          spacing={4}
        >
          {hasSidebar && (
            <Grid item xs={12} sm={3}>
              {renderSidebar()}
            </Grid>
          )}
          <Grid
            item
            container
            direction="column"
            xs={12}
            sm={9}
            sx={{ mt: { xs: 2, sm: 0 } }}
            alignItems="flex-start"
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Typography
                variant="h4"
                fontSize={22}
                sx={{ color: isMobile ? "#646259" : "#000" }}
              >
                News in evidenza
              </Typography>
              {isMobile && renderMobileControls()}
            </Stack>
            {isMobile ? renderMobileGrid() : renderDesktopGrid()}
          </Grid>
        </Grid>
      </AppContainer>
    );
  }
);

MediumCards.displayName = "MediumCards";
