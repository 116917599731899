import React, { memo } from "react";
import { useFormPassword } from "./index.hooks";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { Controller } from "react-hook-form";
import { JsUtility } from "models/common/JsUtility";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

export type FormPasswordProps = {
  name: string;
  textFieldRef?: React.MutableRefObject<HTMLInputElement>;
} & TextFieldProps;

export const FormPassword = memo(
  ({
    name,
    helperText,
    textFieldRef,
    inputRef,
    ...props
  }: FormPasswordProps) => {
    const { control, errors, handleClickIcon, showPassword } =
      useFormPassword();

    return (
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value, name, ref } }) => {
          return (
            <TextField
              name={name}
              variant="outlined"
              type={showPassword ? "text" : "password"}
              {...props}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <RemoveRedEyeIcon
                      onClick={handleClickIcon}
                      cursor="pointer"
                    />
                  </InputAdornment>
                ),
              }}
              value={value}
              onChange={(ev) => onChange(ev.target.value)}
              inputRef={(e) => {
                ref(e);
                if (textFieldRef) {
                  textFieldRef.current = e;
                }
              }}
              onBlur={onBlur}
              error={!!JsUtility.accessObjectByDotSeparatedKeys(errors, name)}
              helperText={
                JsUtility.accessObjectByDotSeparatedKeys(errors, name)
                  ?.message ?? helperText
              }
            />
          );
        }}
      />
    );
  }
);
FormPassword.displayName = "FormPassword";
