import React, { memo } from "react";
import { useCardItem } from "./index.hooks";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { IMediaFe, MediaImageThumbSizes } from "models/client/MediaFe";
import Image from "next/image";
import Link from "next/link";

type CardItemProps = {
  title: string;
  href: string;
  imageData: IMediaFe;
  date: string;
};

export const CardItem = memo(
  ({ title, href, imageData, date }: CardItemProps) => {
    const { media } = useCardItem(imageData);

    return (
      <Paper
        sx={{
          position: "relative",
          width: "100%",
        }}
      >
        <Box
          component="div"
          sx={{
            position: "relative",
            width: "100%",
            aspectRatio: "16/9",
            objectFit: "cover",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          <Link href={href} passHref style={{ cursor: "pointer" }}>
            <Image
              layout={"fill"}
              objectFit={"cover"}
              src={media.getUrlFromKeyAndExtension(MediaImageThumbSizes.MEDIUM)}
              alt={media.title}
            />
          </Link>
        </Box>
        <Box
          sx={{
            p: 2,
            mb: 6,
            minHeight: {
              xs: "100px",
              sm: "170px",
            },
          }}
        >
          <Typography
            sx={{
              color: "#009FDF",
              fontSize: "13px",
              fontWeight: "regular",
              mb: 1,
            }}
          >
            {date}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Sora",
              fontSize: "15px",
              lineHeight: "22px",
              fontWeight: "bold",
            }}
          >
            {title}
          </Typography>
        </Box>

        <IconButton
          href={href}
          sx={{ position: "absolute", bottom: 0, right: 0 }}
        >
          <ArrowCircleDownIcon
            style={{
              color: "#d0d0d0",
              transform: "rotate(-90deg)",
            }}
          />
        </IconButton>
      </Paper>
    );
  }
);
CardItem.displayName = "CardItem";
