import React, { memo } from "react";
import { usePageTitleV1 } from "./index.hooks";
import { Box, Stack, Typography } from "@mui/material";
import { IMediaFe, MediaImageThumbSizes } from "models/client/MediaFe";
import { AppBreadcrumbs } from "components/AppBreadcrumbs";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";

export type PageTitleV1Props = VisualComponentProps<{
  title: string;
  subtitle: string;
  photo: IMediaFe;
}>;

export const PageTitleV1 = memo(
  ({ title, subtitle, photo, anchorId }: PageTitleV1Props) => {
    const { py, media, breadcrumbs } = usePageTitleV1(photo);

    return (
      <Box
        id={anchorId ?? undefined}
        sx={{
          position: "relative",
          width: "100%",
          height: "fit-content",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${media.getUrlFromKeyAndExtension(
              MediaImageThumbSizes.EXTRA_LARGE
            )})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              py,
              backgroundColor: "rgba(0, 0, 0, 0.35)",
            }}
          >
            <Stack alignItems="center" justifyContent="center">
              <Typography
                component="h1"
                variant="h3"
                textAlign="center"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="body1"
                textAlign="center"
                sx={{ color: "white" }}
              >
                {subtitle}
              </Typography>
              <Box sx={{ position: "absolute", bottom: 15 }}>
                <AppBreadcrumbs
                  breadcrumbs={breadcrumbs}
                  currentPageTitle={title}
                  textSx={{ color: "white" }}
                  sx={{ color: "white" }}
                />
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    );
  }
);
PageTitleV1.displayName = "PageTitle";
