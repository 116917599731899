import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";
import React, { memo } from "react";
import { useExternalHtml } from "./index.hooks";
import { Box } from "@mui/material";
import { AppContainer } from "components/AppContainer";

export type ExternalHtmlProps = VisualComponentProps<{
  html: string;
}>;

export const ExternalHtml = memo(({ html }: ExternalHtmlProps) => {
  const {} = useExternalHtml();

  return (
    <AppContainer>
      <Box dangerouslySetInnerHTML={{ __html: html }} />
    </AppContainer>
  );
});
ExternalHtml.displayName = "ExternalHtml";
