import { TaskAltOutlined } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { AppContainer } from "components/AppContainer";
import { CtaButton } from "components/CtaButton";
import { VisualComponentProps } from "components/_admin/_visual_composers/interfaces";
import { FormTextField } from "components/_form/FormTextField";
import { FormCheckbox } from "components/_form/FormCheckbox";
import React, { memo, useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { FormProvider } from "react-hook-form";
import { useEmailContactModule } from "./index.hooks";
import { IMediaFe, MediaFe } from "models/client/MediaFe";

export type EmailContactModuleProps = VisualComponentProps<{
  email: string;
  title: string;
  privacyFile: IMediaFe;
}>;

export const EmailContactModuleComponent = memo(
  ({ title, privacyFile }: EmailContactModuleProps) => {
    const { onSubmit, formData, isLoading, success, messageObj } =
      useEmailContactModule();

    return (
      <FormProvider {...formData}>
        <form onSubmit={onSubmit}>
          <AppContainer sx={{ mt: "40px" }}>
            <Paper
              sx={{
                py: "36px",
                px: "42px",
                border: "1px solid #E1E1E1",
                boxShadow: "0px 2px 4px #0000000F",
              }}
            >
              <Stack spacing={4}>
                <Typography dangerouslySetInnerHTML={{ __html: title }} />
                <Stack direction={{ xs: "column", md: "row" }} spacing="20px">
                  <FormTextField
                    color="secondary"
                    fullWidth
                    name={"name"}
                    label={"Nome"}
                    sx={{
                      pb: { xs: 2, md: 0 },
                    }}
                  />
                  <FormTextField
                    color="secondary"
                    fullWidth
                    name={"lastname"}
                    label={"Cognome"}
                    sx={{ mb: 2 }}
                  />
                </Stack>
                <FormTextField
                  color="secondary"
                  name={"email"}
                  label={"Indirizzo Email"}
                />
                <FormTextField
                  color="secondary"
                  name={"text"}
                  label={"Testo"}
                  multiline
                  minRows={5}
                />
                <FormCheckbox
                  name="privacy"
                  label={
                    <Typography>
                      Accetto le condizioni dell&apos;
                      <Link
                        href={new MediaFe(
                          privacyFile
                        ).getUrlFromKeyAndExtension()}
                        target="_blank"
                      >
                        informativa privacy
                      </Link>{" "}
                      ai sensi dell&apos;art.13 del regolamento (UE) 2016/679
                      del Parlamento Europeo e del Consiglio
                    </Typography>
                  }
                />
                <Box id="recaptcha-container" />
                <Box sx={{ mt: "74px" }}>
                  <CtaButton
                    type="submit"
                    text="Invia"
                    isLoading={isLoading}
                    disabled={isLoading}
                  />
                </Box>
                {messageObj && (
                  <Alert
                    severity="error"
                    sx={{
                      pt: 2,
                      mt: 2,
                    }}
                  >
                    <Stack spacing={3} direction={"row"}>
                      <AlertTitle>{messageObj.message}</AlertTitle>
                    </Stack>
                  </Alert>
                )}
              </Stack>
            </Paper>
            {success && (
              <Box sx={{ pt: 4, pb: 4, textAlign: "center" }}>
                <TaskAltOutlined sx={{ fontSize: "100px", color: "#4BB543" }} />
                <Typography
                  variant={"h2"}
                  sx={{
                    pt: 2,
                    fontSize: "18px !important",
                    fontWeight: "500 !important",
                    lineHeight: "28px !important",
                  }}
                >
                  Messaggio inviato con successo!
                </Typography>
              </Box>
            )}
          </AppContainer>
        </form>
      </FormProvider>
    );
  }
);
EmailContactModuleComponent.displayName = "EmailContactModuleComponent";

export const EmailContactModule = memo((props: EmailContactModuleProps) => {
  return (
    <GoogleReCaptchaProvider
      scriptProps={{ async: true, defer: true, appendTo: "body" }}
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
      language="it"
      container={{
        element: "recaptcha-container",
        parameters: {
          badge: "inline",
          theme: "light",
        },
      }}
    >
      <EmailContactModuleComponent {...props} />
    </GoogleReCaptchaProvider>
  );
});
EmailContactModule.displayName = "EmailContactModule";
