import React, { memo } from "react";
import { useUserDashboard } from "./index.hooks";
import { AppContainer } from "../../AppContainer";
import {
  Alert,
  Box,
  Grid,
  Paper,
  Link as LinkMui,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";

import { CtaButton } from "../../CtaButton";
import { FormTextField } from "../../_form/FormTextField";
import { FormPassword } from "../../_form/FormPassword";
import { FormSelect } from "../../_form/FormSelect";
import { FormDatePicker } from "../../_form/FormDatePicker";
import { FormCheckbox } from "../../_form/FormCheckbox";
import { FormProvider } from "react-hook-form";

type UserDashboardProps = {};

export const UserDashboard = memo(({}: UserDashboardProps) => {
  const {
    formData,
    onSubmit,
    provinces,
    cities,
    isLoading,
    handleDelete,
    userProfile,
    messageObj,
    setMessageObj,
    privacyDisclaimer,
    socioCoop,
  } = useUserDashboard();

  return (
    <FormProvider {...formData}>
      <form onSubmit={onSubmit}>
        <AppContainer sx={{ mt: "40px" }}>
          <Grid
            container
            spacing="20px"
            sx={{
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Grid item xs={12} md={4}>
              <Paper sx={{ backgroundColor: "#009FDF", p: "40px" }}>
                <Stack spacing={3}>
                  <Typography
                    sx={{
                      fontFamily: "Sora",
                      fontWeight: "bold",
                      color: "white",
                      fontSize: "32px",
                    }}
                  >
                    Bentornato
                    <br />
                    {userProfile?.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Karla",
                      color: "white",
                      fontSize: "23px",
                    }}
                  >
                    In questa sezione puoi gestire i tuoi dati e le tue
                    preferenze sul consenso.
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper
                sx={{
                  py: "36px",
                  px: "42px",
                  border: "1px solid #E1E1E1",
                  boxShadow: "0px 2px 4px #0000000F",
                }}
              >
                <Stack spacing="43px">
                  <Typography
                    sx={{
                      fontFamily: "Karla",
                      fontSize: "21px",
                      color: "#AAA79A",
                    }}
                  >
                    Riepilogo dati:
                  </Typography>

                  <FormTextField
                    color="secondary"
                    name={"email"}
                    label={"Indirizzo Email"}
                  />
                  <Stack direction={{ xs: "column", md: "row" }} spacing="20px">
                    <FormTextField
                      color="secondary"
                      fullWidth
                      name={"name"}
                      label={"Nome"}
                      sx={{
                        pb: { xs: 2, md: 0 },
                      }}
                    />
                    <FormTextField
                      color="secondary"
                      fullWidth
                      name={"surname"}
                      label={"Cognome"}
                      sx={{ mb: 2 }}
                    />
                  </Stack>
                  <Stack direction={{ xs: "column", md: "row" }} spacing="20px">
                    <FormPassword
                      color="secondary"
                      fullWidth
                      name={"password"}
                      label={"Password"}
                      helperText="Inserisci una password di almeno 8 caratteri alfanumerici"
                    />
                    <FormPassword
                      color="secondary"
                      fullWidth
                      name={"passwordCheck"}
                      label={"Conferma Password"}
                      helperText="Inserisci una password di almeno 8 caratteri alfanumerici"
                    />
                  </Stack>
                  <Stack spacing="20px">
                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      spacing="20px"
                    >
                      <FormSelect
                        options={provinces}
                        name={"province"}
                        label={"Provincia di residenza"}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                        fullWidth
                      />
                      <FormSelect
                        options={cities}
                        name={"city"}
                        disabled={!formData.getValues("province")}
                        label={"Comune di residenza"}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                        fullWidth
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      spacing="20px"
                    >
                      <FormDatePicker
                        textFieldProps={{
                          color: "secondary",
                          fullWidth: true,
                        }}
                        name={"birthDate"}
                        helperText={"Inserisci nel formato gg/mm/aaaa"}
                        label={"Data di nascita"}
                      />
                      <FormSelect
                        fullWidth
                        color="secondary"
                        options={[
                          { value: "M", label: "Maschio" },
                          { value: "F", label: "Femmina" },
                        ]}
                        name={"gender"}
                        label={"Genere"}
                      />
                    </Stack>
                  </Stack>
                  <Stack py={1}>
                    <FormCheckbox
                      color="secondary"
                      name={"socioCoop"}
                      label={"Sono già Socio Coop"}
                    />
                    <FormTextField
                      disabled={!socioCoop}
                      color="secondary"
                      name={"eanCard"}
                      label={"Numero Carta Socio Coop"}
                      helperText="Inserisci il numero che trovi sul retro della tua tessera Socio Coop"
                    />
                  </Stack>
                  <Stack>
                    <Typography
                      sx={{
                        fontFamily: "Karla",
                        color: "#646259",
                        fontSize: 17,
                      }}
                    >
                      Leggi l&apos;
                      <LinkMui
                        href={privacyDisclaimer.privacyLink}
                        target="_blank"
                        sx={{
                          textDecoration: "none",
                          color: "dodgerblue",
                        }}
                      >
                        {privacyDisclaimer.privacyLabel}
                      </LinkMui>
                    </Typography>
                    {(privacyDisclaimer?.optinPrivacy ?? []).map(
                      (item, index) => (
                        <Stack key={item.id}>
                          <FormCheckbox
                            color="secondary"
                            name={`privacy${item.id}`}
                            label={item.title}
                          />
                          <Typography
                            variant="caption"
                            sx={{
                              fontFamily: "Karla",
                              fontSize: 11,
                              lineHeight: "17px",
                              color: "#646259",
                            }}
                          >
                            {item.text}
                          </Typography>
                        </Stack>
                      )
                    )}
                    <Box sx={{ mt: "74px" }}>
                      <CtaButton
                        isLoading={isLoading}
                        disabled={isLoading}
                        type="submit"
                        text="Aggiorna"
                      />
                    </Box>
                  </Stack>
                </Stack>
              </Paper>
              {/*
              <Paper
                sx={{
                  mt: 3,
                  py: "36px",
                  px: "42px",
                  border: "1px solid #E1E1E1",
                  boxShadow: "0px 2px 4px #0000000F",
                }}
              >
                <Typography
                  color="#AAA79A"
                  gutterBottom
                  sx={{ font: "700 21px/48px Karla" }}
                >
                  Desideri eliminare il tuo account?
                </Typography>
                <CtaButton
                  onClick={handleDelete}
                  isLoading={isLoading}
                  disabled={isLoading}
                  text="Elimina account"
                />
              </Paper>*/}
            </Grid>
          </Grid>
        </AppContainer>
      </form>
      <Snackbar
        open={!!messageObj}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={() => setMessageObj(null)}
          severity={messageObj?.type === "SUCCESS" ? "success" : "error"}
        >
          {messageObj?.message}
        </Alert>
      </Snackbar>
    </FormProvider>
  );
});
UserDashboard.displayName = "UserDashboard";
