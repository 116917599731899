import { useCallback, useContext, useMemo, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { PageDataContext } from "../../../contexts/PageDataContext";

export const useMediumCards = (
  heading: { mainTitle: string; mainSubtitle: string },
  sidebar: {
    sidebarTitle: string;
    sidebarText: string;
    sidebarLink: string;
  },
  tag
) => {
  const { hasHeading, hasSidebar } = useMemo(() => {
    const hasHeading = heading && heading.mainTitle && heading.mainSubtitle;
    const hasSidebar =
      sidebar &&
      sidebar.sidebarTitle &&
      sidebar.sidebarText &&
      sidebar.sidebarLink;

    return { hasHeading, hasSidebar };
  }, [heading, sidebar]);

  const { mediumCardsArticles } = useContext(PageDataContext);
  const filteredArticles = mediumCardsArticles.filter((a) =>
    a.tags.includes(tag)
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [imgCounter, setImgCounter] = useState(0);

  const forwardArrowHandler = useCallback(
    () =>
      setImgCounter((oldVal) =>
        Math.min(oldVal + 1, filteredArticles.length - 1)
      ),
    [filteredArticles]
  );

  const backwardArrowHandler = useCallback(
    () => setImgCounter((oldVal) => Math.max(oldVal - 1, 0)),
    []
  );

  return {
    hasHeading,
    hasSidebar,
    imgCounter,
    backwardArrowHandler,
    forwardArrowHandler,
    isMobile,
    filteredArticles,
  };
};
